import { ChequeType,ChequeTypeEndpoint } from "../domain/ChequeType.model";

type Dictionary = {
    [key: string]: string;
};

const chequeTypeAttributesDiccionary: Dictionary = {
    id: 'TipoChequeID',
    chequeTypeName: 'TipoCheque',
}

export const createAdapterToFieldChequeTypeEndpoint = (key: string): string => {
    return chequeTypeAttributesDiccionary[key];
}

export const createAdapterToChequeTypeEndpoint = (chequeType: ChequeType) => {
    const formattedChequeType: ChequeTypeEndpoint = {
        TipoChequeID: chequeType.id ?? undefined,
        TipoCheque: chequeType.chequeTypeName,
    };
    return formattedChequeType;
}

export const createAdapterToChequeType = (chequeTypeEndpoint: ChequeTypeEndpoint) => {
    const formattedChequeType: ChequeType = {
        id: chequeTypeEndpoint.TipoChequeID,
        chequeTypeName: chequeTypeEndpoint.TipoCheque,
    };
    return formattedChequeType;
}
