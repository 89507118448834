import {TableColumn} from "../../domain/columns.model";
import {useEffect, useRef, useState} from "react";
import {
    StyledColumnsSelectorContainer,
    StyledColumnsSelectorHeader
} from "../../styled-components/StyledColumnSelector";
import SettingsIcon from '@mui/icons-material/Settings';
import {ColumnSelectable} from "../../tableUtils/columnSelector.hook";
import {SelectColumnList} from "./SelectColumnList";

interface TableColumnSelectorProps<T> {
    tableName: string;
    columns: TableColumn<T>[];
    initialColumns: ColumnSelectable[];
    selectedColumns: ColumnSelectable[];
    addColumn: (column: ColumnSelectable) => void;
    removeColumn: (column: ColumnSelectable) => void;
}

export function TableColumnSelector<T>({initialColumns, selectedColumns, addColumn, removeColumn}: TableColumnSelectorProps<T>){

    const [ open, setOpen ] = useState<boolean>(false);
    const switchAddFilter = () => {
        setOpen(!open);
    }

    let menuRef = useRef<any>(null);
    let iconRef = useRef<any>(null);
  
    useEffect(() => {
      const handler = (e: MouseEvent) => {
        if (menuRef.current && e.target instanceof Node && !menuRef.current.contains(e.target) && !iconRef.current.contains(e.target) && open) { 
            setOpen(false);
        }
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    });

    return (
        <StyledColumnsSelectorContainer>
            <StyledColumnsSelectorHeader ref={iconRef} onClick={switchAddFilter} ><SettingsIcon /></StyledColumnsSelectorHeader>
            <SelectColumnList ref={menuRef} show={open} columns={initialColumns} selectedColumns={selectedColumns} addColumn={addColumn} removeColumn={removeColumn}  />
        </StyledColumnsSelectorContainer>
    )
}