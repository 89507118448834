import { FC, ReactNode, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SidebarNav from "./components/SidebarNav";
import { SidebarContext } from "../../Sidebar/SidebarContext";
import { useAppSelector } from "../../../../utilities/hooks/redux.hooks";
import { SidebarMenuExtended } from "./domain/sidebarNav";
import useSession from "../../auth/services/useSession";
import { MENU_PERMISSION_BASE } from "../../menu/menu.constants";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { appConfig } from "../../../../config";
import { decodeToken } from "../../auth/utilities/jwt";
import Profile from "./components/Profile";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";

interface TopbarLayoutProps {
  children?: ReactNode;
}

const TopbarLayout: FC<TopbarLayoutProps> = ({ children }) => {
  const { hasPermission } = useSession();
  const { pathname } = useLocation();
  const data = useAppSelector((state) => state.menuStatus.menus);
  const navigate = useNavigate();
  const session = useAppSelector((state) => state.session);
  const user =
    session.token !== undefined ? decodeToken(session.token) : undefined;
  const HOME_URLS = ["/", "/home", "/dashboard", `/profile/${user.id}`];
  let { verMenuLateral, mostrarOcultarMenu } = useContext(SidebarContext);
  const crumb =
    pathname === "/"
      ? [{ label: "home", link: "/" }]
      : pathname
          .replace(/[?#].*$/, "")
          .split("/")
          .filter((segment) => segment !== "")
          .map((segment, index, array) => ({
            label: segment,
            link: "/" + array.slice(0, index + 1).join("/"),
          }));

  const menus: SidebarMenuExtended[] = data?.filter((menu) =>
    hasPermission(`${MENU_PERMISSION_BASE}${menu.action}`)
  );

  function eraseDepthSubmenus(
    menu: SidebarMenuExtended[]
  ): SidebarMenuExtended[] {
    const result = [];
    for (let i = 0; i < menu.length; i++) {
      const hasPerm = hasPermission(`${MENU_PERMISSION_BASE}${menu[i].action}`);
      const processedItem = {
        ...menu[i],
        submenus: hasPerm ? eraseDepthSubmenus(menu[i].submenus) : [],
      };
      if (hasPerm || processedItem.submenus.length > 0) {
        result.push(processedItem);
      }
    }
    return result;
  }

  const goToHome = () => {
    navigate(appConfig.HOME_PAGE);
  };

  return (
    <ContenedorPrincipal menuLateral={verMenuLateral}>
      <Menu>
        <Logo>
          {verMenuLateral ? (
            <img
              className="logoChico"
              src="/assets/Logo_2D.png"
              alt="TecbiLogo"
            />
          ) : (
            <img
              className="logoGrande"
              src="/assets/TecbiLogo.png"
              alt="TecbiLogo"
            />
          )}
        </Logo>
        <Nav>
          <div className="contenedor-menu-sidebar">
            <h5>{!verMenuLateral ? "MENÚ" : ""}</h5>
            <SidebarNav
              data={eraseDepthSubmenus(menus)}
              isSuccess={data.length > 0}
              openNav={false}
            />
          </div>
        </Nav>
        <Isologo>
          <div className="isologo-cashbn">
            {verMenuLateral ? (
              <img
                className="logoChico"
                src="/assets/iso-reverso-blanco.png"
                alt="Tecbi-Isologo"
              />
            ) : (
              <img
                className="logoGrande"
                src="/assets/CashLogoBN.png"
                alt="Tecbi-Isologo"
              />
            )}
          </div>
        </Isologo>
      </Menu>

      <Contenido>
        <Header>
          <ContenedorIconos>
            {verMenuLateral ? (
              <MenuIcon onClick={mostrarOcultarMenu} />
            ) : (
              <ArrowBackIcon onClick={mostrarOcultarMenu} />
            )}
            <HomeIcon onClick={goToHome} />
          </ContenedorIconos>
          <ContenedorUsuario>
            <Profile />
          </ContenedorUsuario>
        </Header>
        <Main>
          <TopbarContent>
            {data &&
            data.length > 0 &&
            !HOME_URLS.includes(crumb[0].link) &&
            data?.find(
              (menu: SidebarMenuExtended) => crumb[0].link === menu?.action
            )?.name ? (
              <TituloAndBreadcrumb>
                <TituloModulo>
                  <FormattedMessage
                    id={
                      data?.find(
                        (menu: SidebarMenuExtended) =>
                          crumb[0].link === menu?.action
                      )?.name
                    }
                  />
                </TituloModulo>
                <Breadcrumb breadcrumbList={crumb} />
              </TituloAndBreadcrumb>
            ) : null}
            {children || <Outlet />}
          </TopbarContent>
        </Main>
      </Contenido>
    </ContenedorPrincipal>
  );
};

export default TopbarLayout;

const Contenido = styled.div<any>`
  width: 100%;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const ContenedorIconos = styled.div`
  cursor: pointer;
  color: #9a989e;
  padding-left: 20px;
  display: flex;
  gap: 10px;
  svg {
    font-size: 30px;
  }
`;

const ContenedorUsuario = styled.div`
  background-color: #e4e5ec;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.5rem 0 2rem;
  width: 250px;
  z-index: 10;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
`;

const Main = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #f2f3fa;
`;

const ContenedorPrincipal = styled.div<any>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.menuLateral ? "5% 95%" : "15% 85%"};
  height: 100vh;
  overflow: auto;
  transition: 0.1s ease-in-out;

  @media screen and (max-width: 1750px) {
    grid-template-columns: ${(props) =>
      props.menuLateral ? "5% 95%" : "25% 75%"};
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #f2f3fa;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .logoGrande {
    object-fit: contain;
    width: 200px;
    height: 40px;
    margin: 15px 0;
    transition: 0.1s ease-in-out;
  }
  .logoChico {
    object-fit: contain;
    width: 60px;
    height: 40px;
    margin: 15px 0;
    transition: 0.1s ease-in-out;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #706f6f;
  height: 100vh;
  padding: 8%;
  overflow-y: auto;
  ul {
    padding: 0 !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .contenedor-menu-sidebar {
    h5 {
      padding: 10px 0;
      color: #9a989e;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const Isologo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  background-color: #706f6f;
  padding-bottom: 20px;
  img {
    object-fit: contain;
  }
  .logoGrande {
    width: 120px;
    height: 40px;
    transition: 0.1s ease-in-out;
  }
  .logoChico {
    width: 30px;
    height: 40px;
    transition: 0.1s ease-in-out;
  }
`;

const TopbarContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const TituloModulo = styled.h1`
  width: 90%;
  text-transform: uppercase;
  color: #706f6f;
  padding: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 1024px) {
    font-size: 20px;
    height: 100%;
  }
`;

const TituloAndBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
`;
