import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToExchangeRate,
  createAdapterToExchangeRateEndpoint,
  createAdapterToFieldExchangeRateEndpoint
} from "../adapters/exchangeRate.adapter";
import { EndpointExchangeRateFiltered, ExchangeRate, ExchangeRateEndpoint } from "../domain/ExchangeRate.model";
import { appConfig } from "../../../../config";
import { EndpointExchangeRate } from "../domain/ExchangeRate.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";


const BASE_EXCHANGERATE_URL = '/general/tipocambios'
const BASE_EXCHANGERATE_MUTATION_URL = '/general/tipocambio'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/general/tipocambios/export`;

export const exchangeRateApi = createApi({
  reducerPath: "exchangeRateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["ExchangeRates", "ExchangeRate"],
  endpoints: (builder) => ({
    getExchangeRate: builder.query<ExchangeRate, string | undefined>({
      query: (id) => `${BASE_EXCHANGERATE_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointExchangeRate) => {
        return createAdapterToExchangeRate(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "ExchangeRate", id: id?.toString()}],
    }),
    getExchangeRates: builder.query<any, TableOptions<ExchangeRate>|undefined>({
      query: (params: TableOptions<ExchangeRate>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldExchangeRateEndpoint));
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldExchangeRateEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_EXCHANGERATE_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["ExchangeRates"],
      transformResponse: (response: EndpointExchangeRateFiltered, _meta, params: TableOptions<ExchangeRate>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((exchangeRate: ExchangeRateEndpoint) =>
            createAdapterToExchangeRate(exchangeRate)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createExchangeRate: builder.mutation<any, ExchangeRate>({
      query: (data) => ({
        url: `${BASE_EXCHANGERATE_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToExchangeRateEndpoint(data)
        },
      }),
      invalidatesTags: ["ExchangeRates"],
    }),
    updateExchangeRate: builder.mutation<any, ExchangeRate>({
      query: (data: ExchangeRate) => ({
        url: `${BASE_EXCHANGERATE_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToExchangeRateEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: ExchangeRate) => ["ExchangeRates", {type: 'ExchangeRate', id: `${data?.currencyId?.toString()}/${data?.id?.replace(/-/g, "")}`}],
    }),
    deleteExchangeRate: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_EXCHANGERATE_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExchangeRates"],
    }),
  }),
});

export const {
  useGetExchangeRateQuery,
  useGetExchangeRatesQuery,
  useCreateExchangeRateMutation,
  useUpdateExchangeRateMutation,
  useDeleteExchangeRateMutation
} = exchangeRateApi;
