import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appConfig } from "../../../../../config";
import { ActiveDirectoryOption } from "../../domain/activeDirectory";

const initialState: ActiveDirectoryOption = {
  value: appConfig.DEFAULT_ACTIVE_DIRECTORY,
};

export const activeDirectorySlice = createSlice({
  name: "enabledActiveDirectory",
  initialState,
  reducers: {
    setActiveDirectory: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setActiveDirectory } = activeDirectorySlice.actions;

export default activeDirectorySlice.reducer;
