import { FieldErrors } from "react-hook-form";
import { InputError } from "./styled-components/InputError";
import {
  CheckboxContainer,
  InputLayout,
  StyledCheckboxLabel,
} from "./styled-components/InputLayout";
import React, { ReactElement } from "react";
import { DefaultInput } from "./styled-components/DefaultInput";
import {useIntl} from "react-intl";

const formValidation = (errors: FieldErrors, errorKey: string, defaultErrorMessage: string) => {
  return (
    <InputError show={!!errors[errorKey]}>
      {errors[errorKey]?.message as string  ?? defaultErrorMessage}
    </InputError>
  );
};

interface InputProps {
  id?: string;
  register: any;
  name: string;
  errors?: FieldErrors;
  label?: string;
  placeholder?: string;
  type: "checkbox";
  disabled?: boolean;
  autoComplete?: string;
  innerHtmlInput?: ReactElement;
  onChange?: any;
}

export const Checkbox = ({
  id,
  register,
  name,
  errors,
  label = "",
  placeholder,
  type,
  disabled = false,
  autoComplete,
  innerHtmlInput,
  onChange
}: InputProps) => {
  const intl = useIntl();
  const DEFAULT_ERROR_MESSAGE = intl.formatMessage({ id: 'complete this field' })
  const onChangeAttribute = onChange ? {onChange: onChange} : {};
  return (
    <InputLayout disableBorder={true}>
      <CheckboxContainer>
        {innerHtmlInput ? (
          React.cloneElement(innerHtmlInput, {
            ...register,
            id,
            name,
            errors,
            label,
            placeholder: placeholder ?? label,
            type: type ?? "checkbox",
            disabled,
            autoComplete,
          })
        ) : (
          <DefaultInput
            disabled={disabled}
            type="checkbox"
            id={id}
            placeholder={placeholder ?? label}
            {...register}
            {...onChangeAttribute}
          />
        )}
        <StyledCheckboxLabel htmlFor={id}>{label}</StyledCheckboxLabel>
      </CheckboxContainer>
      {errors && formValidation(errors, name, DEFAULT_ERROR_MESSAGE)}
    </InputLayout>
  );
};
