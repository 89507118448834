export function maskNumber(value: any): any{
    const toFormat = value * 1;
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
    };
    if(toFormat < 0){
        const formatValue = (toFormat * (-1)).toLocaleString('es-ES', options);
        return `-${formatValue}`
    }
    const formatValue = toFormat.toLocaleString('es-ES', options);
    return `${formatValue}`;
}