import styled from "styled-components";

export const StyledFilterButtonName = styled.span`
  display: none;
  @media screen and (min-width: 600px) {
    display: inherit;
  }
`;
export const StyledFiltersContainer = styled.div`
  height: 100%;
  display: flex;
  background-color: #4BA735;
  border-radius: 8px;
  width: 48px;
  color: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StyledFiltersHeader = styled.div`
  display: flex;
  background-color: #4BA735;
  color: #fff;
  
`;

interface StyledFilterBodyProps {
  showFilters: boolean;
}

export const StyledFiltersBody = styled.div<StyledFilterBodyProps>`
  display: flex;
  width: 100%;
  height: ${(props) => (props.showFilters ? "auto" : "0px")};
  border: ${(props) =>
    props.showFilters ? "1px solid rgba(0,0,0,0.2)" : "none"};
  transition: height 300ms ease-in-out;
  border-radius: 8px 0 8px 8px;
  overflow: hidden;
    margin-top: 5px;
`;

export const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  height: auto;
  gap: 10px;
  padding: 8px;
`;

export const StyledFilterField = styled.span`
  min-width: 100px;
  text-align: left;
`;
