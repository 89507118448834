import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { BaseLayout, TopbarLayout } from "./shared-kernel/components/Layouts";
import Protected from "./shared-kernel/components/auth/presentation/components/Protected";
import MenusTable from "./shared-kernel/components/menu/component/MenusTable";
import SuspenseLoader from "./shared-kernel/components/SuspenseLoader/SuspenseLoader";
import SecureSlug from "./shared-kernel/components/auth/presentation/components/SecureSlug";
import { appConfig } from "./config";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(
  lazy(
    () =>
      import("./shared-kernel/components/auth/presentation/components/Login")
  )
);
const Dashboard = Loader(
  lazy(() => import("./shared-kernel/components/Dashboard"))
);
const Home = Loader(
  lazy(() => import("./shared-kernel/components/Home/HomeDefault"))
);
const NonResourseMenu = Loader(
  lazy(
    () => import("./shared-kernel/components/NonRecourseMenu/NonResourseMenu")
  )
);
const Users = Loader(lazy(() => import("./user/presentation/Users")));
const UsersTable = Loader(lazy(() => import("./user/components/UsersTable")));
const Menus = Loader(
  lazy(() => import("./shared-kernel/components/menu/presentation/Menus"))
);
const NewMenu = Loader(
  lazy(() => import("./shared-kernel/components/menu/component/NewMenu"))
);
const EditMenu = Loader(
  lazy(() => import("./shared-kernel/components/menu/component/EditMenu"))
);
const UserProfile = Loader(
  lazy(() => import("./user/presentation/UserProfile"))
);
const Settings = Loader(
  lazy(() => import("./shared-kernel/components/Settings/Settings"))
);
const Stocks = Loader(lazy(() => import("./stock/presentation/Stocks")));
const Products = Loader(lazy(() => import("./product/presentation/Products")));
const Tags = Loader(lazy(() => import("./tags/presentation/Tags")));
const Trademark = Loader(
  lazy(() => import("./trademark/presentation/Trademarks"))
);
const Categories = Loader(
  lazy(() => import("./category/presentation/Categories"))
);
const NewStocks = Loader(lazy(() => import("./stock/components/NewStocks")));
const EditStocks = Loader(lazy(() => import("./stock/components/EditStocks")));
const EditProduct = Loader(
  lazy(() => import("./product/components/EditProduct"))
);
const NewProduct = Loader(
  lazy(() => import("./product/components/NewProduct"))
);
const EditTag = Loader(lazy(() => import("./tags/components/EditTag")));
const NewTag = Loader(lazy(() => import("./tags/components/NewTag")));
const EditTrademark = Loader(
  lazy(() => import("./trademark/components/EditTrademark"))
);
const NewTrademark = Loader(
  lazy(() => import("./trademark/components/NewTrademark"))
);
const EditCategory = Loader(
  lazy(() => import("./category/components/EditCategory"))
);
const NewCategory = Loader(
  lazy(() => import("./category/components/NewCategory"))
);
const AccountTypes = Loader(
  lazy(
    () => import("./parametry/financial/accountType/presentation/AccountTypes")
  )
);
const AccountTypesTable = Loader(
  lazy(
    () =>
      import("./parametry/financial/accountType/components/AccountTypesTable")
  )
);
const BankTransactionTypes = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/bankTransactionType/presentation/BankTransactionTypes"
      )
  )
);
const BankTransactionTypesTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/bankTransactionType/components/BankTransactionTypesTable"
      )
  )
);
const Customers = Loader(
  lazy(() => import("./parametry/general/customer/presentation/Customers"))
);
const CustomersTable = Loader(
  lazy(() => import("./parametry/general/customer/components/CustomersTable"))
);
const CountriesTable = Loader(
  lazy(() => import("./parametry/general/country/components/CountriesTable"))
);
const Countries = Loader(
  lazy(() => import("./parametry/general/country/presentation/Countries"))
);
const Currencies = Loader(
  lazy(() => import("./parametry/general/currency/presentation/Currencies"))
);
const CurrenciesTable = Loader(
  lazy(() => import("./parametry/general/currency/components/CurrenciesTable"))
);
const Suppliers = Loader(
  lazy(() => import("./parametry/general/supplier/presentation/Suppliers"))
);
const SuppliersTable = Loader(
  lazy(() => import("./parametry/general/supplier/components/SuppliersTable"))
);
const States = Loader(
  lazy(() => import("./parametry/estimates/state/presentation/States"))
);
const StatesTable = Loader(
  lazy(() => import("./parametry/estimates/state/components/StatesTable"))
);
const Contacts = Loader(
  lazy(() => import("./parametry/general/contact/presentation/Contacts"))
);
const ContactsTable = Loader(
  lazy(() => import("./parametry/general/contact/components/ContactsTable"))
);
const InvestmentTypes = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentType/presentation/InvestmentTypes"
      )
  )
);
const InvestmentTypesTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentType/components/InvestmentTypesTable"
      )
  )
);
const Areas = Loader(
  lazy(() => import("./parametry/general/area/presentation/Areas"))
);
const AreasTable = Loader(
  lazy(() => import("./parametry/general/area/components/AreasTable"))
);
const PaymentMethods = Loader(
  lazy(
    () =>
      import("./parametry/estimates/paymentMethod/presentation/PaymentMethods")
  )
);
const PaymentMethodsTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/estimates/paymentMethod/components/PaymentMethodsTable"
      )
  )
);
const InvestmentMovementsTypes = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentMovementType/presentation/InvestmentMovementsTypes"
      )
  )
);
const InvestmentMovementsTypesTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentMovementType/components/InvestmentMovementsTypesTable"
      )
  )
);
const Banks = Loader(
  lazy(() => import("./parametry/financial/banks/presentation/Banks"))
);
const BanksTable = Loader(
  lazy(() => import("./parametry/financial/banks/components/BanksTable"))
);
const BankAccounts = Loader(
  lazy(
    () => import("./parametry/financial/bankAccount/presentation/BankAccounts")
  )
);
const BankAccountsTable = Loader(
  lazy(
    () =>
      import("./parametry/financial/bankAccount/components/BankAccountsTable")
  )
);
const Concepts = Loader(
  lazy(() => import("./parametry/financial/concept/presentation/Concepts"))
);
const ConceptsTable = Loader(
  lazy(() => import("./parametry/financial/concept/components/ConceptsTable"))
);
const MonthClosures = Loader(
  lazy(
    () =>
      import("./transactions/estimates/monthClosure/presentation/MonthClosures")
  )
);
const MonthClosuresTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/estimates/monthClosure/components/MonthClosuresTable"
      )
  )
);
const BankTransactions = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/bankTransaction/presentation/BankTransactions"
      )
  )
);
const BankTransactionsTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/bankTransaction/components/BankTransactionsTable"
      )
  )
);
const InvestmentPortfolios = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentPortfolio/presentation/InvestmentPortfolios"
      )
  )
);
const InvestmentPortfoliosTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentPortfolio/components/InvestmentPortfoliosTable"
      )
  )
);
const StatementMovements = Loader(
  lazy(
    () =>
      import(
        "./transactions/financial/statementMovement/presentation/StatementMovements"
      )
  )
);
const StatementMovementsTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/financial/statementMovement/components/StatementMovementsTable"
      )
  )
);
const StatementMovementsImportTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/financial/statementMovement/components/StatementMovementsImportTable"
      )
  )
);
const InvestmentInstruments = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentInstrument/presentation/InvestmentInstruments"
      )
  )
);
const InvestmentInstrumentsTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/investmentInstrument/components/InvestmentInstrumentsTable"
      )
  )
);
const StatementBalances = Loader(
  lazy(
    () =>
      import(
        "./transactions/financial/statementBalance/presentation/StatementBalances"
      )
  )
);
const StatementBalancesTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/financial/statementBalance/components/StatementBalancesTable"
      )
  )
);
const BankTransactionsClassification = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/bankTransactionClassification/presentation/BankTransactionsClassification"
      )
  )
);
const BankTransactionsClassificationTable = Loader(
  lazy(
    () =>
      import(
        "./parametry/financial/bankTransactionClassification/components/BankTransactionsClassificationTable"
      )
  )
);
const Locations = Loader(
  lazy(() => import("./transactions/locations/presentation/Locations"))
);
const LocationsTable = Loader(
  lazy(() => import("./transactions/locations/components/LocationsTable"))
);
const CashFlows = Loader(
  lazy(() => import("./transactions/estimates/cashFlow/presentation/CashFlows"))
);
const CashFlowsTable = Loader(
  lazy(
    () => import("./transactions/estimates/cashFlow/components/CashFlowsTable")
  )
);
const CashFlowDetailsTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/estimates/cashFlow/components/CashFlowDetailsTable"
      )
  )
);
const Incomes = Loader(
  lazy(() => import("./transactions/estimates/income/presentation/Incomes"))
);
const IncomesTable = Loader(
  lazy(() => import("./transactions/estimates/income/components/IncomesTable"))
);
const IncomesImportTable = Loader(
  lazy(
    () =>
      import("./transactions/estimates/income/components/IncomesImportTable")
  )
);
const Expenses = Loader(
  lazy(() => import("./transactions/estimates/expense/presentation/Expenses"))
);
const ExpensesTable = Loader(
  lazy(
    () => import("./transactions/estimates/expense/components/ExpensesTable")
  )
);
const ExpensesImportTable = Loader(
  lazy(
    () =>
      import("./transactions/estimates/expense/components/ExpensesImportTable")
  )
);
const ExchangeRates = Loader(
  lazy(
    () => import("./parametry/general/exchangeRate/presentation/ExchangeRates")
  )
);
const ExchangeRatesTable = Loader(
  lazy(
    () =>
      import("./parametry/general/exchangeRate/components/ExchangeRatesTable")
  )
);
const FinancialInvestments = Loader(
  lazy(
    () =>
      import(
        "./transactions/estimates/financialInvestment/presentation/FinancialInvestments"
      )
  )
);
const FinancialInvestmentsTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/estimates/financialInvestment/components/FinancialInvestmentsTable"
      )
  )
);
const FinancialInvestmentsImportTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/estimates/financialInvestment/components/FinancialInvestmentsImportTable"
      )
  )
);
const Headquarters = Loader(
  lazy(
    () => import("./parametry/general/headquarters/presentation/Headquarters")
  )
);
const HeadquartersTable = Loader(
  lazy(
    () =>
      import("./parametry/general/headquarters/components/HeadquartersTable")
  )
);
const ChequeStates = Loader(
  lazy(
    () => import("./parametry/general/chequeState/presentation/ChequeStates")
  )
);
const ChequeStatesTable = Loader(
  lazy(
    () => import("./parametry/general/chequeState/components/ChequeStatesTable")
  )
);
const ChequeTypes = Loader(
  lazy(() => import("./parametry/general/chequeType/presentation/ChequeTypes"))
);
const ChequeTypesTable = Loader(
  lazy(
    () => import("./parametry/general/chequeType/components/ChequeTypesTable")
  )
);
const OwnCheques = Loader(
  lazy(() => import("./transactions/cheques/ownCheque/presentation/OwnCheques"))
);
const OwnChequesTable = Loader(
  lazy(
    () => import("./transactions/cheques/ownCheque/components/OwnChequesTable")
  )
);
const Permissions = Loader(
  lazy(() => import("./permission/presentation/Permissions"))
);
const PermissionsTable = Loader(
  lazy(() => import("./permission/components/PermissionsTable"))
);
const Roles = Loader(lazy(() => import("./role/presentation/Roles")));
const RolesTable = Loader(lazy(() => import("./role/components/RolesTable")));
const OwnChequesImportTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/cheques/ownCheque/components/OwnChequesImportTable"
      )
  )
);
const ThirdPartyCheques = Loader(
  lazy(
    () =>
      import(
        "./transactions/cheques/thirdPartyCheque/presentation/ThirdPartyCheques"
      )
  )
);
const ThirdPartyChequesTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/cheques/thirdPartyCheque/components/ThirdPartyChequesTable"
      )
  )
);
const ThirdPartyChequesImportTable = Loader(
  lazy(
    () =>
      import(
        "./transactions/cheques/thirdPartyCheque/components/ThirdPartyChequesImportTable"
      )
  )
);

// Bank reconciliations
const BankReconciliationLayout = Loader(
  lazy(
    () => import("./bankReconciliation/presentation/BankReconciliationLayout")
  )
);
const BankReconciliations = Loader(
  lazy(() => import("./bankReconciliation/components/BankReconciliations"))
);

const BankReconciliationTabsLayout = Loader(
  lazy(
    () =>
      import(
        "./bankReconciliation/components/BankReconcilitationTabsLayout/BankReconciliationTabsLayout"
      )
  )
);

const BankReconciliationDashboard = Loader(
  lazy(
    () =>
      import(
        "./bankReconciliation/components/BankReconciliationDashboard/BankReconciliationDashboard"
      )
  )
);

const BankReconciliationLastReconciliations = Loader(
  lazy(
    () =>
      import(
        "./bankReconciliation/components/BankReconciliationLastReconciliations/BankReconciliationLastReconciliations"
      )
  )
);

// Status
const NotFound = Loader(
  lazy(() => import("./shared-kernel/components/Status/NotFound/NotFound"))
);
const Status500 = Loader(
  lazy(
    () =>
      import(
        "./shared-kernel/components/Status/InternalServerError/InternalServerError"
      )
  )
);
const StatusComingSoon = Loader(
  lazy(() => import("./shared-kernel/components/Status/ComingSoon/CominSoon"))
);
const StatusMaintenance = Loader(
  lazy(
    () => import("./shared-kernel/components/Status/Maintenance/Maintenance")
  )
);

const routes: RouteObject[] = [
  {
    path: "/login",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Protected>
        <TopbarLayout />
      </Protected>
    ),
    children: [
      {
        path: "",
        element:
          appConfig.HOME_DASHBOARD === "true" ? (
            <Navigate to="dashboard" />
          ) : (
            <Navigate to="home" />
          ),
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/profile/:id",
        element: (
          <Protected>
            <UserProfile />
          </Protected>
        ),
      },
      {
        path: "/settings",
        element: (
          <Protected>
            <Settings />
          </Protected>
        ),
      },
      {
        path: "/stock",
        element: <Stocks />,
        children: [
          {
            path: "add",
            element: <NewStocks />,
          },
          {
            path: "edit/:id",
            element: <EditStocks />,
          },
        ],
      },
      {
        path: "permissions",
        element: (
          <SecureSlug>
            <Permissions />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <PermissionsTable />,
          },
        ],
      },
      {
        path: "roles",
        element: <Roles />,
        children: [
          {
            path: "",
            element: <RolesTable />,
          },
        ],
      },
      {
        path: "exchangeRates",
        element: (
          <SecureSlug>
            <ExchangeRates />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ExchangeRatesTable />,
          },
        ],
      },
      {
        path: "/products",
        element: <Products />,
        children: [
          {
            path: "add",
            element: <NewProduct />,
          },
          {
            path: "edit/:id",
            element: <EditProduct />,
          },
        ],
      },
      {
        path: "/tags",
        element: <Tags />,
        children: [
          {
            path: "add",
            element: <NewTag />,
          },
          {
            path: "edit/:id",
            element: <EditTag />,
          },
        ],
      },
      {
        path: "/trademark",
        element: <Trademark />,
        children: [
          {
            path: "add",
            element: <NewTrademark />,
          },
          {
            path: "edit/:id",
            element: <EditTrademark />,
          },
        ],
      },
      {
        path: "/categories",
        element: (
          <SecureSlug>
            <Categories />
          </SecureSlug>
        ),
        children: [
          {
            path: "add",
            element: <NewCategory />,
          },
          {
            path: "edit/:id",
            element: <EditCategory />,
          },
        ],
      },
      {
        path: "users",
        element: (
          <SecureSlug>
            <Users />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <UsersTable />,
          },
        ],
      },
      {
        path: "menus",
        element: (
          <SecureSlug>
            <Menus />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <MenusTable />,
          },
          {
            path: "add",
            element: <NewMenu />,
          },
          {
            path: "edit/:id",
            element: <EditMenu />,
          },
        ],
      },
      {
        path: "currencies",
        element: (
          <SecureSlug>
            <Currencies />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <CurrenciesTable />,
          },
        ],
      },
      {
        path: "customers",
        element: (
          <SecureSlug>
            <Customers />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <CustomersTable />,
          },
        ],
      },
      {
        path: "countries",
        element: (
          <SecureSlug>
            <Countries />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <CountriesTable />,
          },
        ],
      },
      {
        path: "suppliers",
        element: (
          <SecureSlug>
            <Suppliers />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <SuppliersTable />,
          },
        ],
      },
      {
        path: "accountTypes",
        element: (
          <SecureSlug>
            <AccountTypes />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <AccountTypesTable />,
          },
        ],
      },
      {
        path: "bankTransactionTypes",
        element: (
          <SecureSlug>
            <BankTransactionTypes />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <BankTransactionTypesTable />,
          },
        ],
      },
      {
        path: "states",
        element: (
          <SecureSlug>
            <States />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <StatesTable />,
          },
        ],
      },
      {
        path: "contacts",
        element: (
          <SecureSlug>
            <Contacts />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ContactsTable />,
          },
        ],
      },
      {
        path: "investmenttypes",
        element: (
          <SecureSlug>
            <InvestmentTypes />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <InvestmentTypesTable />,
          },
        ],
      },
      {
        path: "areas",
        element: (
          <SecureSlug>
            <Areas />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <AreasTable />,
          },
        ],
      },
      {
        path: "bankTransactions",
        element: (
          <SecureSlug>
            <BankTransactions />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <BankTransactionsTable />,
          },
        ],
      },
      {
        path: "investmentmovementstypes",
        element: (
          <SecureSlug>
            <InvestmentMovementsTypes />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <InvestmentMovementsTypesTable />,
          },
        ],
      },
      {
        path: "paymentmethods",
        element: (
          <SecureSlug>
            <PaymentMethods />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <PaymentMethodsTable />,
          },
        ],
      },
      {
        path: "banks",
        element: (
          <SecureSlug>
            <Banks />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <BanksTable />,
          },
        ],
      },
      {
        path: "bankAccounts",
        element: (
          <SecureSlug>
            <BankAccounts />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <BankAccountsTable />,
          },
        ],
      },
      {
        path: "concepts",
        element: (
          <SecureSlug>
            <Concepts />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ConceptsTable />,
          },
        ],
      },
      {
        path: "chequeStates",
        element: (
          <SecureSlug>
            <ChequeStates />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ChequeStatesTable />,
          },
        ],
      },
      {
        path: "chequeTypes",
        element: (
          <SecureSlug>
            <ChequeTypes />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ChequeTypesTable />,
          },
        ],
      },
      {
        path: "investmentportfolios",
        element: (
          <SecureSlug>
            <InvestmentPortfolios />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <InvestmentPortfoliosTable />,
          },
        ],
      },
      {
        path: "investmentInstruments",
        element: (
          <SecureSlug>
            <InvestmentInstruments />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <InvestmentInstrumentsTable />,
          },
        ],
      },
      {
        path: "bankTransactionClassification",
        element: (
          <SecureSlug>
            <BankTransactionsClassification />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <BankTransactionsClassificationTable />,
          },
        ],
      },
      {
        path: "headquarters",
        element: (
          <SecureSlug>
            <Headquarters />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <HeadquartersTable />,
          },
        ],
      },
      {
        path: "ownCheques",
        element: (
          <SecureSlug>
            <OwnCheques />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <OwnChequesTable />,
          },
          {
            path: "import",
            element: <OwnChequesImportTable />,
          },
        ],
      },
      {
        path: "thirdPartyCheques",
        element: (
          <SecureSlug>
            <ThirdPartyCheques />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ThirdPartyChequesTable />,
          },
          {
            path: "import",
            element: <ThirdPartyChequesImportTable />,
          },
        ],
      },
      {
        path: "statementBalances",
        element: (
          <SecureSlug>
            <StatementBalances />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <StatementBalancesTable />,
          },
        ],
      },
      {
        path: "monthClosures",
        element: (
          <SecureSlug>
            <MonthClosures />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <MonthClosuresTable />,
          },
        ],
      },
      {
        path: "locations",
        element: (
          <SecureSlug>
            <Locations />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <LocationsTable />,
          },
        ],
      },
      {
        path: "cashflow",
        element: (
          <SecureSlug>
            <CashFlows />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <CashFlowsTable />,
          },
          {
            path: ":id",
            element: <CashFlowDetailsTable />,
          },
        ],
      },
      {
        path: "statementMovements",
        element: (
          <SecureSlug>
            <StatementMovements />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <StatementMovementsTable />,
          },
          {
            path: "import",
            element: <StatementMovementsImportTable />,
          },
        ],
      },
      {
        path: "incomes",
        element: (
          <SecureSlug>
            <Incomes />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <IncomesTable />,
          },
          {
            path: "import",
            element: <IncomesImportTable />,
          },
        ],
      },
      {
        path: "financialInvestments",
        element: (
          <SecureSlug>
            <FinancialInvestments />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <FinancialInvestmentsTable />,
          },
          {
            path: "import",
            element: <FinancialInvestmentsImportTable />,
          },
        ],
      },
      {
        path: "expenses",
        element: (
          <SecureSlug>
            <Expenses />
          </SecureSlug>
        ),
        children: [
          {
            path: "",
            element: <ExpensesTable />,
          },
          {
            path: "import",
            element: <ExpensesImportTable />,
          },
        ],
      },
      {
        path: "bankReconciliations",
        //element: <SecureSlug><bankReconciliations /></SecureSlug>,
        element: <BankReconciliationLayout />,
        children: [
          {
            path: "",
            element: <BankReconciliationTabsLayout />,
            children: [
              {
                path: "",
                element: <Navigate to="lastreconciliations" replace />,
              },
              {
                path: "dashboard",
                element: <BankReconciliationDashboard />,
              },
              {
                path: "lastreconciliations",
                element: <BankReconciliationLastReconciliations />,
              },
            ],
          },
          {
            path: "reconciliations",
            element: <BankReconciliations />,
          },
        ],
      },
    ],
  },
  {
    path: "/status",
    children: [
      {
        path: "",
        element: <Navigate to="404" replace />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <Status500 />,
      },
      {
        path: "maintenance",
        element: <StatusMaintenance />,
      },
      {
        path: "coming-soon",
        element: <StatusComingSoon />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Protected>
        <TopbarLayout />
      </Protected>
    ),
    children: [
      {
        path: "*",
        element: <NonResourseMenu />,
      },
    ],
  },
];

export default routes;
