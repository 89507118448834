import { createAdapterToCurrency } from "../../parametry/general/currency/adapters/currency.adapter";
import { maskNumber } from "../../utilities/maskNumber";
import {
  BankReconciliationRecord,
  BankReconciliationEndpoint,
  LastBankReconciliations,
  LastBankReconciliationsEndpoint,
  LastBankReconciliationEndpoint,
  LastBankAccountReconciliationEndpoint,
} from "../domain/BankReconciliations";

type Dictionary = {
  [key: string]: string;
};

const bankReconciliationAttributesDiccionary: Dictionary = {
  id: "id",
  date: "date",
  concept: "concept",
  amount: "amount",
  status: "status",
  isDebit: "isDebit",
  idConciliation: "idConciliation",
  idCandidates: "idCandidates",
};

export const createAdapterToFieldConciliationEndpoint = (
  key: string
): string => {
  return bankReconciliationAttributesDiccionary[key];
};

export const createAdapterToBankReconciliationEndpoint = (
  bankReconciliation: BankReconciliationRecord
) => {
  const formattedBankReconciliation: BankReconciliationEndpoint = {
    id: bankReconciliation.id,
    date: bankReconciliation.date,
    concept: bankReconciliation.concept,
    amount: bankReconciliation.isDebit
      ? bankReconciliation.debit ?? 0
      : bankReconciliation.credit ?? 0,
    status: bankReconciliation.status,
    isDebit: bankReconciliation.isDebit,
    idConciliation: bankReconciliation.idConciliation,
    idCandidates: bankReconciliation.idCandidates,
    type: bankReconciliation.type,
    statusID: 0
  };
  return formattedBankReconciliation;
};

export const createAdapterToBankReconciliation = (
  bankReconciliationEndpoint: BankReconciliationEndpoint
) => {
  const formattedBankReconciliation: BankReconciliationRecord = {
    id: bankReconciliationEndpoint.id,
    date: bankReconciliationEndpoint.date,
    concept: bankReconciliationEndpoint.concept,
    debit: bankReconciliationEndpoint.isDebit
      ? bankReconciliationEndpoint.amount
      : undefined,
    credit: bankReconciliationEndpoint.isDebit
      ? undefined
      : bankReconciliationEndpoint.amount,
    
    debitMask: bankReconciliationEndpoint.isDebit
      ? maskNumber(bankReconciliationEndpoint.amount)
      : undefined,
    creditMask: bankReconciliationEndpoint.isDebit
      ? undefined
      : maskNumber(bankReconciliationEndpoint.amount),
    
    status: bankReconciliationEndpoint.status,
    statusID: bankReconciliationEndpoint.statusID,
    isDebit: bankReconciliationEndpoint.isDebit,
    idConciliation: bankReconciliationEndpoint.idConciliation,
    idCandidates: bankReconciliationEndpoint.idCandidates,
    type: bankReconciliationEndpoint.type,
  };
  return formattedBankReconciliation;
};

export const createAdapterToLastBankReconciliations = (
  lastBankReconciliationsEndpoint: LastBankReconciliationsEndpoint
) => {
  const formattedLastBankReconciliations: LastBankReconciliations = {
    banks: lastBankReconciliationsEndpoint?.banks
      ? lastBankReconciliationsEndpoint?.banks.map(
          (lastBankReconciliation: LastBankReconciliationEndpoint) => {
            return {
              bankId: lastBankReconciliation.bankId,
              name: lastBankReconciliation.name,
              // shortName: lastBankReconciliation.shortName,
              lastConciliationDate: lastBankReconciliation.lastConciliationDate,
              automaticConciliationsPercentage:
                lastBankReconciliation.automaticConciliationsPercentage,
              noConciliationsPercentage:
                lastBankReconciliation.noConciliationsPercentage,
              noConciliationsQuantity:
                lastBankReconciliation.noConciliationsQuantity,
              noConciliationsAmount:
                lastBankReconciliation.noConciliationsAmount,
              TotalExtractos:
                lastBankReconciliation.TotalExtractos,
              ConciliacionesAutomaticas:
                lastBankReconciliation.ConciliacionesAutomaticas,
              TotalConciliado: 
                lastBankReconciliation.TotalConciliado,
              accountsBanks:
                lastBankReconciliation?.accountsBanks &&
                lastBankReconciliation?.accountsBanks.length > 0
                  ? lastBankReconciliation?.accountsBanks.map(
                      (
                        lastBankAccountReconciliation: LastBankAccountReconciliationEndpoint
                      ) => {
                        return {
                          id: lastBankAccountReconciliation.id,
                          accountNumber:
                            lastBankAccountReconciliation.accountNumber,
                          lastConciliationDate:
                            lastBankAccountReconciliation.lastConciliationDate,
                          lastConciliationStartDate:
                            lastBankAccountReconciliation.lastConciliationStartDate,
                          lastConciliationEndDate:
                            lastBankAccountReconciliation.lastConciliationEndDate,
                          automaticConciliationsPercentage:
                            lastBankAccountReconciliation.automaticConciliationsPercentage,
                          noConciliationsPercentage:
                            lastBankAccountReconciliation.noConciliationsPercentage,
                          noConciliationsQuantity:
                            lastBankAccountReconciliation.noConciliationsQuantity,
                          noConciliationsAmount:
                            lastBankAccountReconciliation.noConciliationsAmount,
                        };
                      }
                    )
                  : [],
            };
          }
        )
      : [],
    totalAutomaticConciliationsPercentage:
      lastBankReconciliationsEndpoint.totalAutomaticConciliationsPercentage,
    totalNoConciliationsPercentage:
      lastBankReconciliationsEndpoint.totalNoConciliationsPercentage,
    totalNoConciliationsQuantity:
      lastBankReconciliationsEndpoint.totalNoConciliationsQuantity,
    totalNoConciliationsAmount:
      lastBankReconciliationsEndpoint.totalNoConciliationsAmount,
  };
  return formattedLastBankReconciliations;
};
