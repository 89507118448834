import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  EndpointPermission,
  Permission,
  PermissionDataProps,
} from "../domain/Permission.model";
import { createAdapterPermission } from "../adapters/permission.adapter";
import {TableOptions} from "../../shared-kernel/components/Table";
import {appConfig} from "../../config";

const API_URL_UM = appConfig.API_URL_UM;
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/PermissionApi/export`;


export const permissionApi = createApi({
    reducerPath: "PermissionApi",
    baseQuery: fetchBaseQuery({
      baseUrl: API_URL_UM,
      prepareHeaders: (headers: Headers) => {
        const APP_TOKEN = "app_token";
        const token = localStorage.getItem(APP_TOKEN);

        if (token) {
          headers.set("authorization", `${token}`);
          headers.set("Content-Type", "application/json");
        }

        return headers;
      },
      credentials: "include",
    }),
    tagTypes: ["Permissions", "Permission"],
    endpoints: (builder) => ({
      getPermissions: builder.query<PermissionDataProps, TableOptions<Permission> | undefined>({
        query: (tableOptions: TableOptions<Permission>) =>{
          let queryParams: string = '';
          if(tableOptions !== undefined && tableOptions.page){
            const page = tableOptions.page - 1;
            queryParams += `page=${page}`;
          }
          if(tableOptions !== undefined && tableOptions.pageSize){
            queryParams += `&pageSize=${tableOptions.pageSize}`;
          }
          if(tableOptions !== undefined && tableOptions.order){
            const parsedOrder = JSON.stringify({
              field: tableOptions.order.field,
              order: tableOptions.order.order
            })
            queryParams += `&order=${parsedOrder}`;
          }
          if(tableOptions !== undefined && tableOptions.search){
            queryParams += `&search=${tableOptions.search}`;
          }
          return `permissions${queryParams !== '' ? `?${queryParams}` : ''}`
        }
          ,
        providesTags: ["Permissions"],
        transformResponse: (response: any) => {
          return {
            data: response.map((p: EndpointPermission) => createAdapterPermission(p)),
            page: 1,
            total: response.length,
          };
        }
      }),
      updatePermission: builder.mutation<any, EndpointPermission>({
        query: (data: EndpointPermission) => ({
          url: `permissions/${data.id}`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: (_res, _err, data: EndpointPermission) => ["Permissions", {type: 'Permission', id: data?.id?.toString()}],
      }),
      getPermission: builder.query<EndpointPermission, string | undefined>({
        query: (id) => `permissions/${id}`,
        providesTags: (_res, _err, id) => [{type: "Permission", id: id?.toString()}],
        transformResponse: (response: EndpointPermission) => createAdapterPermission(response),
      }),
      createPermission: builder.mutation<any, any>({
        query: (data) => ({
          url: 'permissions/',
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["Permissions"],
      }),
      deletePermission: builder.mutation<string, any>({
        query: (id) => ({
          url: `${API_URL_UM}/permissions/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ['Permissions']
      }),
    }),
  });

export const { useGetPermissionsQuery,useGetPermissionQuery,useDeletePermissionMutation,useUpdatePermissionMutation,useCreatePermissionMutation } = permissionApi;
