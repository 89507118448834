import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldIncomeEndpoint,
  createAdapterToIncome,
  createAdapterToIncomeEndpoint,
} from "../adapters/income.adapter";
import {
  EndpointIncomeFiltered,
  Income,
  IncomeEndpoint,
} from "../domain/Income.model";
import { appConfig } from "../../../../config";
import { EndpointIncome } from "../domain/Income.model";
import { stringToNumericalDate } from "../../../../utilities/dateUtils";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_INCOME_URL = "/estimaciones/ingresos";
const BASE_INCOME_MUTATION_URL = "/estimaciones/ingreso";
export const BASE_INCOME_EXPORT_URL = `${appConfig.API_NODE_URL}/estimaciones/ingresos/export`;

export const incomeApi = createApi({
  reducerPath: "incomeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Incomes", "Income"],
  endpoints: (builder) => ({
    getIncome: builder.query<Income, number | undefined>({
      query: (id) => `${BASE_INCOME_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointIncome) => {
        return createAdapterToIncome(response.Content);
      },
      providesTags: (_res, _err, id) => [
        { type: "Income", id: id?.toString() },
      ],
    }),
    getIncomes: builder.query<any, TableOptions<Income> | undefined>({
      query: (params: TableOptions<Income>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(f, createAdapterToFieldIncomeEndpoint)
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldIncomeEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_INCOME_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["Incomes"],
      transformResponse: (
        response: EndpointIncomeFiltered,
        _meta,
        params: TableOptions<Income>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((income: IncomeEndpoint) =>
          createAdapterToIncome(income)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createIncome: builder.mutation<any, Income[]>({
      query: (data: Income[]) => ({
        url: `${BASE_INCOME_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: data.map((i) => createAdapterToIncomeEndpoint(i)),
        },
      }),
      invalidatesTags: ["Incomes"],
    }),
    updateIncome: builder.mutation<any, Income>({
      query: (data: Income) => ({
        url: `${BASE_INCOME_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToIncomeEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: Income) => [
        "Incomes",
        { type: "Income", id: data?.id?.toString() },
      ],
    }),
    updateProgram: builder.mutation<any, any>({
      query: (data: string) => ({
        url: `${
          appConfig.API_NODE_URL
        }/estimaciones/ingreso/reprogramar/${stringToNumericalDate(data)}`,
        method: "POST",
      }),
      invalidatesTags: ["Incomes", "Income"],
    }),
    monthEndClose: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${appConfig.API_NODE_URL}/estimaciones/ingreso/cierre/${data?.year}/${data?.month}`,
        method: "POST",
      }),
      invalidatesTags: ["Incomes", "Income"],
    }),
    deleteIncome: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_INCOME_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Incomes"],
    }),
  }),
});

export const {
  useGetIncomeQuery,
  useGetIncomesQuery,
  useCreateIncomeMutation,
  useUpdateIncomeMutation,
  useUpdateProgramMutation,
  useMonthEndCloseMutation,
  useDeleteIncomeMutation,
} = incomeApi;
