import { createAdapterToBankAccount } from "../../../../parametry/financial/bankAccount/adapters/bankAccount.adapter";
import { createAdapterToChequeState } from "../../../../parametry/general/chequeState/adapters/chequeState.adapter";
import { createAdapterToChequeType } from "../../../../parametry/general/chequeType/adapters/chequeType.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  Beneficiary,
  BeneficiaryEndpoint,
  OwnCheque,
  OwnChequeEndpoint,
} from "../domain/OwnCheque.model";

type Dictionary = {
  [key: string]: string;
};

const ownChequeAttributesDiccionary: Dictionary = {
  id: "ChequePropioID",
  customerId: "BeneficiarioID",
  beneficiaryId : "BeneficiarioID",
  bankAccountId: "CuentaBancariaID",
  number: "Numero",
  issueDate: "FechaEmisionID",
  depositDate: "FechaDepositoID",
  dueDate: "FechaVencimientoID",
  chequeStateId: "EstadoID",
  chequeTypeId: "TipoChequeID",
  rejectionReason: "MotivoRechazo",
  amount: "Importe",
};

export const createAdapterToFieldOwnChequeEndpoint = (key: string): string => {
  return ownChequeAttributesDiccionary[key];
};

export const createAdapterToOwnChequeEndpoint = (ownCheque: OwnCheque) => {
  const formattedOwnCheque: Partial<OwnChequeEndpoint> = {
    ChequePropioID: ownCheque.id ?? undefined,
    BeneficiarioID: ownCheque.beneficiaryId,
    CuentaBancariaID: ownCheque.bankAccountId,
    Numero: ownCheque.number,
    FechaEmisionID: stringToNumericalDate(ownCheque.issueDate),
    FechaDepositoID: ownCheque.depositDate
      ? stringToNumericalDate(ownCheque.depositDate)
      : undefined,
    FechaVencimientoID: stringToNumericalDate(ownCheque.dueDate),
    EstadoID: ownCheque.chequeStateId,
    TipoChequeID: ownCheque.chequeTypeId,
    MotivoRechazo: ownCheque.rejectionReason,
    Importe: ownCheque.amount,
  };
  return formattedOwnCheque;
};

export const createAdapterToOwnCheque = (
  ownChequeEndpoint: OwnChequeEndpoint
) => {
  const formattedOwnCheque: OwnCheque = {
    id: ownChequeEndpoint.ChequePropioID,
    beneficiaryId: ownChequeEndpoint.BeneficiarioID,
    beneficiary: createAdapterToBeneficiary(ownChequeEndpoint.Beneficiario),
    bankAccountId: ownChequeEndpoint.CuentaBancariaID,
    bankAccount: createAdapterToBankAccount(ownChequeEndpoint.CuentaBancaria),
    number: ownChequeEndpoint.Numero,
    issueDate: numericalDateToString(ownChequeEndpoint.FechaEmisionID),
    depositDate: ownChequeEndpoint.FechaDepositoID
      ? numericalDateToString(ownChequeEndpoint.FechaDepositoID)
      : undefined,
    dueDate: numericalDateToString(ownChequeEndpoint.FechaVencimientoID),
    chequeStateId: ownChequeEndpoint.EstadoID,
    chequeState: createAdapterToChequeState(ownChequeEndpoint.Estado),
    chequeTypeId: ownChequeEndpoint.TipoChequeID,
    chequeType: createAdapterToChequeType(ownChequeEndpoint.TipoCheque),
    rejectionReason: ownChequeEndpoint.MotivoRechazo,
    amount: ownChequeEndpoint.Importe,
  };
  return formattedOwnCheque;
};

const createAdapterToBeneficiary = (
  beneficiaryEndpoint: BeneficiaryEndpoint
) => {
  //
  const formattedBeneficiary: Beneficiary = {
    personId: beneficiaryEndpoint.PersonaID,
    person: beneficiaryEndpoint.Persona,
    CUIT: beneficiaryEndpoint.CUIT,
  };
  return formattedBeneficiary;
};
