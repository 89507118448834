import { Country,CountryEndpoint } from "../domain/Country.model";

type Dictionary = {
    [key: string]: string;
};

const countryAttributesDiccionary: Dictionary = {
    id: 'PaisID',
    name: 'Pais',
}

export const createAdapterToFieldCountryEndpoint = (key: string): string => {
    return countryAttributesDiccionary[key];
}

export const createAdapterToCountryEndpoint = (country: Country) => {
    const formattedCountry: CountryEndpoint = {
        PaisID: country.id ?? undefined,
        Pais: country.name,
    };
    return formattedCountry;
}

export const createAdapterToCountry = (countryEndpoint: CountryEndpoint) => {
    const formattedCountry: Country = {
        id: countryEndpoint.PaisID,
        name: countryEndpoint.Pais,
    };
    return formattedCountry;
}