import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToExpense,
  createAdapterToExpenseEndpoint,
  createAdapterToFieldExpenseEndpoint,
} from "../adapters/expense.adapter";
import {
  EndpointExpenseFiltered,
  Expense,
  ExpenseEndpoint,
} from "../domain/Expense.model";
import { appConfig } from "../../../../config";
import { EndpointExpense } from "../domain/Expense.model";
import { stringToNumericalDate } from "../../../../utilities/dateUtils";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_EXPENSE_URL = "/estimaciones/egresos";
const BASE_EXPENSE_MUTATION_URL = "/estimaciones/egreso";
export const BASE_EXPENSE_EXPORT_URL = `${appConfig.API_NODE_URL}/estimaciones/egresos/export`;

export const expenseApi = createApi({
  reducerPath: "expenseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Expenses", "Expense"],
  endpoints: (builder) => ({
    getExpense: builder.query<Expense, number | undefined>({
      query: (id) => `${BASE_EXPENSE_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointExpense) => {
        return createAdapterToExpense(response.Content);
      },
      providesTags: (_res, _err, id) => [
        { type: "Expense", id: id?.toString() },
      ],
    }),
    getExpenses: builder.query<any, TableOptions<Expense> | undefined>({
      query: (params: TableOptions<Expense>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(f, createAdapterToFieldExpenseEndpoint)
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldExpenseEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_EXPENSE_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["Expenses"],
      transformResponse: (
        response: EndpointExpenseFiltered,
        _meta,
        params: TableOptions<Expense>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (expense: ExpenseEndpoint) => createAdapterToExpense(expense)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createExpense: builder.mutation<any, Expense[]>({
      query: (data: Expense[]) => ({
        url: `${BASE_EXPENSE_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: data.map((i) => createAdapterToExpenseEndpoint(i)),
        },
      }),
      invalidatesTags: ["Expenses"],
    }),
    updateExpense: builder.mutation<any, Expense>({
      query: (data: Expense) => ({
        url: `${BASE_EXPENSE_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToExpenseEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: Expense) => [
        "Expenses",
        { type: "Expense", id: data?.id?.toString() },
      ],
    }),
    updateProgram: builder.mutation<any, any>({
      query: (data: string) => ({
        url: `${
          appConfig.API_NODE_URL
        }/estimaciones/egreso/reprogramar/${stringToNumericalDate(data)}`,
        method: "POST",
      }),
      invalidatesTags: ["Expenses", "Expense"],
    }),
    monthEndClose: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${appConfig.API_NODE_URL}/estimaciones/egreso/cierre/${data?.year}/${data?.month}`,
        method: "POST",
      }),
      invalidatesTags: ["Expenses", "Expense"],
    }),
    deleteExpense: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_EXPENSE_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Expenses"],
    }),
  }),
});

export const {
  useGetExpenseQuery,
  useGetExpensesQuery,
  useCreateExpenseMutation,
  useUpdateExpenseMutation,
  useUpdateProgramMutation,
  useMonthEndCloseMutation,
  useDeleteExpenseMutation,
} = expenseApi;
