import styled from "styled-components";

interface StyledDashboardDateFilterWrapperProps {
  margin?: string;
}

export const StyledDashboardDateFilterWrapper = styled.div<StyledDashboardDateFilterWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
  /* padding: 3px 18px; */
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 5;
  //react-datepicker-wrapper
  input {
    border: none;
    outline: none;
    font-size: 1rem;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    text-align: center;
    color: #696969;
  }
  svg {
    top: 0px;
    right: -5px;
    font-size: 20px;
    color: #696969; 
  }
  .react-datepicker__header{
    margin: 10px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .react-datepicker__current-month, .react-datepicker__day-names {
      div {
        ::first-letter{
          text-transform: uppercase;
        }
      }
      ::first-letter{
        text-transform: uppercase;
      }
    }
  }
  .react-datepicker{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f3fa;
    button {
      margin: 10px;
    }
  }
  .react-datepicker__month-container{
    background-color: #f2f3fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .react-datepicker__month{
    margin: 5px 10px;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .react-datepicker__day--selected{
    background-color: #64b33b !important;
    border-radius: 100%;
    color: #fff !important;
    transition: 0.5s ease-in-out;
  }
  .react-datepicker__day--selected:hover{
    border-radius: 40% !important;
  }
  .react-datepicker__day--keyboard-selected{
    background-color: #64b33b95 !important;
    color: white !important;
    border-radius: 100%;
    transition: 0.5s ease-in-out;
  }
  .react-datepicker__day--keyboard-selected:hover{
    background-color: #64b33b95 ;
    border-radius: 40% !important;
  }

  .react-datepicker__day{
    transition: 0.3s ease-in-out;
  }
  .react-datepicker__day:hover{
    border-radius: 50%;
  }
`;

export const StyledDashboardDateFilterOptionsTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 5px 0px;
  margin-bottom: 5px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.482);
  text-transform: uppercase;
`;

export const OpcionesPredefinidasContenedor = styled.div<any>`
  display: ${props => props.opcPredefinidas ? "flex" : "none"};
  flex-direction: column;
  width: 105%;
  transform: translate(-5px);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

export const StyledDashboardDateFilterOptions = styled.div<any>`
  display: ${props => props.opcPredefinidas ? "flex" : "none"};
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;  
  gap: 5px;
  width: 100%;
  .opciones-predefinidas{
    width: 223%;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 10px 0;
    font-size: 14px;
  }
`;

export const StyledDashboardDateFilterOption = styled.p`
  width: 100%;
  cursor: pointer;
  padding: 6px 8px;
  font-weight: 500;
  color: gray;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
