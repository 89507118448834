import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToChequeType,
  createAdapterToChequeTypeEndpoint,
  createAdapterToFieldChequeTypeEndpoint
} from "../adapters/chequeType.adapter";
import { ChequeType, ChequeTypeEndpoint } from "../domain/ChequeType.model";
import { appConfig } from "../../../../config";
import { EndpointChequeType } from "../domain/ChequeType.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_CHEQUETYPE_URL = '/TipoCheque'
const BASE_CHEQUETYPE_MUTATION_URL = '/TipoCheque'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/TipoCheque/export`;

export const chequeTypeApi = createApi({
  reducerPath: "chequeTypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["ChequeTypes", "ChequeType"],
  endpoints: (builder) => ({
    getChequeType: builder.query<ChequeType, number | undefined>({
      query: (id) => `${BASE_CHEQUETYPE_MUTATION_URL}/${id}`,
      transformResponse: (response: ChequeTypeEndpoint) => {
        return createAdapterToChequeType(response)
      },
      providesTags: (_res, _err, id) => [{type: "ChequeType", id: id?.toString()}],
    }),
    getChequeTypes: builder.query<any, TableOptions<ChequeType> | undefined>({
      query: (params: TableOptions<ChequeType>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f, createAdapterToFieldChequeTypeEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldChequeTypeEndpoint(order.field), order:order.order}) : undefined;
        return {
          url: `${BASE_CHEQUETYPE_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["ChequeTypes"],
      transformResponse: (response: EndpointChequeType) => {
        return {
          data: response.rows.map((c: ChequeTypeEndpoint) =>
            createAdapterToChequeType(c)
          ),
          page: response.page,
          total: response.total,
        };
      },
    }),
    createChequeType: builder.mutation<any, ChequeType>({
      query: (data) => ({
        url: `${BASE_CHEQUETYPE_MUTATION_URL}`,
        method: "POST",
        body: createAdapterToChequeTypeEndpoint(data)
      }),
      invalidatesTags: ["ChequeTypes"],
    }),
    updateChequeType: builder.mutation<any, ChequeType>({
      query: (data: ChequeType) => ({
        url: `${BASE_CHEQUETYPE_MUTATION_URL}/${data.id}`,
        method: "PUT",
        body: createAdapterToChequeTypeEndpoint(data)
      }),
      invalidatesTags: (_res, _err, data: ChequeType) => ["ChequeTypes", {type: 'ChequeType', id: data?.id?.toString()}],
    }),
    deleteChequeType: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_CHEQUETYPE_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ChequeTypes"],
    }),
  }),
});

export const {
  useGetChequeTypeQuery,
  useGetChequeTypesQuery,
  useCreateChequeTypeMutation,
  useUpdateChequeTypeMutation,
  useDeleteChequeTypeMutation
} = chequeTypeApi;
