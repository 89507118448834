import {Location, LocationEndpoint} from "../domain/location.model";
import {
    numericalDateToString,
    stringToNumericalDate
} from "../../../utilities/dateUtils";

type Dictionary = {
    [key in keyof Location | string]: keyof LocationEndpoint;
};

const locationAttributesDiccionary: Dictionary = {
    date: "FechaID",
    conceptId: "ConceptoID",
    suggestedPercentage: "PorcentajeSugerido",
    firstEstimatePercentage: "PorcentajePrimeraEstimacion",
    lastEstimatePercentage: "PorcentajeUltimaEstimacion"
}

export const createAdapterToFieldLocationEndpoint = (key: string): string => {
    return locationAttributesDiccionary[key];
}

export const createAdapterToLocationEndpoint = (location: Location) => {
    const formattedLocation: LocationEndpoint = {
        FechaID: stringToNumericalDate(location.date),
        ConceptoID: location.conceptId,
        PorcentajeSugerido: location.suggestedPercentage,
        PorcentajePrimeraEstimacion: location.firstEstimatePercentage,
        PorcentajeUltimaEstimacion: location.lastEstimatePercentage
    };
    return formattedLocation;
}

export const createAdapterToLocation = (locationEndpoint: LocationEndpoint) => {
    const formattedLocation: Location = {
        date: numericalDateToString(locationEndpoint.FechaID),
        conceptId: locationEndpoint.ConceptoID,
        suggestedPercentage: locationEndpoint.PorcentajeSugerido,
        firstEstimatePercentage: locationEndpoint.PorcentajePrimeraEstimacion,
        lastEstimatePercentage: locationEndpoint.PorcentajeUltimaEstimacion
    };
    return formattedLocation;
}