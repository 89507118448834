import { ChequeState,ChequeStateEndpoint } from "../domain/ChequeState.model";

type Dictionary = {
    [key: string]: string;
};

const chequeStateAttributesDiccionary: Dictionary = {
    id: 'EstadoID',
    name: 'Estado',
}

export const createAdapterToFieldChequeStateEndpoint = (key: string): string => {
    return chequeStateAttributesDiccionary[key];
}

export const createAdapterToChequeStateEndpoint = (chequeState: ChequeState) => {
    const formattedChequeState: ChequeStateEndpoint = {
        EstadoID: chequeState.id ?? undefined,
        Estado: chequeState.name,
    };
    return formattedChequeState;
}

export const createAdapterToChequeState = (chequeStateEndpoint: ChequeStateEndpoint) => {
    const formattedChequeState: ChequeState = {
        id: chequeStateEndpoint.EstadoID,
        name: chequeStateEndpoint.Estado,
    };
    return formattedChequeState;
}