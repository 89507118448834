import translate from "../../../../../language/translate";
import { SidebarNavLogout } from "../styled-components/SidebarNavLogout";
import { StyledDropDownMenu } from "../styled-components/StyledDropDownMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useIntl } from "react-intl";
import DropMenuItem from "./DropMenuItem";
import { useLogoutMutation } from "../../../auth/services/authApi";
import { forwardRef } from "react";
import useSession from "../../../auth/services/useSession";
import { AuthenticatorType } from "../../../auth/domain/activeDirectory";

interface DropMenuTypes {
  openDropMenu?: boolean;
  dropdownToggle: () => void;
}

const DropMenu = forwardRef(
  ({ openDropMenu, dropdownToggle }: DropMenuTypes, ref: any) => {
    const [logout] = useLogoutMutation();
    const intl = useIntl();
    const session = useSession();
    const handleLogout = () => {
      logout();
    };

    return (
      <StyledDropDownMenu openDropMenu={openDropMenu} ref={ref}>
        {session.userData.type === AuthenticatorType.NATIVE ? (
          <DropMenuItem
            label={intl.formatMessage({
              id: "settings",
            })}
            link={`/profile/${session.userData.id}`}
            dropdownToggle={dropdownToggle}
          />
        ) : null }
        <SidebarNavLogout onClick={handleLogout}>
          <LogoutIcon />
          <span>{translate("exit")}</span>
        </SidebarNavLogout>
      </StyledDropDownMenu>
    );
  }
);

export default DropMenu;
