import {OrdersColumn, SortColumnProps} from "../domain/table.model";
import {StyledColumnOrderIcon} from "../styled-components/StyledTableCell";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {TableColumn} from "../domain/columns.model";

interface TableFieldOrderProps<T> {
    column: TableColumn<T>
    sortColumn?: SortColumnProps;
    onShortColumnChange: (newSort: SortColumnProps) => void;
}

function TableFieldOrder<T>({column, sortColumn, onShortColumnChange}: TableFieldOrderProps<T>){
    return (
        sortColumn?.field === column.field ? (
            sortColumn.order === OrdersColumn.ASC ? (
                <StyledColumnOrderIcon
                    active={sortColumn?.field === column.field}
                    onClick={() =>
                        onShortColumnChange({
                            field: column.field,
                            order: OrdersColumn.DESC,
                        })
                    }
                >
                    <NorthIcon />
                </StyledColumnOrderIcon>
            ) : (
                <StyledColumnOrderIcon
                    active={sortColumn?.field === column.field}
                    onClick={() =>
                        onShortColumnChange({
                            field: column.field,
                            order: OrdersColumn.ASC,
                        })
                    }
                >
                    <SouthIcon />
                </StyledColumnOrderIcon>
            )
        ) : (
            <StyledColumnOrderIcon
                onClick={() =>
                    onShortColumnChange({
                        field: column.field,
                        order: OrdersColumn.ASC,
                    })
                }
            >
                <SwapVertIcon />
            </StyledColumnOrderIcon>
        )
    )
}

export default TableFieldOrder;