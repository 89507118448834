import styled from "styled-components";

export const SidebarMenuItemIcon = styled.img<any>`
  /* background-color: #ffffff; */
  position: ${props => props.menuLateral ? 'relative' : '' };
  left: 115px;
  top: -35px;
  width: ${props => props.menuLateral ? '30px' : '20px'};
  height: ${props => props.menuLateral ? '50px' : '20px'};
  object-fit: contain;
  object-position: center;
`