import React, { FC, useRef } from "react";
import {
  StyledTablePagination,
  StyledTablePaginationBox,
  StyledTablePaginationDivider,
  StyledTablePaginationPageNumber,
  StyledTablePaginationSelect,
} from "../styled-components/StyledTablePagination";
import translate from "../../../../language/translate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { TablePaginationAttributes } from "../domain/table.model";

const TablePagination: FC<TablePaginationAttributes> = ({
  show,
  currentPage,
  pageSize,
  total,
  pageOptions,
  onPerPageChange,
  onPageChange
}) => {
  const pageRef: React.MutableRefObject<any> = useRef();
  const pages = total ? Math.ceil(total / pageSize) : 0;

  const changePerPage = (e: any) => {
    e.preventDefault();
    const { value } = e.target;
    onPerPageChange(value);
  };

  const changePage = (e: any) => {
    e.preventDefault();
    const { value } = e.target;
    if (value <= pages) {
      onPageChange(value);
    }
  };

  const handlePage = (e: any) => {
    e.preventDefault();
  };

  const handlePageKeyUp:
    | React.KeyboardEventHandler<HTMLInputElement>
    | undefined = (e) => {
    e.preventDefault();
    const key = e.key;
    if (key === "Enter" && pageRef.current) {
      pageRef.current.blur();
    }
  };

    const onNext = () => {
        const maxPage = total ? Math.ceil(total / pageSize) : 0;
        if (maxPage > currentPage) {
            onPageChange(currentPage + 1);
        }
    };

    const onFirst = () => {
      onPageChange(1);
    };

    const onLast = () => {
      const maxPage = total ? Math.ceil(total / pageSize) : 0;
      onPageChange(maxPage)
    };

    /*const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, type, value, checked } = event.target;

      const newValue = type === "checkbox" ? checked : type === "number" ? +value : value;

      setOptions({
        ..._options,
        filter: {
          ..._options.filter,
          [name]: newValue === "" ? null : newValue
        }
      });
    };*/

    const onPrevios = () => {
        const maxPage = total ? Math.ceil(total / pageSize) : 0;
        if (maxPage > currentPage - 1 && currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

  return (
    <StyledTablePagination show={show}>
      <StyledTablePaginationBox>
        <div className="navigate">
          <KeyboardDoubleArrowLeftIcon onClick={onFirst} />
          <NavigateBeforeIcon onClick={onPrevios} />
          {/* {currentPage > 2 ? 
            <StyledTablePaginationPageNumber
            ref={pageRef}
            type="number"
            value={currentPage - 2}
            onClick={()=>onPageChange(currentPage-2)}
            active={false}
            onChange={changePage}
            onBlur={handlePage}
            onKeyUp={handlePageKeyUp}
          />
          :
          null} */}
          {currentPage > 1 ? 
            <StyledTablePaginationPageNumber
            ref={pageRef}
            type="number"
            value={currentPage -1}
            onClick={()=>onPageChange(currentPage-1)}
            active={false}
            onChange={changePage}
            onBlur={handlePage}
            onKeyUp={handlePageKeyUp}
          />
          :
          null}
          <StyledTablePaginationPageNumber
            ref={pageRef}
            type="number"
            value={currentPage}
            active={true}
            onChange={changePage}
            onBlur={handlePage}
            onKeyUp={handlePageKeyUp}
          />
          {currentPage < pages ? 
            <StyledTablePaginationPageNumber
            ref={pageRef}
            type="number"
            value={currentPage + 1}
            active={false}
            onClick={()=>onPageChange(currentPage+1)}
            onChange={changePage}
            onBlur={handlePage}
            onKeyUp={handlePageKeyUp}
          />
          :
          null}
          {/* {currentPage < pages-1 ? 
            <StyledTablePaginationPageNumber
            ref={pageRef}
            type="number"
            value={currentPage + 2}
            active={false}
            onClick={()=>onPageChange(currentPage+2)}
            onChange={changePage}
            onBlur={handlePage}
            onKeyUp={handlePageKeyUp}
          />
          :
          null} */}
          <NavigateNextIcon onClick={onNext} />
          <KeyboardDoubleArrowRightIcon onClick={onLast} />
        </div>
        {/* {translate("of")}
        {` ${pages}`} */}
      </StyledTablePaginationBox>
      {/* <StyledTablePaginationDivider>|</StyledTablePaginationDivider> */}
      <StyledTablePaginationBox hiddenMobile={true}>
        {translate("show")}
        <StyledTablePaginationSelect
          id="demo-simple-select"
          value={pageSize}
          onChange={changePerPage}
        >
          {pageOptions &&
            pageOptions.map((opt: number, index: number) => (
              <option key={`page_option_${index}`} value={opt}>
                {opt}
              </option>
            ))}
        </StyledTablePaginationSelect>
        {` `}
        {translate("records")}
        {/*
        {total && (
          <StyledTablePaginationDivider>|</StyledTablePaginationDivider>
        )}

        {total && (
          <span>
            {translate("total")}: {total}
          </span>
        )} */}
      </StyledTablePaginationBox>
    </StyledTablePagination>
  );
};

export default TablePagination;
