import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {TableOptions} from "../../../../shared-kernel/components/Table";
import {
  createAdapterToConcept,
  createAdapterToConceptEndpoint,
  createAdapterToFieldConceptEndpoint
} from "../adapters/concept.adapter";
import {Concept, ConceptEndpoint, EndpointConceptFiltered} from "../domain/Concept.model";
import { appConfig } from "../../../../config";
import { EndpointConcept } from "../domain/Concept.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_CONCEPT_URL = '/entidadesfinancieras/conceptos'
const BASE_CONCEPT_MUTATION_URL = '/entidadesfinancieras/concepto'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/conceptos/export`;

export const conceptApi = createApi({
  reducerPath: "conceptApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Concepts", "Concept"],
  endpoints: (builder) => ({
    getConcept: builder.query<Concept, number | undefined>({
      query: (id) => `${BASE_CONCEPT_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointConcept) => {
        return createAdapterToConcept(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "Concept", id: id?.toString()}],
    }),
    getConcepts: builder.query<any, TableOptions<Concept>|undefined>({
      query: (params: TableOptions<Concept>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldConceptEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldConceptEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_CONCEPT_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["Concepts"],
      transformResponse: (response: EndpointConceptFiltered, _meta, params: TableOptions<Concept>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((concept: ConceptEndpoint) =>
            createAdapterToConcept(concept)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createConcept: builder.mutation<any, Concept>({
      query: (data) => ({
        url: `${BASE_CONCEPT_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToConceptEndpoint(data)
        },
      }),
      invalidatesTags: ["Concepts"],
    }),
    updateConcept: builder.mutation<any, Concept>({
      query: (data: Concept) => ({
        url: `${BASE_CONCEPT_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToConceptEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: Concept) => ["Concepts", {type: 'Concept', id: data?.id?.toString()}],
    }),
    deleteConcept: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_CONCEPT_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Concepts"],
    }),
  }),
});

export const {
  useGetConceptQuery,
  useGetConceptsQuery,
  useCreateConceptMutation,
  useUpdateConceptMutation,
  useDeleteConceptMutation
} = conceptApi;
