import SidebarNavMenuItemGroup from "./SidebarNavMenuItemGroup";
import SidebarNavMenuItemNode from "./SidebarNavMenuItemNode";
import { SidebarMenu } from "../domain/sidebarNav";

function SidebarNavMenuItem({ item}: { item: SidebarMenu }) {
  //SidebarNavMenuItemGroup son los links padres
  //SidebarNavMenuItemNode son los sub links
  return !item.isLeaf ? (
    <SidebarNavMenuItemGroup item={item} />
  ) : (
    <SidebarNavMenuItemNode item={item} />
  );
}

export default SidebarNavMenuItem;
