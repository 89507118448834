import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToBankAccount,
  createAdapterToBankAccountEndpoint,
  createAdapterToFieldBankAccountEndpoint,
} from "../adapters/bankAccount.adapter";
import {
  BankAccount,
  BankAccountEndpoint,
  EndpointBankAccountFiltered,
} from "../domain/BankAccount.model";
import { appConfig } from "../../../../config";
import { EndpointBankAccount } from "../domain/BankAccount.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_BANKACCOUNT_URL = "/entidadesfinancieras/cuentasbancarias";
const BASE_BANKACCOUNT_MUTATION_URL = "/entidadesfinancieras/cuentabancaria";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/cuentasbancarias/export`;

export const bankAccountApi = createApi({
  reducerPath: "bankAccountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["BankAccounts", "BankAccount", "BankAccountByAccountNumber", "MotherBankAccount"],
  endpoints: (builder) => ({
    getBankAccount: builder.query<BankAccount, number | undefined>({
      query: (id) => `${BASE_BANKACCOUNT_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointBankAccount) => {
        return createAdapterToBankAccount(response.Content);
      },
      providesTags: (_res, _err, id) => [
        { type: "BankAccount", id: id?.toString() },
      ],
    }),
    getBankAccountByAccountNumber: builder.query<
      BankAccount,
      string | undefined
    >({
      query: (accountNumber) =>
        `${BASE_BANKACCOUNT_MUTATION_URL}?accountnumber=${accountNumber}`,
      transformResponse: (response: EndpointBankAccount) => {
        return createAdapterToBankAccount(response.Content);
      },
      providesTags: ["BankAccountByAccountNumber"],
    }),
    getBankAccounts: builder.query<any, TableOptions<BankAccount> | undefined>({
      query: (params: TableOptions<BankAccount>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(
            f,
            createAdapterToFieldBankAccountEndpoint
          )
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldBankAccountEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_BANKACCOUNT_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["BankAccounts"],
      transformResponse: (
        response: EndpointBankAccountFiltered,
        _meta,
        params: TableOptions<BankAccount>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (bankAccount: BankAccountEndpoint) =>
            createAdapterToBankAccount(bankAccount)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createBankAccount: builder.mutation<any, BankAccount>({
      query: (data) => ({
        url: `${BASE_BANKACCOUNT_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToBankAccountEndpoint(data),
        },
      }),
      invalidatesTags: ["BankAccounts", "BankAccountByAccountNumber"],
    }),
    updateBankAccount: builder.mutation<any, BankAccount>({
      query: (data: BankAccount) => ({
        url: `${BASE_BANKACCOUNT_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToBankAccountEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: BankAccount) => [
        "BankAccounts",
        { type: "BankAccount", id: data?.id?.toString() },
        "BankAccountByAccountNumber",
      ],
    }),
    deleteBankAccount: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_BANKACCOUNT_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BankAccounts", "BankAccountByAccountNumber"],
    }),
    getMotherBankAccount: builder.query<BankAccount, number | undefined>({
      query: (id) => `${BASE_BANKACCOUNT_MUTATION_URL}/cuentamadre`,
      transformResponse: (response: EndpointBankAccount) => {
        return createAdapterToBankAccount(response.Content);
      },
      providesTags: (_res, _err, id) => [
        { type: "MotherBankAccount", id: id?.toString() },
      ],
    }),
  }),
});

export const {
  useGetBankAccountQuery,
  useGetBankAccountsQuery,
  useCreateBankAccountMutation,
  useUpdateBankAccountMutation,
  useDeleteBankAccountMutation,
  useGetBankAccountByAccountNumberQuery,
  useGetMotherBankAccountQuery,
} = bankAccountApi;
