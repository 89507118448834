import {ReactNode} from "react";

export type TableColumn<T> = {
  field: string;
  label: string;
  type: ColumnType;
  filterOperators?: TextFilterOperator[] | NumberFilterOperator[] | DateFilterOperator[] | IdentifierFilterOperator[];
  filterOptions?: FilterOption[];
  textAlign?: string;
  headerAlign?: string;
  width?: string;
  color?: string;
  background?: string;
  order?: boolean;
  hidden?: boolean;
  render?: (data: T) => ReactNode;
  mask?: (value: any) => any;
};

export type FilterOption = {
  id: string | number;
  label: string;
}

export enum ColumnType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  IDENTIFIER = 'identifier'
}

export enum TextFilterOperator {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
}

export enum IdentifierFilterOperator {
  ONLY = 'only',
  SOME = 'some'
}

export enum NumberFilterOperator {
  EQUALS = 'numberEquals',
  NOT_EQUALS = 'numberNotEquals',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
  BETWEEN = 'between'
}

export enum DateFilterOperator {
  EQUALS = 'dateEquals',
  NOT_EQUALS = 'dateNotEquals',
  AFTER = 'after',
  BEFORE = 'before',
  BETWEEN = 'dateBetween',
}

export type ValueType = string | number | Date | boolean | FilterOption;

export interface ColumnFilter<ValueType> {
  column?: string;
  operator?: string;
  values?: { value: ValueType }[]
}

export interface ColumnFilterNode<ValueType> {
  name?: string;
  operation?: string;
  values?:  ValueType []
}