import styled from "styled-components";

const ACTIONS_COLUMN_WIDTH: string = "3%";

interface HeaderCellProps {
  width?: string;
  textAlign?: string;
  color?: string;
  background?: string;
  hidden?: boolean;
  colorLine?: string;
}

interface CellProps {
  width?: string;
  textAlign?: string;
  color?: string;
  background?: string;
  hidden?: boolean;
  colspan?: string;
  colorLine?: string;
}

interface ColumnOrderIconProps {
  active?: boolean;
}

export const StyledColumnOptionsButton = styled.div``;

export const StyledTableHeaderCell = styled.th<HeaderCellProps>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  color: ${(props) =>
    props.color ? props.color : props.theme.table.headerColor};
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 0.875rem;
  position: sticky;
  top: 0;
  padding: 0 8px;
  font-weight: 600;
  @media (min-width: 600px) {
    display: ${(props) => (props.hidden ? "none" : "table-cell")};
  }
`;

export const StyledHeaderLabel = styled.div<ColumnOrderIconProps>`
  display: flex;
  gap: 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const StyledHeaderLabelActions = styled.div`
  
`

export const StyledColumnOrderIcon = styled.div<ColumnOrderIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #000000ba;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    color: #706f6f;
  }
  transition: 0.5s ease-in-out;
  opacity: ${props => props.active ? '1' : '0'};
  padding: 8px;
  :hover{
      visibility: visible;
      opacity: ${props => !props.active && '1'};
      background-color: #E6E7ED;
      border-radius: 10px;
  }
  `;

export const StyledTableCell = styled.td<CellProps>`
  position: relative;
  width: 100%;
  min-height: 30px;
  display: flex;
  column-span: ${(props) => (props.colspan ? props.colspan : "inherit")};
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  text-align: center;
  color: ${(props) =>
    props.color ? props.color : props.theme.table.textColor};
  border-bottom: 1px solid rgba(0,0,0,0.2);
  &:before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
  }
  @media (min-width: 600px) {
    display: ${(props) => (props.hidden ? "none" : "table-cell")};
    width: auto;
    text-align: center;
    &:before {
      display: none;
    }
  }
`;


export const StyledTableCellActions = styled(StyledTableCell)`
  width: 100%;
  text-align: right;
  @media (min-width: 600px) {
    width: ${`${ACTIONS_COLUMN_WIDTH}`};
  }
`

export const StyledTableHeaderCellActions = styled(StyledTableHeaderCell)`
  width: ${`${ACTIONS_COLUMN_WIDTH}`};
`
