import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToBankTransaction,
  createAdapterToBankTransactionEndpoint,
  createAdapterToFieldBankTransactionEndpoint
} from "../adapters/bankTransaction.adapter";
import { appConfig } from "../../../../config";
import {
  BankTransaction,
  BankTransactionEndpoint,
  EndpointBankTransaction,
  EndpointBankTransactionFiltered
} from "../domain/BankTransaction.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_BANKTRANSACTION_URL = '/entidadesfinancieras/operacionesbancos'
const BASE_BANKTRANSACTION_MUTATION_URL = '/entidadesfinancieras/operacionbanco'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/operacionesbancos/export`;

export const bankTransactionApi = createApi({
  reducerPath: "bankTransactionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["BankTransactions", "BankTransaction"],
  endpoints: (builder) => ({
    getBankTransaction: builder.query<BankTransaction, number | undefined>({
      query: (id) => `${BASE_BANKTRANSACTION_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointBankTransaction) => {
        return createAdapterToBankTransaction(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "BankTransaction", id: id?.toString()}],
    }),
    getBankTransactions: builder.query<any, TableOptions<BankTransaction>|undefined>({
      query: (params: TableOptions<BankTransaction>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldBankTransactionEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldBankTransactionEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_BANKTRANSACTION_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["BankTransactions"],
      transformResponse: (response: EndpointBankTransactionFiltered, _meta, params: TableOptions<BankTransaction>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((bankTransaction: BankTransactionEndpoint) =>
            createAdapterToBankTransaction(bankTransaction)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createBankTransaction: builder.mutation<any, BankTransaction>({
      query: (data) => ({
        url: `${BASE_BANKTRANSACTION_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToBankTransactionEndpoint(data)
        },
      }),
      invalidatesTags: ["BankTransactions"],
    }),
    updateBankTransaction: builder.mutation<any, BankTransaction>({
      query: (data: BankTransaction) => ({
        url: `${BASE_BANKTRANSACTION_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToBankTransactionEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: BankTransaction) => ["BankTransactions", {type: "BankTransaction", id: data?.id?.toString()}],
    }),
    deleteBankTransaction: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_BANKTRANSACTION_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BankTransactions"],
    }),
  }),
});

export const {
  useGetBankTransactionQuery,
  useGetBankTransactionsQuery,
  useCreateBankTransactionMutation,
  useUpdateBankTransactionMutation,
  useDeleteBankTransactionMutation
} = bankTransactionApi;
