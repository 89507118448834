import { SidebarMenu, SidebarMenuExtended } from "../shared-kernel/components/Layouts/TopbarLayout/domain/sidebarNav";

export function useGetStatusMenu(id:string , statusArray:SidebarMenuExtended[]) { //busca el id del array principal en el array auxiliar y devuelve su status
  if (statusArray.length > 0 ){
    const found = findMenuItemById(id, statusArray)
    return found!.expanded
  } else { 
    return false
  }
}
  
export function findMenuItemById( itemId:string, menuItems: SidebarMenuExtended[]): SidebarMenuExtended | undefined { //funcion que busca un elemento de id dado
      for (let i = 0; i < menuItems.length; i++) {
          if (menuItems[i]?.menuId === itemId) {
            return menuItems[i];
          }
          if (menuItems[i]?.submenus?.length > 0) {
            const foundMenuItem:any = findMenuItemById(itemId, menuItems[i].submenus);
            if (foundMenuItem){
              return foundMenuItem
            }
          }
      }
}

export function addExpandedProperty(menuItems:SidebarMenu[]) {
    return menuItems?.map((menu:SidebarMenu) => { 
      const updatedMenu = menu?.submenus?.length > 0? { ...menu, expanded: false }: menu;
        if (menu.submenus.length > 0) {
          updatedMenu.submenus = addExpandedProperty(menu.submenus);
        }
        return updatedMenu;
    });
  }
