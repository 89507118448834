import styled from "styled-components";

interface TableBodyProps {
    background?: string;
}

export const StyledTableBody = styled.tbody<TableBodyProps>`
:before{
   line-height:1em;
    content:".";
    color: transparent;
    display:block;
}
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: none;
`
