import { ColumnFilter, TableColumn, ValueType } from "./columns.model";
import { ReactNode } from "react";
import { ColumnSelectable } from "../tableUtils/columnSelector.hook";

export interface TableParameters<T> {
  search?: string;
  searchableColumns?: (keyof T)[];
  searchableTooltipLabels?: string[];
  page: number;
  pageSize: number;
  order?: SortColumnProps;
  filters?: ColumnFilter<ValueType>[];
}

export interface TableProps<T> {
  tableName?: string;
  columns: TableColumn<T>[];
  data?: TableDataProps<T>;
  parameters: TableParameters<T>;
  onChangeParameters: (newParameters: TableParameters<T>) => void;
  pageSizeOptions?: number[];
  actions?: TableActionsProps<T>[];
  defaultColumns?: string[];
  hidePagination?: boolean;
}

export interface TableBodyProps<T> {
  rows: T[];
  columns: TableColumn<T>[];
  selectedColumns: ColumnSelectable[];
  actions?: TableActionsProps<T>[];
  columnsOrder: string[];
}

export interface TableDataProps<T> {
  data: T[];
  total?: number;
}

export const enum OrdersColumn {
  ASC = "ASC",
  DESC = "DESC",
}

export interface SortColumnProps {
  field: string;
  order: OrdersColumn;
}

export interface TableOptions<T> {
  search?: string;
  searchableColumns?: (keyof T)[];
  page?: number;
  pageSize?: number;
  order?: SortColumnProps;
  filters?: ColumnFilter<ValueType>[];
}

export interface TablePaginationAttributes {
  show?: boolean;
  currentPage: number;
  pageSize: number;
  total?: number;
  pageOptions: number[];
  onPerPageChange: (newPage: number) => void;
  onPageChange: (newPage: number) => void;
}

export interface TableFilter {
  column: string;
  value: string;
  operator:
    | "equals"
    | "includes"
    | "startsWith"
    | "endsWith"
    | "lessThan"
    | "between"
    | "greaterThan";
}

export interface TableActionsProps<T> {
  name: string;
  toValidate?: (data?: T) => void;
  toExecute: (data?: T) => void;
  color?: (data?: T) => string;
  freeAction?: boolean;
  icon?: ReactNode;
}
