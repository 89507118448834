import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  EndpointRole, Role,
  RoleDataProps,
} from "../domain/Role.model";
import { createAdapterRole } from "../adapters/role.adapter";
import {TableOptions} from "../../shared-kernel/components/Table";
import {appConfig} from "../../config";

const API_URL_UM = appConfig.API_URL_UM
export const BASE_EXPORT_URL = `${appConfig.API_URL_UM}/role/export`;

export const roleApi = createApi({
    reducerPath: "RoleApi",
    baseQuery: fetchBaseQuery({
      baseUrl: API_URL_UM,
      prepareHeaders: (headers: Headers) => {
        const APP_TOKEN = "app_token";
        const token = localStorage.getItem(APP_TOKEN);

        if (token) {
          headers.set("authorization", `${token}`);
          headers.set("Content-Type", "application/json");
        }

        return headers;
      },
      credentials: "include",
    }),
    tagTypes: ["Roles", "Role"],
    endpoints: (builder) => ({
      getRoles: builder.query<RoleDataProps, TableOptions<Role> | undefined>({
        query: (tableOptions: TableOptions<Role>) =>{
          let queryParams: string = '';
          if(tableOptions !== undefined && tableOptions.page){
            const page = tableOptions.page - 1;
            queryParams += `page=${page}`;
          }
          if(tableOptions !== undefined && tableOptions.pageSize){
            queryParams += `&pageSize=${tableOptions.pageSize}`;
          }
          if(tableOptions !== undefined && tableOptions.order){
            const parsedOrder = JSON.stringify({
              field: tableOptions.order.field,
              order: tableOptions.order.order
            })
            queryParams += `&order=${parsedOrder}`;
          }
          if(tableOptions !== undefined && tableOptions.search){
            queryParams += `&search=${tableOptions.search}`;
          }
          return `roles/byapplication/${appConfig.APPLICATION_ID}${queryParams !== '' ? `?${queryParams}` : ''}`
        }
          ,
        providesTags: ["Roles"],
        transformResponse: (response: any) => {
          return {
            data: response.map((r: EndpointRole) => createAdapterRole(r)),
            page: 1,
            total: response.length,
          };
        }
      }),
      getRole: builder.query<EndpointRole, string | undefined>({
        query: (id) => `roles/${id}?applicationId=${appConfig.APPLICATION_ID}`,
        providesTags: (_res, _err, id) => [{type: "Role", id: id?.toString()}],
        transformResponse: (response: EndpointRole) => createAdapterRole(response),
      }),
      createRole: builder.mutation<any, any>({
        query: (data) => ({
          url: 'roles/',
          method: "POST",
          body: {
            ...data,
            applicationId: appConfig.APPLICATION_ID
          },
        }),
        invalidatesTags: ["Roles"],
      }),
      updateRole: builder.mutation<any, EndpointRole>({
        query: (data: EndpointRole) => {
          const bodyData = createAdapterRole(data);
          return {
            url: `roles/${data.id}`,
            method: "PUT",
            body: {
              ...bodyData,
              applicationId: appConfig.APPLICATION_ID
            }
          }
        },
        invalidatesTags: (_res, _err, data: EndpointRole) => ["Roles", {type: 'Role', id: data?.id?.toString()}],
      }),
      deleteRole: builder.mutation<any, any>({
        query: (id) => ({
          url: `${API_URL_UM}/roles/${id}`,
          method: "DELETE",
          body: {
            applicationId: appConfig.APPLICATION_ID
          }
        }),
        invalidatesTags: ['Roles']
      }),
    }),
  });

export const { useGetRolesQuery,useGetRoleQuery,useDeleteRoleMutation,useUpdateRoleMutation,useCreateRoleMutation } = roleApi;
