export interface ActiveDirectoryContextProps {
  enabledActiveDirectory: boolean;
  setActiveDirectory: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ActiveDirectoryProps {
  children: React.ReactNode;
}

export type ActiveDirectoryOption = {
  value: boolean;
};

export enum AuthenticatorType {
  NATIVE = "Native",
  ACTIVE_DIRECTORY = "ActiveDirectory",
}
