import { createSlice } from '@reduxjs/toolkit';
import { SidebarMenuExtended } from '../domain/sidebarNav';


export const menuStatusSlice = createSlice({
  name: 'menuStatus',
  initialState: {
    menus : [] as SidebarMenuExtended[], // array auxiliar donde voy a guardar el status expanded de cada item
  },
  reducers: {
    changeStatusMenu: (state, action) => { //actualiza el estado de redux con el nuevo array auxiliar
      if (state.menus.length > 0 ){
        const  itemId  = action.payload;
      const updatedMenus = toggleExpandedById(state.menus, itemId);
      state.menus = updatedMenus;
      }
    },
    changeToFalseMenu: (state, action) => { //actualiza el estado de redux con el nuevo array auxiliar
      const  itemId  = action.payload;
      if (state.menus.length > 0 ){
        const updatedMenus = ExpandedToFalseById(state.menus, itemId);
        state.menus = updatedMenus;
      }
    },
    fetchAndInitializeMenus: (state, action) => {
        const  expandedArray  = action.payload;
        state.menus = expandedArray;
    }
  }
});

function toggleExpandedById(menuItems: any[], targetId: any): any {
    return menuItems.map((menuItem: any) => {
      if (menuItem.menuId === targetId) {
        return { ...menuItem, expanded: !menuItem.expanded };
      }
      if (menuItem.submenus.length > 0) {
        const updatedSubmenus = toggleExpandedById(menuItem.submenus, targetId);
        return { ...menuItem, submenus: updatedSubmenus };
      }
      return menuItem;
    });
  }

  function ExpandedToFalseById(menuItems: any[], targetId: any): any { //cambia el status del elemento del array auxiliar: expanded false->true y true->false 
    return menuItems.map((menuItem: any) => {
        if (menuItem.menuId === targetId) {
          return { ...menuItem, expanded: false };
        }
        if (menuItem.submenus.length > 0) {
          const updatedSubmenus = ExpandedToFalseById(menuItem.submenus, targetId);
          return { ...menuItem, submenus: updatedSubmenus };
        }
        return menuItem;
      });
    }

export const { changeStatusMenu, changeToFalseMenu,fetchAndInitializeMenus } = menuStatusSlice.actions;

export default menuStatusSlice.reducer;
