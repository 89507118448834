import {StyledFilterTag} from "../../styled-components/StyledFilterTag";
import {ColumnFilter, FilterOption, IdentifierFilterOperator, TableColumn, ValueType} from "../../domain/columns.model";
import {useIntl} from "react-intl";
import CloseIcon from "@mui/icons-material/Close";

interface FilterTagProps<T> {
    filter: ColumnFilter<ValueType>;
    columns: TableColumn<T>[];
    onRemove: (filterToRemove: ColumnFilter<ValueType>) => void;
}

export function FilterTag<T>({ filter, columns, onRemove }:FilterTagProps<T>) {
    const intl = useIntl();
    const handleRemove = () => {
        onRemove(filter);
    }
    return (
        <StyledFilterTag>
            <strong> {columns.find((col) => col.field === filter.column)?.label ?? intl.formatMessage({ id: filter.column }) } </strong>
            <span>{` ${intl.formatMessage({ id: filter.operator })} `}</span>
            {
                filter.operator === IdentifierFilterOperator.SOME ?
                    <strong> { filter.values?.map((value: any) => value.value.label).join(', ') } </strong>
                    :
                    filter.operator === IdentifierFilterOperator.ONLY ?
                        <strong> { (filter.values?.[0].value as FilterOption).label } </strong>
                        :
                    <strong> { filter.values?.map(value => value.value).join(` ${intl.formatMessage({ id: 'and' })} `) } </strong>
            }
            <CloseIcon onClick={handleRemove} />
        </StyledFilterTag>
    )
}
