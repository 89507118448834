import {useAppSelector} from "../../../../utilities/hooks/redux.hooks";
import {decodeToken} from "../utilities/jwt";
import {UserData} from "../domain/auth";
import {appConfig} from "../../../../config";

const useSession = () => {
    const ROL_ADMIN_NAME = appConfig.ROL_ADMIN_NAME;
    const session = useAppSelector(state => state.session);
    const userData: UserData = session.token ? decodeToken(session.token) : undefined;

    const hasPermission = (permissionName: string) => {
        if(userData?.role?.name === ROL_ADMIN_NAME)
            return true;
        return !!userData?.role?.permissions?.find(permission => permission.name === permissionName);
    }

    return {
        userData,
        hasPermission
    }
}

export default useSession;
