import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "../../domain/theme";

const APP_THEME_ITEM_NAME = "appTheme";

const currentThemeName = localStorage.getItem(APP_THEME_ITEM_NAME) ?? "light";

const initialState: Theme = {
  selectedTheme: currentThemeName,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      const newTheme = action.payload;
      localStorage.setItem(APP_THEME_ITEM_NAME, newTheme);
      state.selectedTheme = newTheme;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
