import { useRoutes } from "react-router-dom";
import router from "./router";
import { SidebarProvider } from "./shared-kernel/components/Sidebar/SidebarContext";
import "nprogress/nprogress.css";
import  LanguageProvider  from "./language/presentation/LanguageProvider";
import ThemeProviderWrapper from "./theme/presentation/ThemeProvider";
import NotificationBar from "./shared-kernel/components/NotificationBar/NotificationBar";
import {ErrorBoundary} from "react-error-boundary";
import { useAppDispatch } from "./utilities/hooks/redux.hooks";
import { useGetSidebarMenusQuery } from "./shared-kernel/components/menu/services/menuApi";
import { useEffect } from "react";
import { fetchAndInitializeMenus } from "./shared-kernel/components/Layouts/TopbarLayout/state/menuStatusSlice.reducer";
import { addExpandedProperty } from "./utilities/menuStatusUtils";

function AppWithStore() {
  const content = useRoutes(router);
  const dispatch = useAppDispatch();
  const { data, isSuccess } = useGetSidebarMenusQuery(undefined);

  useEffect(() => {
    if (isSuccess){
      const expandedArray = addExpandedProperty(data)
      dispatch(fetchAndInitializeMenus(expandedArray))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  return (
    <>
        {isSuccess?
        <LanguageProvider>
          <SidebarProvider>
            <ThemeProviderWrapper>
              <ErrorBoundary fallback={<div>Algo salio mal</div>}>
                {content}
                <NotificationBar />
              </ErrorBoundary>
            </ThemeProviderWrapper>
          </SidebarProvider>
        </LanguageProvider>
        :
        null}
    </>
  );
}

export default AppWithStore;