import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  StatementMovementImport,
  StatementMovementImportEndpoint,
} from "../domain/StatementMovementImport.model";

type Dictionary = {
  [key: string]: string;
};

const statementMovementAttributesDiccionary: Dictionary = {
  id: "ExtractoBancarioMovimientoID",
  registerType: "TipoRegistro",
  bankCode: "CodigoBanco",
  shortAccountNumber: "NumeroCuentaCorto",
  movementDate: "FechaMovimiento",
  valueDate: "FechaValor",
  movementType: "TipoMovimiento",
  amount: "Importe",
  voucherNumber: "NumeroComprobante",
  opCode: "CodigoOperacion",
  processDate: "FechaProceso",
  freeInfo: "InformacionLibre",
  branch: "SucursalOrigen",
  depositorCode: "CodigoDepositante",
  longAccountNumber: "NumeroCuentaLargo",
  bankOpCode: "CodigoOperacionBanco",
  currencyType: "TipoMoneda",
  CUIT: "CUIT",
};

export const createAdapterToFieldStatementMovementEndpoint = (
  key: string
): string => {
  return statementMovementAttributesDiccionary[key];
};

export const createAdapterToStatementMovementEndpoint = (
  statementMovementImport: StatementMovementImport
) => {
  const formattedStatementMovementImport: StatementMovementImportEndpoint = {
    TipoRegistro: statementMovementImport.registerType,
    CodigoBanco: statementMovementImport.bankCode,
    NumeroCuentaCorto: statementMovementImport.shortAccountNumber,
    FechaMovimiento: stringToNumericalDate(
      statementMovementImport.movementDate
    ),
    FechaValor: stringToNumericalDate(statementMovementImport.valueDate),
    TipoMovimiento: statementMovementImport.movementType,
    Importe: statementMovementImport.amount,
    NumeroComprobante: statementMovementImport.voucherNumber,
    CodigoOperacion: statementMovementImport.opCode,
    FechaProceso: stringToNumericalDate(statementMovementImport.processDate),
    InformacionLibre: statementMovementImport.freeInfo,
    SucursalOrigen: statementMovementImport.branch,
    CodigoDepositante: statementMovementImport.depositorCode,
    NumeroCuentaLargo: statementMovementImport.longAccountNumber,
    CodigoOperacionBanco: statementMovementImport.bankOpCode,
    TipoMoneda: statementMovementImport.currencyType.toString(),
    CBU: statementMovementImport.CBU,
    CUIT: statementMovementImport.CUIT,
    Denominacion: statementMovementImport.denomination,
  };
  return formattedStatementMovementImport;
};

export const createAdapterToStatementMovementImport = (
  statementMovementImportEndpoint: StatementMovementImportEndpoint
) => {
  const formattedStatementMovementImport: StatementMovementImport = {
    registerType: statementMovementImportEndpoint.TipoRegistro,
    bankCode: statementMovementImportEndpoint.CodigoBanco,
    shortAccountNumber: statementMovementImportEndpoint.NumeroCuentaCorto,
    movementDate: numericalDateToString(
      statementMovementImportEndpoint.FechaMovimiento
    ),
    valueDate: numericalDateToString(
      statementMovementImportEndpoint.FechaValor
    ),
    movementType: statementMovementImportEndpoint.TipoMovimiento,
    amount: statementMovementImportEndpoint.Importe,
    voucherNumber: statementMovementImportEndpoint.NumeroComprobante,
    opCode: statementMovementImportEndpoint.CodigoOperacion,
    processDate: numericalDateToString(
      statementMovementImportEndpoint.FechaProceso
    ),
    freeInfo: statementMovementImportEndpoint.InformacionLibre,
    branch: statementMovementImportEndpoint.SucursalOrigen,
    depositorCode: statementMovementImportEndpoint.CodigoDepositante,
    longAccountNumber: statementMovementImportEndpoint.NumeroCuentaLargo,
    bankOpCode: statementMovementImportEndpoint.CodigoOperacionBanco,
    currencyType: parseInt(statementMovementImportEndpoint.TipoMoneda),
    CBU: statementMovementImportEndpoint.CBU,
    CUIT: statementMovementImportEndpoint.CUIT,
    denomination: statementMovementImportEndpoint.Denominacion,
  };
  return formattedStatementMovementImport;
};
