import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
type SidebarContextProps = {
  verMenuLateral: any;
  setVerMenuLateral: Dispatch<SetStateAction<boolean>>;
  mostrarOcultarMenu: () => void;
};

interface Props {
  children?: React.ReactNode
}

export const SidebarContext = createContext<SidebarContextProps>(
  {} as SidebarContextProps
);

export const SidebarProvider: FC<Props> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [verMenuLateral, setVerMenuLateral] = useState<boolean>(false);
  const mostrarOcultarMenu = () => {
    setVerMenuLateral(!verMenuLateral);
  }
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ verMenuLateral, setVerMenuLateral, mostrarOcultarMenu }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
