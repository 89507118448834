import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  TimeZone } from "../domain/TimeZone.model";

const DEFAULT_TIMEZONE_OFFSET = '0';

const currentTimeZoneOffset = localStorage.getItem('APP_TIMEZONE_OFFSET') ?? DEFAULT_TIMEZONE_OFFSET

const initialState: TimeZone = {
    offset: currentTimeZoneOffset,
};

export const timezoneSlice = createSlice({
  name: "timezone",
  initialState: initialState,
  reducers: {
    setTimeZone: (state, action: PayloadAction<string>) => {
      const newOffset = action.payload;
      localStorage.setItem('APP_TIMEZONE_OFFSET', newOffset);
      state.offset = newOffset;
    },
  },
});

export const { setTimeZone } = timezoneSlice.actions;

export default timezoneSlice.reducer;
