import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToContact,
  createAdapterToContactEndpoint,
  createAdapterToFieldContactEndpoint
} from "../adapters/contact.adapter";
import { Contact, ContactEndpoint, EndpointContactFiltered } from "../domain/Contact.model";
import { appConfig } from "../../../../config";
import { EndpointContact } from "../domain/Contact.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_CONTACT_URL = '/general/contactos'
const BASE_CONTACT_MUTATION_URL = '/general/contactos'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/general/contactos/export`;

export const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Contacts", "Contact"],
  endpoints: (builder) => ({
    getContact: builder.query<Contact, number | undefined>({
      query: (id) => `${BASE_CONTACT_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointContact) => {
        return createAdapterToContact(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "Contact", id: id?.toString()}],
    }),
    getContacts: builder.query<any, TableOptions<Contact>|undefined>({
      query: (params: TableOptions<Contact>) => {
        const { page, pageSize, order, search, filters } = params;
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldContactEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldContactEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_CONTACT_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["Contacts"],
      transformResponse: (response: EndpointContactFiltered, _meta, params: TableOptions<Contact>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((contact: ContactEndpoint) =>
            createAdapterToContact(contact)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createContact: builder.mutation<any, Contact>({
      query: (data) => ({
        url: `${BASE_CONTACT_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToContactEndpoint(data)
        },
      }),
      invalidatesTags: ["Contacts"],
    }),
    updateContact: builder.mutation<any, Contact>({
      query: (data: Contact) => ({
        url: `${BASE_CONTACT_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToContactEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: Contact) => ["Contacts", {type: 'Contact', id: data?.id?.toString()}],
    }),
    deleteContact: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_CONTACT_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contacts"],
    }),
  }),
});

export const {
  useGetContactQuery,
  useGetContactsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation
} = contactApi;
