import { createAdapterToBank } from "../../../../parametry/financial/banks/adapters/bank.adapter";
import { createAdapterToChequeState } from "../../../../parametry/general/chequeState/adapters/chequeState.adapter";
import { createAdapterToChequeType } from "../../../../parametry/general/chequeType/adapters/chequeType.adapter";
import { createAdapterToCurrency } from "../../../../parametry/general/currency/adapters/currency.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  Customer_wPersona,
  Customer_wPersonaEndpoint,
  ThirdPartyCheque,
  ThirdPartyChequeEndpoint,
} from "../domain/ThirdPartyCheque.model";

type Dictionary = {
  [key: string]: string;
};

const thirdPartyChequeAttributesDiccionary: Dictionary = {
  id: "ChequeTerceroID",
  customerId: "ClienteID",
  bankId: "BancoID",
  number: "Numero",
  currencyId: "MonedaID",
  issueDate: "FechaEmisionID",
  depositDate: "FechaDepositoID",
  dueDate: "FechaVencimientoID",
  chequeStateId: "EstadoID",
  chequeTypeId: "TipoChequeID",
  rejectionReason: "MotivoRechazo",
  amount: "Importe",
  CUIT: "CUIT",
};

export const createAdapterToFieldThirdPartyChequeEndpoint = (
  key: string
): string => {
  return thirdPartyChequeAttributesDiccionary[key];
};

export const createAdapterToThirdPartyChequeEndpoint = (
  thirdPartyCheque: ThirdPartyCheque
) => {
  const formattedThirdPartyCheque: Partial<ThirdPartyChequeEndpoint> = {
    ChequeTerceroID: thirdPartyCheque.id ?? undefined,
    ClienteID: thirdPartyCheque.customerId,
    BancoID: thirdPartyCheque.bankId,
    MonedaID: thirdPartyCheque.currencyId,
    Numero: thirdPartyCheque.number,
    CUIT: thirdPartyCheque.CUIT,
    FechaEmisionID: stringToNumericalDate(thirdPartyCheque.issueDate),
    FechaDepositoID: thirdPartyCheque.depositDate
      ? stringToNumericalDate(thirdPartyCheque.depositDate)
      : undefined,
    FechaVencimientoID: stringToNumericalDate(thirdPartyCheque.dueDate),
    EstadoID: thirdPartyCheque.chequeStateId,
    TipoChequeID: thirdPartyCheque.chequeTypeId,
    MotivoRechazo: thirdPartyCheque.rejectionReason,
    Importe: thirdPartyCheque.amount,
  };
  return formattedThirdPartyCheque;
};

export const createAdapterToThirdPartyCheque = (
  thirdPartyChequeEndpoint: ThirdPartyChequeEndpoint
) => {
  const formattedThirdPartyCheque: ThirdPartyCheque = {
    id: thirdPartyChequeEndpoint.ChequeTerceroID,
    customerId: thirdPartyChequeEndpoint.ClienteID,
    customer: createAdapterToCustomer_wPersona(
      thirdPartyChequeEndpoint.Cliente
    ),
    bankId: thirdPartyChequeEndpoint.BancoID,
    bank: createAdapterToBank(thirdPartyChequeEndpoint.Banco),
    currencyId: thirdPartyChequeEndpoint.MonedaID,
    currency: createAdapterToCurrency(thirdPartyChequeEndpoint.Moneda),
    number: thirdPartyChequeEndpoint.Numero,
    CUIT: thirdPartyChequeEndpoint.CUIT,
    issueDate: numericalDateToString(thirdPartyChequeEndpoint.FechaEmisionID),
    depositDate: thirdPartyChequeEndpoint.FechaDepositoID
      ? numericalDateToString(thirdPartyChequeEndpoint.FechaDepositoID)
      : undefined,
    dueDate: numericalDateToString(thirdPartyChequeEndpoint.FechaVencimientoID),
    chequeStateId: thirdPartyChequeEndpoint.EstadoID,
    chequeState: createAdapterToChequeState(thirdPartyChequeEndpoint.Estado),
    chequeTypeId: thirdPartyChequeEndpoint.TipoChequeID,
    chequeType: createAdapterToChequeType(thirdPartyChequeEndpoint.TipoCheque),
    rejectionReason: thirdPartyChequeEndpoint.MotivoRechazo,
    amount: thirdPartyChequeEndpoint.Importe,
  };
  return formattedThirdPartyCheque;
};

const createAdapterToCustomer_wPersona = (
  customer_wPersonaEndpoint: Customer_wPersonaEndpoint
) => {
  //
  const formattedcustomer_wPersona: Customer_wPersona = {
    personId: customer_wPersonaEndpoint.PersonaID,
    person: customer_wPersonaEndpoint.Persona,
    CUIT: customer_wPersonaEndpoint.CUIT,
  };
  return formattedcustomer_wPersona;
};
