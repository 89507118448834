import { Process, ProcessEndpoint } from "../domain/Process.model";

type Dictionary = {
  [key: string]: string;
};

const processAttributesDiccionary: Dictionary = {
  id: "ProcesoID",
  name: "Proceso",
  code: "Codigo",
};

export const createAdapterToFieldProcessEndpoint = (key: string): string => {
  return processAttributesDiccionary[key];
};

export const createAdapterToProcessEndpoint = (process: Process) => {
  const formattedProcess: ProcessEndpoint = {
    ProcesoID: process.id,
    Proceso: process.name,
    Codigo: process.code,
  };
  return formattedProcess;
};

export const createAdapterToProcess = (processEndpoint: ProcessEndpoint) => {
  const formattedProcess: Process = {
    id: processEndpoint.ProcesoID,
    name: processEndpoint.Proceso,
    code: processEndpoint.Codigo,
  };
  return formattedProcess;
};
