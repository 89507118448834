import GlobalStyles from "./styled-components/GlobalStyles";
import { Provider } from "react-redux";
import "nprogress/nprogress.css";
import { store } from "./store";
import AppWithStore from "./AppWithStore";

function App() {
  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
        <AppWithStore />
      </Provider>
    </>
  );
}

export default App;
