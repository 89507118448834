import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToCurrency,
  createAdapterToCurrencyEndpoint,
  createAdapterToFieldCurrencyEndpoint
} from "../adapters/currency.adapter";
import { Currency, CurrencyEndpoint, EndpointCurrencyFiltered } from "../domain/Currency.model";
import { appConfig } from "../../../../config";
import { EndpointCurrency } from "../domain/Currency.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";


const BASE_CURRENCY_URL = '/general/monedas'
const BASE_CURRENCY_MUTATION_URL = '/general/moneda'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/general/monedas/export`;

export const currencyApi = createApi({
  reducerPath: "currencyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Currencies", "Currency"],
  endpoints: (builder) => ({
    getCurrency: builder.query<Currency, number | undefined>({
      query: (id) => `${BASE_CURRENCY_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointCurrency) => {
        return createAdapterToCurrency(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "Currency", id: id?.toString()}],
    }),
    getCurrencies: builder.query<any, TableOptions<Currency>|undefined>({
      query: (params: TableOptions<Currency>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldCurrencyEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldCurrencyEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_CURRENCY_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["Currencies"],
      transformResponse: (response: EndpointCurrencyFiltered, _meta, params: TableOptions<Currency>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((currency: CurrencyEndpoint) =>
            createAdapterToCurrency(currency)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createCurrency: builder.mutation<any, Currency>({
      query: (data) => ({
        url: `${BASE_CURRENCY_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToCurrencyEndpoint(data)
        },
      }),
      invalidatesTags: ["Currencies"],
    }),
    updateCurrency: builder.mutation<any, Currency>({
      query: (data: Currency) => ({
        url: `${BASE_CURRENCY_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToCurrencyEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: Currency) => ["Currencies", {type: 'Currency', id: data?.id?.toString()}],
    }),
    deleteCurrency: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_CURRENCY_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Currencies"],
    }),
  }),
});

export const {
  useGetCurrencyQuery,
  useGetCurrenciesQuery,
  useCreateCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyMutation
} = currencyApi;
