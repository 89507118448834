import styled from "styled-components";
import { TextField } from "@mui/material";

export const DefaultInput3 = styled(TextField)``;

export const DefaultInput2 = styled.input`
  position: relative;
  width: 100%;
  padding: 15px 20px;
  outline: none;
  font-size: 1.25em;
  color: ${(props) => props.theme.layout.login.inputColor};
  border-radius: 5px;
  background: ${(props) => props.theme.layout.login.inputBg};
  border: none;
  &::placeholder {
    color: ${(props) => props.theme.layout.login.title};
  }
`;
export const DefaultInput = styled.input`
  position: relative;
  outline: none;
  padding: 8.5rem 14px;
  font-size: 0.75em;
  color: ${(props) => props.theme.table.textColor};
  border-radius: 5px;
  border: none;

  &[type="checkbox"] {
    width: 15px;
    height: 15px;
    background-color: green;
    accent-color: green;
    border: 2px solid green;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }

  &[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
    position: relative;
  }
`;
