import { State, StateEndpoint } from "../domain/State.model";

type Dictionary = {
    [key: string]: string;
};

const stateAttributesDiccionary: Dictionary = {
    id: 'EstadoID',
    name: 'Estado',
}

export const createAdapterToFieldStateEndpoint = (key: string): string => {
    return stateAttributesDiccionary[key];
}

export const createAdapterToStateEndpoint = (state: State) => {
    const formattedState: StateEndpoint = {
        EstadoID: state.id ?? undefined,
        Estado: state.name,
    };
    return formattedState;
}

export const createAdapterToState = (stateEndpoint: StateEndpoint) => {
    const formattedState: State = {
        id: stateEndpoint.EstadoID,
        name: stateEndpoint.Estado,
    };
    return formattedState;
}