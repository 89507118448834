import {
    StyledFiltersContainer,
    StyledFiltersHeader
} from "../../styled-components/StyledFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useIntl } from "react-intl";

interface TableFilterProps{
    openFilter: boolean;
    setOpenFilter: (openFilter: boolean) => void;
}

function TableFilter({openFilter, setOpenFilter}: TableFilterProps){

    const intl = useIntl();

    const switchAddFilter = () => {
        setOpenFilter(!openFilter);
    }

    return (
        <StyledFiltersContainer onClick={switchAddFilter} title={intl.formatMessage({id: 'addFilter'})}>
            <FilterListIcon />
        </StyledFiltersContainer>
    )
}

export default TableFilter;
