import moment from "moment";

export function numericalDateToDate(numericalDate: number): Date {
  const year = Math.floor(numericalDate / 10000);
  const month = Math.floor((numericalDate % 10000) / 100);
  const day = numericalDate % 100;
  return new Date(year, month - 1, day);
}

export function dateToNumber(input: Date): number {
  const date = new Date(`${input} 00:00`);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return parseInt(`${year}${month}${day}`);
}

export function dateOrStringDateToNumber(
  input: Date | string | null
): number | null {
  if (input === null) {
    return null;
  }
  const date = new Date(input);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return parseInt(`${year}${month}${day}`);
}

export function dateConGuionANumber(input: string) {
  const [day, month, year] = input.split("-");
  return (`${year}${month}${day}`);
}

export function numericalDateToString(numericalDate: number): string {
  const year = Math.floor(numericalDate / 10000);
  const month = Math.floor((numericalDate % 10000) / 100);
  const day = numericalDate % 100;
  const paddedMonth = month.toString().padStart(2, "0");
  const paddedDay = day.toString().padStart(2, "0");
  return `${year}-${paddedMonth}-${paddedDay}`;
}

export function stringToNumericalDate(input: string): number {
  const [year, month, day] = input.split("-");
  const numericYear = parseInt(year, 10);
  const numericMonth = parseInt(month, 10);
  const numericDay = parseInt(day, 10);
  return numericYear * 10000 + numericMonth * 100 + numericDay;
}

export function stringDateFormatter(input: string | undefined): string {
  if (input === undefined || parseInt(input.replace(/-/g, "")) === 0) {
    return "";
  } else {
    const dateObj = new Date(`${input} 00:00`);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}-${formattedMonth}-${year}`;
  }
}

export function exportToXLSXFormater(input: string | undefined): string {
  if (input === undefined || parseInt(input.replace(/-/g, "")) === 0) {
    return "";
  } else {
    const [year, month, day] = input.split("-");
    return `${day}/${month}/${year}`;
  }
}

export function stringHourFormatter(input: string | undefined): string {
  if (input === undefined || input === "0") {
    return "";
  } else {
    const timeNumber = Number(input);
    const hours = Math.floor(timeNumber / 10000);
    const minutes = Math.floor((timeNumber % 10000) / 100);
    const seconds = timeNumber % 100;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
}

export function xlsxDateToInnerType(input: string): string {
  const regex = new RegExp("[0-9]{1,2}/[0-9]{1,2}/([0-9]{2}){1,2}$");
  if (!regex.test(input)) {
    return "-1";
  }
  const [day, month, year] = input.split(/[-/]/g);
  const paddedDay = day?.padStart(2, "0");
  const paddedMonth = month?.padStart(2, "0");
  let transformedDate: string;
  if (paddedMonth === undefined || paddedDay === undefined) {
    // transformedDate = `0000-00-00`;
    return "-1";
  } else {
    transformedDate = `${
      year.length === 2 ? "20" + year : year
    }-${paddedMonth}-${paddedDay}`;
  }
  return transformedDate;
}

export function stringHourToNumber(input: string | undefined): number {
  if (input === undefined || input === "0") {
    return 0;
  } else {
    const timeStringWithoutColons = input.replace(/:/g, "");
    return parseInt(timeStringWithoutColons, 10);
  }
}

export function dateToSimpleString(inputDate: Date): string {
  const year = inputDate.getFullYear().toString();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const hour = inputDate.getHours().toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  const seconds = inputDate.getSeconds().toString().padStart(2, "0");

  return `${year}${month}${day}_${hour}${minutes}${seconds}`;
}

export function dateToString(input: Date | string, separator?: string): string {
  const inputDate = new Date(input);
  const year = inputDate.getFullYear().toString();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");

  return separator
    ? `${day}${separator}${month}${separator}${year}`
    : `${day}/${month}/${year}`;
}

export function dateToStringG(input: Date | string, separator?: string): string {
  const inputDate = new Date(input);
  const year = inputDate.getFullYear().toString();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = (inputDate.getDate() + 1).toString().padStart(2, "0");

  return separator
    ? `${day}${separator}${month}${separator}${year}`
    : `${day}-${month}-${year}`;
}

export function dateToEnglishString(
  input: Date | string,
  separator?: string
): string {
  const inputDate = new Date(input);
  const year = inputDate.getFullYear().toString();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");

  return separator
    ? `${year}${separator}${month}${separator}${day}`
    : `${year}/${month}/${day}`;
}

export function getDateByDays(input: Date | string, days: number): Date {
  const newDate = new Date(input);
  let DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
  return new Date(newDate.getTime() + DAY_IN_MILLISECONDS * days);
}

export function getNextBusinessDay() {
  let currentDate = moment();
  if (currentDate.day() === 5) {
    currentDate.add(3, "days");
  } else if (currentDate.day() === 6) {
    currentDate.add(2, "days");
  } else {
    currentDate.add(1, "day");
  }
  return currentDate.format("YYYY-MM-DD");
}

export function fullDateToDate(input: string | undefined) {
  if (input) {
    const simpleDate = input.split("T");
    return stringDateFormatter(simpleDate[0]);
  }
  return undefined;
}

export function invertStringDate(
  input: string | undefined,
  separator?: string
) {
  if (input) {
    const dateSeparator = separator ?? "-";
    const arrayDate = input.split(dateSeparator);
    return `${arrayDate[2]}${dateSeparator}${arrayDate[1]}${dateSeparator}${arrayDate[0]}`;
  }
  return undefined;
}

export function compareDateString(input1: string, input2: string) {
  if (!input1) {
    return -1;
  }
  if (!input2) {
    return 1;
  }
  const date1 = new Date(`${input1} 00:00`);
  const date2 = new Date(`${input2} 00:00`);
  return date1.getTime() < date2.getTime() ? -1 : 1;
}

export function ddmmyyyyStringToDate(input: string){
  const [day, month, year] = input.split("-");
  return new Date(`${year}-${month}-${day}T00:00:00`)
}
