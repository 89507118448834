import {Menu, MenuEndpoint} from "../domain/Menu.model";

export const createAdapterToMenuEndpoint = (menu: Menu) => {
    const parent = menu.parentMenu ? typeof menu.parentMenu !== 'string' ? createAdapterToMenuEndpoint(menu.parentMenu) : menu.parentMenu : undefined;
    const formattedMenu: MenuEndpoint = {
        menuId: menu.menuId ?? undefined,
        name: menu.name,
        alias: menu.label,
        action: menu.action,
        icon: menu.icon,
        parentMenu: parent === "" ? undefined : parent,
        order: menu.order
    };
    return formattedMenu;
}

export const createAdapterToMenu = (menuEndpoint: MenuEndpoint) => {
    const formattedMenu: Menu = {
        menuId: menuEndpoint.menuId,
        name: menuEndpoint.name,
        icon: menuEndpoint.icon,
        action: menuEndpoint.action,
        label: menuEndpoint.alias,
        order: menuEndpoint.order,
        parentMenu: menuEndpoint.parentMenu ? typeof menuEndpoint.parentMenu !== 'string' ? createAdapterToMenu(menuEndpoint.parentMenu) : menuEndpoint.parentMenu : undefined
    };
    return formattedMenu;
}