import { useEffect, useState } from "react";
import { ColumnSelectable } from "./columnSelector.hook";

export function useColumnExchange(
  tableName: string,
  columns: string[],
  initialColumns: ColumnSelectable[]
) {
  const LOCAL_STORAGE_KEY = `${tableName}_tableColumnsOrder`;
  const storedColumnsOrder = localStorage.getItem(LOCAL_STORAGE_KEY);
  const parsedStorageColumnsOrder = storedColumnsOrder
    ? JSON.parse(storedColumnsOrder)
    : undefined;

  const checkInvalidColumns = (
    parsedStorageColumns: ColumnSelectable[] | undefined
  ) => {
    if (parsedStorageColumns) {
      return !parsedStorageColumns.some(
        (item2) => !initialColumns.some((item1) => item1.field === item2.field)
      );
    }
    return false;
  };

  const [columnsOrder, setColumnsOrder] = useState<string[]>(
    storedColumnsOrder && checkInvalidColumns(parsedStorageColumnsOrder)
      ? parsedStorageColumnsOrder
      : columns
  );

  const exchangeColumns = (a: string, b: string) => {
    const newOrder = [...columnsOrder];
    const indexA = newOrder.indexOf(a);
    const indexB = newOrder.indexOf(b);
    const tmp = newOrder[indexA];
    newOrder[indexA] = newOrder[indexB];
    newOrder[indexB] = tmp;
    setColumnsOrder(newOrder);
  };

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(columnsOrder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsOrder]);

  return {
    columnsOrder,
    exchangeColumns,
  };
}
