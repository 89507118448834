import styled from "styled-components";

export const StyledTableAddButtonName = styled.span`
  display: none;
  @media screen and (min-width: 600px) {
    display: inherit;
  }
`

export const StyledTableFreeActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  height: auto;
  width: auto;
`

export const StyledTableFreeAction = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  border: none;
  color: #00000099;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: #000000d6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: auto;
  }
`