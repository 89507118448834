import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToArea,
  createAdapterToAreaEndpoint,
  createAdapterToFieldAreaEndpoint,
} from "../adapters/area.adapter";
import { Area, AreaEndpoint, EndpointAreaFiltered } from "../domain/Area.model";
import { appConfig } from "../../../../config";
import { EndpointArea } from "../domain/Area.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_AREA_URL = "/usuarios/areas";
const BASE_AREA_MUTATION_URL = "/usuarios/area";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/usuarios/areas/export`;

export const areaApi = createApi({
  reducerPath: "areaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Areas", "Area"],
  endpoints: (builder) => ({
    getArea: builder.query<Area, number | undefined>({
      query: (id) => `${BASE_AREA_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointArea) => {
        return createAdapterToArea(response.Content);
      },
      providesTags: (_res, _err, id) => [{type: "Area", id: id?.toString()}],
    }),
    getAreas: builder.query<any, TableOptions<Area> | undefined>({
      query: (params: TableOptions<Area>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(f, createAdapterToFieldAreaEndpoint)
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldAreaEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_AREA_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["Areas"],
      transformResponse: (
        response: EndpointAreaFiltered,
        _meta,
        params: TableOptions<Area>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((area: AreaEndpoint) =>
          createAdapterToArea(area)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createArea: builder.mutation<any, Area>({
      query: (data) => ({
        url: `${BASE_AREA_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToAreaEndpoint(data),
        },
      }),
      invalidatesTags: ["Areas"],
    }),
    updateArea: builder.mutation<any, Area>({
      query: (data: Area) => ({
        url: `${BASE_AREA_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToAreaEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: Area) => ["Areas", {type: 'Area', id: data?.id?.toString()}],
    }),
    deleteArea: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_AREA_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Areas"],
    }),
  }),
});

export const {
  useGetAreaQuery,
  useGetAreasQuery,
  useCreateAreaMutation,
  useUpdateAreaMutation,
  useDeleteAreaMutation,
} = areaApi;
