import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../domain/Notification.model";
import { LOCALES } from "../../../../language/locales";
import messages from "../../../../language/messages";

const initialState: Notification = {
  show: false,
  severity: "info",
  message: "",
};

const isMissingTranslate = (message: string) => {
  return !Object.values(LOCALES).some((locale: string) =>
    Object.values(messages[locale]).includes(message)
  );
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setNotification: (_state, action: PayloadAction<Notification>) => {
      if (
        !action?.payload?.skipTranslation &&
        isMissingTranslate(action?.payload?.message)
      ) {
        return {
          ...action.payload,
          message: "Hubo un error, por favor contáctese con el soporte técnico",
        };
      }
      return action.payload;
    },
    cleanNotification: (_state) => {
      return {
        ...initialState,
        severity: _state.severity,
      };
    },
  },
});

export const { setNotification, cleanNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
