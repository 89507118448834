import { LOCALES } from "../locales";

const englishLocale = {
  [LOCALES.ENGLISH]: {
    hello: "Hi!",
    username: "Username",
    user: "User",
    password: "Password",
    "new password": "New Password",
    "sign in": "Sign in",
    login: "Login",
    "is required": "is required",
    "is invalid": "is not valid",
    exit: "Exit",
    dashboard: "Dashboard",
    "good morning" : "Good Morning",
    "this is what is happening with your business today" : 
      "This is what is happening with your business today",
    users: "Users",
    total: "Total",
    page: "Page",
    show: "Show",
    records: "Records",
    firstname: "Firstname",
    lastname: "Lastname",
    role: "Role",
    of: "of",
    filters: "Filters",
    contains: "contains",
    doesNotContain: "does not contain",
    startsWith: "starts with",
    endsWith: "ends with",
    equals: "equals",
    notEquals: "not equals",
    numberEquals: "equals",
    numberNotEquals: "not equals",
    greaterThan: "greater than",
    greaterThanOrEqual: "greater than or equal",
    lessThan: "less than",
    lessThanOrEqual: "less than or equal",
    after: "after",
    before: "before",
    between: "between",
    operator: "Operator",
    value: "Value",
    and: "and",
    home: "Home",
    "there was an error loading the menus":
      "There was an error loading the menus",
    settings: "Settings",
    "General settings" : "General settings",
    profile: "Profile",
    "public avatar": "Public Avatar",
    "change anything related with your profile picture":
      "Change anything related with your profile picture",
    "change general app settings" 
      : "Change general app settings",
    "upload avatar": "Upload avatar",
    "the maximum file size allowed is 200kb":
      "The maximum file size allowed is 200kb",
    "main settings": "Main Settings",
    "change anything related with your profile data":
      "Change anything related with your profile data",
    "enter your first name": "Enter your first name",
    "enter your last name": "Enter your last name",
    "enter your username, so people you know can recognize you":
      "Enter your username, so people you know can recognize you",
    "this email will be displayed on your public profile":
      "This email will be displayed on your public profile",
    update: "Update",
    theme: "Theme",
    language: "Language",
    timezone: "Time Zone",
    offset: "Configure time zone",
    "change timezone": "Change time zone",
    "change to your prefered language": "Change to your prefered language",
    "change the main theme": "Change the main theme",
    email: "e-Mail",
    "stock administration": "Stock administration",
    "products administration": "Products administration",
    "tags administration": "Tags administration",
    "trademark administration": "Trademark administration",
    "categories administration": "Categories administration",
    "edit stock": "Edit stock",
    "new stock": "New stock",
    "edit product": "Edit product",
    "new product": "New product",
    "edit tag": "Edit tag",
    "new tag": "New tag",
    "new trademark": "New trademark",
    "edit trademark": "Edit trademark",
    "new category": "New category",
    "edit category": "Edit category",
    "new currency": "New currency",
    "edit currency": "Edit currency",
    currencyName: "Currency",
    currencyCode: "Code",
    "currencies administration": "Currencies Administration",
    edit: "Edit",
    delete: "Delete",
    Editar: "Edit",
    Eliminar: "Delete",
    search: "Search",
    add: "Add",
    cancel: "Cancel",
    confirm: "Confirm",
    "confirm delete?": "Do you want to delete?",
    beneficiaryName: "Beneficiary",
    "new customer": "New customer",
    "edit customer": "Edit customer",
    customerName: "Customer",
    "customers administration": "Customers administration",
    cuit: "NIF/CUIT",
    "new country": "New country",
    "edit country": "Edit country",
    countryName: "Country",
    "countries administration": "Countries administration",
    "new supplier": "New supplier",
    "edit supplier": "Edit supplier",
    supplierName: "Related supplier",
    "suppliers administration": "Suppliers administration",
    "new accountType": "New bank account type",
    "edit accountType": "Edit bank account type",
    accountTypeName: "Bank account type name",
    "AccountTypes administration": "Bank Account Type Administration",
    "new bankTransactionType": "New bank transaction types",
    "edit bankTransactionType": "Edit bank transaction types",
    bankTransactionTypeName: "Bank Transaction Types",
    bankTransactionTypeNameShort: "Bank Trans.",
    "BankTransactionType administration":
      "Bank transaction types administration",
    "new state": "New state",
    "edit state": "Edit state",
    stateName: "Transaction State",
    "text only": "Text only",
    "states administration": "States administration",
    "new contact": "New contact",
    "edit contact": "Edit contact",
    "contacts administration": "Contacts administration",
    active: "Active",
    "new investment type": "New investment type",
    "edit investment type": "Edit investment type",
    investmentTypeName: "Investment type",
    "investment types administration": "Investment types administration",
    "new area": "New Area",
    "edit area": "Edit Area",
    areaName: "Area",
    "areas administration": "Areas administration",
    "new bankTransaction": "New bank transaction",
    "edit bankTransaction": "Edit bank transaction",
    bankTransactionName: "Bank transaction",
    "bankTransactions administration": "Bank transactions administration",
    short: "Short name",
    code: "Code",
    "new payment method": "New payment method",
    "edit payment method": "Edit payment method",
    paymentMethodName: "Payment method",
    paymentMethodCode: "Code",
    "payment methods administration": "Payment methods Administration",
    "new investment movement type": "New investment movement type",
    "edit investment movement type": "Edit investment movement type",
    investmentMovementTypeName: "Investment movement type",
    "investment movements types administration":
      "Investment movements types administration",
    none: "Select",
    noneAccount: "Select Account",
    "new bank": "New bank",
    "edit bank": "Edit bank",
    bankName: "Bank name",
    "banks administration": "Banks administration",
    BCRA: "Bank code",
    countryId: "Country",
    "new concept": "New concept",
    "edit concept": "Edit concept",
    conceptName: "Concept",
    "concepts administration": "Concepts administration",
    conceptTypeId: "Concept type",
    order: "Order",
    "new exchangeRate": "New exchange rate",
    "edit exchangeRate": "Edit exchange rate",
    date: "Date",
    maxDate: "Date of deadline",
    "exchangeRates administration": "Exchange rates administration",
    amount: "Amount",
    parentAccount: "Parent account",
    "new investment portfolio": "New investment portfolio",
    "edit investment portfolio": "Edit investment portfolio",
    investmentPortfolioName: "Investment portfolio",
    ric: "RIC",
    dueDate: "Due Date",
    issueDate: "Issue Date",
    depositDate: "Deposit Date",
    rejectionReason: "Rejection/voided Reason",
    ChequeTypeName: "Cheque Type",
    ownChequeName: "Own Cheque",
    ThirdPartyChequeName: "Third Party Cheque",
    ChequeNumber: "Cheque Number",
    number: "number",
    "investment portfolios administration":
      "Investment portfolios administration",
    father: "Father",
    label: "Label",
    name: "Name",
    icon: "Icon",
    "menus administration": "Menus administration",
    action: "Action",
    "file not selected": "File not selected",
    "choose file": "Choose file",
    "add menu": "Add menu",
    parentMenu: "Parent menu",
    alias: "Alias",
    "new investmentInstrument": "New investment instrument",
    "edit investmentInstrument": "Edit investment instrument",
    investmentInstrumentName: "Investment Instrument",
    "investmentInstruments administration":
      "Investment instruments administration",
    ISIN: "ISIN",
    "new bankAccount": "New bank account",
    "edit bankAccount": "Edit bank account",
    "bankAccounts administration": "Bank accounts administration",
    bankAccountName: "Bank Account",
    accountNumber: "Account number",
    CBU: "CBU",
    SWIFT: "SWIFT",
    CUIT: "NIF/CUIT",
    bankId: "Bank",
    currencyId: "Currency",
    accountTypeId: "Account type",
    "new bank transaction classification":
      "New bank transaction classification",
    "edit bank transaction classification":
      "Edit bank transaction classification",
    "bank transactions classification administration":
      "Bank transactions classification administration",
    bankTransactionClassificationName: "Classification",
    bankTransactionTypeId: "Transaction type",
    transactionCode: "Transaction code",
    p1Code: "ERP Code",
    bankTransactionClassificationDetailed:
      "Bank transaction classification detailed",
    bankTransactionClassificationDetailedShort:
      "Bank trans. Class. detailed",
    "new statementBalance": "New statement balance",
    "edit statementBalance": "Edit statement balance",
    "statementBalances administration": "Statement balance administration",
    balance: "Balance",
    hour: "Hour",
    "new monthClosure": "New month closure",
    "monthClosures administration": "Month closures administration",
    month: "Month",
    year: "Year",
    period: "Period",
    firstEstimatePercentage: "First estimate percentage",
    suggestedPercentage: "Suggested percentage",
    concept: "Concept",
    lastEstimatePercentage: "Last estimate percentage",
    "locations administration": "Locations administration",
    initialize: "Initialize",
    "must be greater than or equal to": "must be greater than or equal to",
    "must be less than or equal to": "must be less than or equal to",
    "must be greater than": "must be greater than",
    "An error occurred. Contact the administrator":
      "An error occurred. Contact the administrator",
    "enter a month in MM format": "Enter a month in MM format",
    "enter a year in YYYY format": "Enter a year in YYYY format",
    "enter a valid percentage": "Enter a valid percentage",
    "edit location": "edit location",
    "new statementMovement": "New statement movement",
    "edit statementMovement": "Edit statement movement",
    "statementMovements administration": "statement movements administration",
    valueDate: "Value date",
    processDate: "Process date",
    bankCode: "Bank code",
    shortAccountNumber: "Short account number",
    movementDate: "Movement date",
    movementType: "Movement type",
    voucherNumber: "Voucher number",
    opCode: "Operation code",
    freeInfo: "Free info",
    branch: "Origin branch",
    depositorCode: " Depositor code",
    longAccountNumber: "Long account number",
    bankOpCode: "Bank operation code",
    currencyType: "Currency type",
    loadFile: "Load file",
    daily: "Daily",
    foreign: "Foreign",
    file: "File",
    "There is no data to import in the table":
      "There is no data to import in the table",
    "Load File": "Load File",
    saveData: "Save Data",
    "error loading data": "Error loading data",
    column: "Column",
    "add value": "Add value",
    "column is required": "Column is required",
    "operator is required": "Operator is required",
    "complete this field": "Complete this field",
    typeId: "TypeId",
    only: "only",
    some: "some",
    "cash flow administration": "Cash Flow administration",
    bank: "Bank",
    initialBalance: "Initial Balance",
    totalRevenues: "Total Revenues",
    totalRevenuesShort: "T. Revenues",
    totalExpenses: "Total Expenses",
    totalExpensesShort: "T. Expenses",
    dailyTransactions: "Daily Transactions",
    dailyTransactionsShort: "Daily Trans.",
    accountsTransactions: "Accounts Transactions",
    accountsTransactionsShort: "Accounts Trans.",
    projectedBalance: "Projected Balance",
    details: "Details",
    state: "State",
    minimumBalance: "Minimum Balance",
    taxDebitsCredits: "TX (D/C) 0,60 %l",
    transferredExpenses: "Transferred Expenses",
    transferredRevenues: "Transferred Revenues",
    expenses: "Expenses",
    revenues: "Revenues",
    preview: "Preview",
    send: "Send",
    accept: "Accept",
    "sending emails": "Sending emails",
    sendEmail: "Send Email",
    sendingMail: "Sending email from",
    template: "Template",
    cashflowInitialization: "Cashflow Initialization",
    "sure to initialize?":
      "Are you sure to proceed with cash flow initialization? This action is irreversible",
    cashflowUpdate: "Cashflow Update",
    "sure to update?":
      "Are you sure to proceed with cash flow update? This action is irreversible",
    firstEstDate: "First est. date",
    lastEstDate: "Last est. date",
    currencyLocationId: "Currency location",
    currencyOpId: "Currency operation",
    exchangeRateOp: "Exchange rate operation",
    comment: "Comment",
    creditNote: "Nota de crédito",
    firstEstAmount: "First est. amount",
    lastEstAmount: "Last est. amount",
    "incomes administration": "Incomes administration",
    "new income": "New income",
    "edit income": "Edit income",
    import: "Import",
    rescueFCI: " Rescue FCI",
    subscribeFCI: "Subscription FCI",
    expense: "Expense",
    revenue: "Revenue",
    "Daily Movement": "Daily Movement",
    Transfer: "Transfer",
    fromBankAccountId: "From bank account",
    toBankAccountId: "To bank account",
    id: "ID",
    dateEquals: "equals",
    dateNotEquals: "not equals",
    dateBetween: "between",
    "selected columns": "Selected columns",
    "searcheable columns": "Searcheable columns:",
    Import: "Import File",
    Export: "Export",
    "new financialInvestment": "New financial investment",
    "edit financialInvestment": "Edit financial investment",
    financialInvestmentName: "Financial investmentName",
    "financialInvestments administration":
      "Financial investments administration",
    "users administration": "Users administration",
    fees: "Fees",
    taxes: "Taxes",
    payOrder: "Pay order",
    Progrm: "Program",
    "edit expense": "Editar egreso",
    "new expense": "Nuevo egreso",
    "expenses administration": "Administración de egresos",
    "select the file to import": "Select the file to import",
    "Table Transformations": "Table Transformations",
    HWBankDolarBillete: "Bank Dollar Note",
    HWBankDolarDivisa: "Bank Dollar Currency",
    HWmmPesos: "MM Pesos",
    HWBankPesos: "Bank Pesos",
    "new chequeState": "New cheque state",
    "edit chequeState": "Edit cheque state",
    chequeStateName: "Cheque state",
    "new chequeType": "New cheque type",
    "edit chequeType": "Edit cheque type",
    chequeTypeName: "Cheque type",
    PermissionName: "Permission",
    permissions: "Permissions",
    roleName: "Role",
    assigned: "Assigned",
    available: "Available",
    selected: "selected",
    "all items selected": "All items selected",
    "move selected left": "move selected left",
    "move selected right": "move selected right",
    "invalid email": "Invalid email",
    "invalid password": "Invalid password",
    "invalid username": "Invalid username",
    "you do not have permissions to enter this menu":
      "You do not have permissions to enter this menu",
    "in row": "in row",
    importSuccessful: "Import Successful",
    "change anything related with your password":
      "Change anything related with your password",
    "manage password": "Manage Password",
    "enter your new password": "Enter your new password",
    "enter your old password": "Enter your old password",
    "re-enter your new password": "Re-enter your new password",
    "old password": "Old Password",
    "inconsistent passwords": "You entered two diferent passwords",
    "Passwords do not match": "Your old password do not match",
    "successful password update": "Successful password update",
    "input date older than last month closure date":
      "The input date is older than the last month closure date",
    "dates are wrong": "dates are wrong",
    "you need to correct this before continuing":
      "You need to correct this before continuing",
    "successful import, data was sent": "Successful import, data was sent",
    "import failed": "Import failed",
    noRole: "Without role",
    "initial projected balance": "today projected balance",
    "initial projected": "Initial projected",
    "initial projected at": "Initial projected at",
    "daily transactions": "Daily Transactions",
    "daily treasury": "Daily Treasury",
    "options predefined": "Options predefined",
    today: "Today",
    tomorroy: "Tomorroy",
    "this week": "This week",
    "next week": "Next week",
    "end of the month": "End of the month",
    to: "To",
    "period projection": "Period projection",
    "initial balance": "Initial balance",
    "income collections": "Income collections",
    "expenses paid": "Expenses paid",
    "projected balance": "Projected balance",
    "select bank": "Select bank",
    "date to": "Date to",
    createExpense: "Add Expense",
    updateExpense: "Edit Expense",
    createIncome: "Create Income",
    updateIncome: "Edit Income",
    paymentTypeName: "Payment Type",
    Collection: "Collections",
    failedOperation: "Operation could not be done",
    Payment: "Payments",
    createMovement: "Add Movement",
    updateMovement: "Edit Movement",
    debit: "Debit",
    credit: "Credit",
    hasTo: "Debit",
    toHave: "Credit",
    reconcile: "Reconcile",
    "reconciliation detail": "Reconciliation detail",
    "bank extract": "Bank extract",
    "diary book": "Diary book",
    "You have selected a statement that has already been reconciled":"You have selected a statement that has already been reconciled",
    "You have selected a journal that has already been reconciled":"You have selected a journal that has already been reconciled",
    accountBank: "Account bank",
    extracts: "Extracts",
    diary: "Diary",
    "error when reconciling": "Error when reconciling",
    reconcileSuccessful: "Successful conciliation",
    reconcileDeleted: "Conciliation deleted",
    "new reconciliation": "New reconciliation",
    "The amounts are different": "The amounts are different",
    export: "Export",
    CountryName: "Country Name",
    ProgramWarning:
      "All pending values until the selected date will be rescheduled",
    MonthEndClose: "Month-end Close",
    MonthEndCloseWarning:
      "All pending values of the selected time period will be discarded by the month-end close",
    MonthClosureWarning:
      "You won't be able to modify records with a date before the deadline",
    "input has to be positive": "Input has to be greater than 0",
    addFilter: "Add Filter",
    "select account": "Select account",
    conceptId: "Concept",
    stateId: "State",
    investmentPortfolioId: "Investment portfolio",
    investmentMovementTypeId: "Investment movement type",
    investmentInstrumentId: "Investment instrument",
    "Locked by Date": "Locked by closure date",
    unsavedChanges: "There are unsaved changes!",
    "lastEstDate must be higher than firstEstDate":
      "Last est. date have to be higher than First est. date",
    "successful create, data was sent": "Successful create, data was sent",
    "successful update, data was sent": "Successful update, data was sent",
    "success delete": "Success delete",
    bankAccountId: "Account number",
    paymentMethodId: "Payment method",
    bankTransactionClassificationId: "Bank transaction classification",
    hourId: "Hour",
    beneficiaryId: "Beneficiary",
    chequeStateId: "Cheque state",
    chequeTypeId: "Chequet type",
    customerId: "Customer",
    bankTransactionId: "Bank transaction",
    areaId: "Area",
    investmentTypeId: "Investment type",
    createChequeSuccessful: "Create cheque successful",
    updateChequeSuccessful: "Update cheque successful",
    deleteChequeSuccessful: "Cheque deleted successful",
    createSuccessful: "Created successful",
    updateSuccessful: "Updated  successful",
    deleteSuccessful: "Deleted successful",
    "12characters": "must be 12 alphanumeric characters",
    reconciliationDashboard: "Dashboard",
    lastReconciliations: "Last reconciliations",
    notSelectedFile: "A file must be entered",
    "enter dates": "Enter dates",
    "last 30 days": "Last 30 days",
    "error when delete reconciling": "Error when delete reconciling",
    "error getting reconciliations": "Error getting reconciliations",
    resumeLastReconciliation: "Resume last reconciliation",
    topTenNoReconciliations: "Top 10 no reconciliations",
    "processed movements": "Processed movements",
    "automatic reconciliations": "Automatic reconciliations",
    "partial reconciliations": "Partial reconciliations",
    "no conciliations": "No conciliations",
    "automatic percentage": "Automatic percentage",
    "unreconciled amount": "Unreconciled amount",
    viewDetails: "View details",
    importReconciliationError: "Import reconciliation error",
    lastreconciliations: "Last reconciliations",
    remove: "Remove",
    reconciliations: "Reconciliations",
    bankReconciliations: "Bank reconciliations",
    banks: "Banks",
    lastConciliationDate: "Last Conciliation Date",
    automaticConciliationsPercentage: "% Automatic Conciliations",
    noConciliationsPercentage: "% No Conciliations",
    noConciliationsQuantity: "No Conciliations Quantity",
    noConciliationsAmount: "No Conciliations Amount",
    accountsbanks: "Accounts Banks",
    "error when obtaining the latest reconciliations":
      "Error when obtaining the latest reconciliations",
    "go to last reconciliation": "Go to last reconciliation",
    requiredByLastEstDate: "Required by Last Estimate Date",
    requiredByLastEstAmount: "Required by Last Estimate Amount",
    paymentSavedSuccessful: "Payment Saved Successfuly",
    collectionSavedSuccessful: "Collection Saved Successfuly",
    "Concepto already exists": "Concept already exists",
    "issueDate required": "Issue date required",
    "depositDate must be greater than issueDate":
      "Deposit date must be greater than issueDate",
    "dueDate required": "Due date required",
    "dueDate must be greater than depositDate":
      "Due date must be greater than depositDate",
    "dueDate must be greater than issueDate":
      "Due date must be greater than issueDate",
    "CierreMes already exists": "Blocking date already exists",
    "El Cheques ya existe": "Cheque already exists",
    "amount must be greater than 0": "Amount must be greater than 0",
    "Error tipo cheque can not be duplicated":
      "Cheque type can not be duplicated",
    "Error tipo de cheque name already exist":
      "Cheque type's name already exist",
    "Error bank name already exist": "Bank's name already exist",
    "Error bank short name already exist": "Bank's short name already exist",
    "Error bank BCRA code already exist": "Bank's code already exist",
    "Error swift account already exist": "Account's SWIFT code already exist",
    "Error CBU already exist": "CBU already exist",
    "Error account number exist": "Account number already exist",
    "Error duplicated estimacion estado name": "Duplicated state name",
    "Error estimacion estado name already exist": "State name already exist",
    "Error duplicated formatos pagos name": "Duplicated payment method's name",
    "Error duplicated formatos pagos short name":
      "Duplicated payment method's short name",
    "Error formatos pagos name already exist":
      "Payment method's name already exist",
    "Error formatos pagos short name already exist":
      "Payment method's short name already exist",
    "Error email can not be duplicated": "Email can not be duplicated",
    "Error, email to update already exists": "Email to update already exists",
    "El NIF/CUIT ya existe": "NIF/CUIT already exist",
    "Error name already exist": "Name already exist",
    "Tipo de cambio exist on date": "Exchange rate exist on date",
    "Error can not be add duplicated area": "Area to add already exist",
    "Error area to update already exist": "Area to update already exist",
    "must be between": "Must be between",
    "must be higher than": "must be higher than",
    "This filter is invalid": "This filter is invalid",
    "This condition is invalid": "This condition is invalid",
    "This name is invalid": "filter's attribute is invalid",
    "Tabla can not be empty": "Table can not be empty",
    "Filter can not be empty": "Filter can not be empty",
    "RootDir is required": "Root directory is required",
    "RootDir can not be blank": "Root directory can not be blank",
    "The selected bank account does not match with data in the file":
      "bank account does not match with data in the row",
    "Error no coincide el importe del asiento y del extracto":
      "bank transaction and bank statement do not match",
    "Error asineto a conciliar incorrecto":
      " Incorrect transaction to reconcile",
    "Error asiento a conciliar incorrecto":
      "Incorrect transaction to reconcile",
    "Error cuentas bancarias no coinciden": "Bank accounts do not match",
    "Error en la conciliacion no hay asientos":
      "Reconciliation does not have transactions",
    "Error en la conciliacion no hay extractos movimientos":
      "Reconciliation does not have statements",
    "Error el asiento ya tiene una conciliacion":
      "The transaction already have a reconciliation",
    "Error el extracto bancario ya tiene una conciliacion":
      "The statement already have a reconciliation",
    "Error bank account not exist": "Bank account does not exist",
    "must not be empty": "The field must not be empty",
    "Extracto Movimiento Bancario already exists":
      "Bank transaction already exists",
    "Extracto Movimiento Bancario not exists":
      "Bank transaction does not exist",
    "Extracto Movimiento Bancario not found": "Bank transaction not found",
    "Fecha not found": "Date not found",
    "Cuenta Bancaria not found": "Bank account not found",
    "Clasificación Movimiento Bancario not found":
      "bank transaction classification not found",
    "Concepto not found": "Concept Not found",
    "Tipo Movimiento Bancario not found": "Bank transaction type not found",
    "Error empty code": "Empty code",
    "Extracto Saldo Bancario already exists": "Balance already exists",
    "Extracto Saldo Bancario not exists": "Balance does not exist",
    "Extracto Saldo Bancario not found": "Balance not found",
    "Ingreso already exists": "Income already exists ",
    "Ingreso not exists": "Income does not exist",
    "Ingreso not found": "Income not found",
    "Movimiento not found": "Movement not found",
    "Fecha Primera Estimación not found": "First est. date not found",
    "Fecha Última Estimación not found": "Last est. date not found",
    "Área not found": "Area not found",
    "Moneda Locación not found": "Currency location not found",
    "Moneda Operación not found": "Currency operation not found",
    "Estado not found": "State not found",
    "Cliente not found": "Customer not found",
    "Incorrect Period": "Incorrect Period",
    "Cashflow already exists": "Cashflow already exists",
    "Cashflow not exists": "Cashflow does not exist",
    "Cashflow not found": "Cashflow not found",
    "Egreso already exists": "Expense already exists",
    "Egreso not exists": "Expense does not exist",
    "Egreso not found": "Expense not found",
    "Formato Pago not found": "Payment method not found",
    "Proveedor not found": "Supplier not found",
    "Movimiento already exists": "Movement already exists",
    "Movimiento not exists": "Movement does not exist",
    "ChequePropio o ChequeTercero o CuentaBancaria es requerido":
      "Own cheque, third party cheque or account number are required",
    "Cobro already exists": "Collection already exists",
    "Cobro not exists": "Collection does not exist",
    "Cobro not found": "Collection not found",
    "Inversión Financiera already exists":
      "Financial investment already exists",
    "Inversión Financiera not exists": "Financial investment does not exist",
    "Inversión Financiera not found": "Financial investment not found",
    "Cartera Inversión not found": "Investment portfolio not found",
    "Instrumento Inversión not found": "Investment intstrument not found",
    "Tipo Movimiento Inversión not found": "Investment movement type not found",
    "Pago already exists": "Payment already exists",
    "Pago not exists": "Payment does not exist",
    "Pago not found": "Payment not found",
    "Locación already exists": "Location already exists",
    "Locación not exists": "Location does not exist",
    "Locación not found": "Location not found",
    "Operación de Banco already exists": "Bank transaction already exists",
    "Operación de Banco not exists": "Bank transaction does not exist",
    "Operación de Banco not found": "Bank transaction not found",
    "Cartera de Inversión already exists":
      "Investment portfolio already exists",
    "Cartera de Inversión not exists": "Investment portfolio does not exist",
    "Cartera de Inversión not found": "Investment portfolio not found",
    "Moneda not found": "Currency not found",
    "Tipo de Inversión not found": "Investment type not found",
    "Instrumento de Inversión already exists":
      "Investment intstrument already exists",
    "Instrumento de Inversión not exists":
      "Investment intstrument does not exist",
    "Instrumento de Inversión not found": "Investment intstrument not found",
    "El ISIN ingresado ya existe": "ISIN already exist",
    "Fecha Vencimiento not found": "Due date not found",
    "Clase de Inversión not found": "Investment class not found",
    "Tipo de Movimiento Bancario already exists":
      "Bank transaction type already exists",
    "Tipo de Movimiento Bancario not exists":
      "Bank transaction type does not exist",
    "Tipo de Movimiento Bancario not found": "Bank transaction type not found",
    "Tipo de Concepto already exists": "Concept type already exists",
    "Tipo de Concepto not exists": "Concept type does not exist",
    "Tipo de Concepto not found": "Concept type not found",
    "Type of Movement Investment already exists":
      "Movement investment type already exist",
    "Type of Movement Investment not exists":
      "Movement investment type does not exist",
    "Type of Movement Investment not found":
      "Movement investment type not found",
    "Cuenta Bancaria already exists": "Bank account already exists",
    "Cuenta Bancaria not exists": "Bank account does not exist",
    "Banco not found": "Bank not found",
    "Tipo de Cuenta Bancaria not found": "Bank account type not found",
    "Clasificación Movimiento Bancario already exists":
      "bank transaction classification",
    "Clasificación Movimiento Bancario not exists":
      "bank transaction classification does not exist",
    "Operacion Banco not found": "Bank movement not found",
    "Codigo de Operacion Duplicado": "duplicate transaction code",
    "Codigo P1 Duplicado": "Duplicate ERP code",
    "Banco already exists": "Bank already exists",
    "Banco not exists": "Bank does not exist",
    "PaisID not found": "country not found",
    "Codigo Banco already exist for this country":
      "Bank code already exists for this country",
    "Error bank BCRA code already exist ": "Bank code already exists",
    "must be a string of at most 5 characters":
      "String must be less than 5 characters",
    "Clase de Inversión already exists": "Inversion class already exists",
    "Clase de Inversión not exists": "Inversion class does not exist",
    "Tipo de Cuenta Bancaria already exists":
      "Bank account type already exists",
    "Tipo de Cuenta Bancaria not exists": "Bank account type does not exist",
    "Tipo de Inversión already exists": "Investment type already exists",
    "Tipo de Inversión not exists": "Investment type does not exist",
    "CierreMes not exists": "Blocking date does not exist",
    "CierreMes not found": "Blocking date not found",
    "CierreMes Table empty": "Blocking date table is empty",
    "Order already exists": "Order already exists",
    "Concepto not exists": "Concept does not exist",
    "TipoConceptoID not found": "Concept not found",
    "Tipo de Cambio already exists": "Exchange rate already exists",
    "Tipo de Cambio not exists": "Exchange rate does not exist",
    "Tipo de Cambio not found": "Exchange rate not found",
    "Contacto already exists": "Contact already exists",
    "Contacto not exists": "Contact does not exist",
    "Contacto not found": "Contact not found",
    "must be a string of at most 3 characters":
      "String must be less than 3 characters",
    "El País ya Existe": "Country already exists",
    "País not exists": "Country does not exist",
    "País not found": "Country not found",
    "Persona already exists": "Person already exists",
    "Persona not exists": "Person does not exist",
    "Persona not found": "Person not found",
    "Tiempo already exists": "Time already exists",
    "Tiempo not exists": "Time does not exist",
    "Tiempo not found": "Time not found",
    "FechaID cannot be null": "Date must not be empty",
    "La Moneda existe": "Currency already exists",
    "Monedas not exists": "Currency does not exist",
    "Monedas not found": "Currency not found",
    "Codigo de Moneda existe": "Currency code already exists",
    "Estado already exists": "State already exists",
    "Estado not exists": "State does not exist",
    "Formato de Pago already exists": "Payment method already exist",
    "Formato de Pago not exists": "Payment method does not exist",
    "Formato de Pago not found": "Payment method not found",
    "FactHamblinWatsa already exists": "Headquarters already exists",
    "FactHamblinWatsa not exists": "Headquarters does not exist",
    "FactHamblinWatsa not found": "headquarters not found",
    "Area already exists": "Area already exists",
    "Area not exists": "Area does not exist",
    "Area not found": "Area not found",
    "TipoCheque already exists": "Cheque type already exists",
    "TipoCheque not exists": "Cheque type does not exist",
    "TipoCheque not found": "Cheque type not found",
    "Estados already exists": "State already exists",
    "Estados not exists": "State does not exist",
    "Estados not found": "State not found",
    "El Cheque ya existe": "Cheque already exists",
    "ChequesPropios not exists": "Own cheque does not exist",
    "ChequesPropios not found": "Own cheque does not found",
    "ChequesTerceros not exists": "Third party cheque does not exist",
    "ChequesTerceros not found": "Third party cheque not found",
    "Name must not be empty": "Name must not be empty",
    "Alias must not be empty": "Alias must not be empty",
    "Action must not be empty": "Action must not be empty",
    "Menu name already exists": "Menu already exists",
    "Menu name not exists": "Menu does not exist",
    "Menu not found": "Menu not found",
    "Invalid parent menu": "Invalid parent menu",
    initializeCashFlowSuccessful: "Cashflow initialization successful",
    updateCashFlowSuccessful: "Cashflow update successful",
    emailCashFlowSuccessful: "e-mail was sent successfully",
    cashFlowTransferSuccessful: "Transfer successful",
    cashFlowMovDailySuccessful: "Daily transaction successful",
    "Movimiento realizado, saldo de cuenta en valor negativo":
      "Transaction completed, account balance in negative value",
    "Cuenta origen no encontrada": "Source account not found",
    "Debe seleccionar una cuenta valida": "You must select a valid account",
    "El monto debe ser superior a 0": "The amount must be greater than 0",
    "Transferencia realizada, saldo de cuenta origen en valor negativo":
      "Transfer completed, balance of source account in negative value",
    "No se ha realizado la transferencia":
      "The transfer has not been completed",
    cashflow: "Cashflow",
    at: "at",
    yes: "Yes",
    no: "No",
    "Invalid credentials":
      "The username and/or password entered are not correct",
    "Se debe seleccionar un tipo de movimiento":
      "A type of movement must be selected",
    thirdPartyCheques: "Thirdparty Cheques",
    noChartData: "There is no data",
    incomes: "Incomes",
    "Error mother account already exist": "Mother account already exist",
    process: "Process",
    monthClosures: "Month closures",
    "error getting last reconciliation close":
      "Error getting last reconciliation close",
    "Codigo must not be empty": "Code must not be empty",
    "Proceso must not be empty": "Process must not be empty",
    "Procesos already exists": "Process already exists",
    "Procesos not exists": "Process not exists",
    "Procesos not found": "Process not found",
    "Procesos Table empty": "There is no Process. Add one to validate",
    "Invalid processing date": "Cannot be processed. Locked by closure",
    monthLock: "Month lock",
    "amount extracts": "Amount extracts",
    "amount diaries": "Amount diaries",
    accountingAccount: "Accounting Account",
    "Error accounting account already exist": "Accounting account already exists",

    "Estimaciones" : "Estimates",
    "Financieras" : "Financial",
    "Parametria" : "Parametry",
    "Transacciones" : "Transactions",
    "Extractos Bancarios" : "Bank statements",
    "statementMovements" : "Bank statements",
    "Cheques" : "Checks",
    "Program":"Program",
    "Edit" : "Edit",
    "Delete" : "Delete",
    "Ingresos":"Incomes",
    "Egresos" : "Expenses",
    "Estimaciones Ingresos" : "Income estimates",
    "Estimaciones Egresos" : "Expense estimates",
    "Estimaciones Inversiones Financieras" : "Financial investment estimates",
    "financialInvestments" : "Financial investments",
    "Inversiones Financieras" : "Financial investments",
    "Flujo de Caja" : "Cashflow",
    "Bloqueo de Mes" : "Month closures",
    "Cheques Propios" : "Own checks",
    "ownCheques" : "Own checks",
    "Propios" : "Own",
    "Cheques Terceros" : "Third party checks",
    "Administración de Bancos" : "Banks administration",
    "Cartera Inversiones" : "Investment portfolio",
    "investmentPortfolios" : "Investment portfolios",
    "Tipo Cuentas Bancarias" : "Bank account type", 
    "accountTypes" : "Account types",
    "Cuentas Bancarias" : "Bank accounts",
    "bankAccounts" : "Bank accounts",
    "Bancos" : "Banks",
    "Tipo Inversiones" : "Investment types",
    "investmentTypes" : "Investment types",
    "Clasificación Movimientos Extractos" : "Bank transaction classification",
    "bankTransactionClassification" : "Bank transaction classification",
    "Tipo Movimientos Inversiones" : "Investment movements types",
    "investmentmovementstypes" : "Investment movements types",
    "Conceptos Estimaciones" : "Concepts estimates",
    "concepts" : "Concepts estimates",
    "Instrumentos Inversiones" : "Investement instruments",
    "investmentInstruments" : "Investement instruments",
    "Estados Cheques" : "Check states",
    "chequeStates" : "Check states",
    "Estados" : "States",
    "states" : "States",
    "Formato Pagos" : "Payment methods",
    "paymentMethods" : "Payment methods",
    "Países" : "Countries",
    "countries" : "Countries",
    "Monedas" : "Currencies",
    "currencies" : "Currencies",
    "Proveedores" : "Suppliers",
    "suppliers" : "Suppliers",
    "Clientes" : "Customers",
    "customers" : "Customers",
    "Contactos" : "Contacts",
    "contacts" : "Contacts",
    "Tipo Cambio" : "Exchange rates",
    "exchangeRates" : "Exchange rates",
    "Tipos Cheques" : "Types of checks",
    "chequeTypes" : "Types of checks",
    "Áreas" : "Areas",
    "areas" : "Areas",
    "Gestor" : "Manager",
    "change" : "Change",
    "edit menu" : "Edit menu",
    "menus" : "Menus",
    "funds" : "Funds",
    "Fondos" : "Funds",
    "General" : "General",
    "Casa Matriz" : "Headquarters",
    "headquarters" : "Headquarters",
    "Administración" : "Administration",
    "Administración de Menús" : "Menu Management",
    "Permisos" : "Permissions",
    "Usuarios" : "Users",
    "Roles" : "Roles",
    "roles" : "Roles",
    "Administración de Usuarios" : "Users Management",
    "Administración de Roles" : "Rol Management",
    "Conciliación Bancaria" : "Bank Reconciliation",
    "Administrador" : "Administrator",
    "Finanzas" : "Finance",
    "fundsprices" : "Funds Prices",
    "Precios Fondos" : "Funds Prices",
    "Precio Fondo exist on date" : "A price record already exists for the selected date, investment instrument and currency.",
    "FechaID must not be empty" : "A date must be indicated.",
    "Are you sure you want to delete this record?" : "Are you sure you want to delete this record?",
    "Revalue" : "Revalue",
    "Revaluation done correctly" : "Revaluation done correctly",
    "No records found to reevaluate" : "No records found to reevaluate",
    'NOT FOUND "Schroder Genérico" IN INVESTMENT INSTRUMENT':'Not found "Schroder Genérico" in Investment Instrument. This record must exist',
    'NOT FOUND "SUSCRIPCIÓN" IN CLASSIFICATION EXTRACTS MOVEMENTS':'Not found "SUBSCRIPTION" in extract movement classification. This record must exist',
    'NOT FOUND "CREDITO POR RESCATE FCI" IN CLASSIFICATION EXTRACTS MOVEMENTS':'Not found "CREDITO POR RESCATE FCI" in extract movement classification. This record must exist',
    'NOT FOUND "Stock y Movimientos FCI - S" IN CONCEPTS ESTIMATES':'Not found "Stock y Movimientos FCI - S" in Concepts Estimates. This record must exist',
    'NOT FOUND "Stock y Movimientos FCI - R" IN CONCEPTS ESTIMATES':'Not found "Stock y Movimientos FCI - R" in Concepts Estimates. This record must exist',

    "Inversiones LM" : "Investments LM",
    "SALDO INICIAL" : "INITIAL BALANCE",
    "Funding Income" : "Funding Income",
    "Cash Pooling Out" : "Cash Pooling Out",
    "Funding accounts" : "Funding accounts",
    "Commissions" : "Commissions",
    "Cash Pooling In" : "Cash Pooling In",
    "Claims" : "Claims",
    "Tax/Bank Fees" : "Tax/Bank Fees",
    "N/A" : "N/A",
    "Embargos Egresos" : "Embargoments Expenditures",
  },
};

export default englishLocale;
