import { numericalDateToString, stringToNumericalDate } from "../../../../utilities/dateUtils";
import { IncomeCollection, IncomeCollectionEndpoint, PaymentType } from "../domain/IncomeCollection.model";

type Dictionary = {
    [key: string]: string;
};

const incomeCollectionAttributesDiccionary: Dictionary = {
    id:'PagosID',
	dateId:'FechaID',
	expenseId:'EgresoID',
	thirdPartyChequeId:'ChequeTerceroID',
	bankAccountId:'CuentaID',
	amount:'Importe'
}

export const createAdapterToFieldIncomeCollectionEndpoint = (key: string): string => {
    return incomeCollectionAttributesDiccionary[key];
}

export const createAdapterToIncomeCollectionEndpoint = (incomeCollection: IncomeCollection) => {
    const formattedIncomeCollection: Partial<IncomeCollectionEndpoint> = {
        CobroID: incomeCollection.id?? undefined,
        FechaID: stringToNumericalDate(incomeCollection.dateId),
        IngresoID: incomeCollection.incomeId,
        ChequeTerceroID: incomeCollection.thirdPartyChequeId?? undefined,
        CuentaBancariaID: incomeCollection.bankAccountId?? undefined,
        Importe: incomeCollection.amount,
    };
    return formattedIncomeCollection;
}

export const createAdapterToIncomeCollection = (incomeCollectionEndpoint: IncomeCollectionEndpoint) => {
    const formattedIncomeCollection: IncomeCollection = {
        paymentType:paymentTypeChecker(incomeCollectionEndpoint),
        number:numberFinder(incomeCollectionEndpoint),
        id: incomeCollectionEndpoint.CobroID,
        dateId: numericalDateToString(incomeCollectionEndpoint.FechaID? incomeCollectionEndpoint.FechaID : 0),
        incomeId: incomeCollectionEndpoint.IngresoID,
        thirdPartyChequeId: incomeCollectionEndpoint.ChequeTerceroID?? undefined,
        bankAccountId: incomeCollectionEndpoint.CuentaBancariaID?? undefined,
        amount: incomeCollectionEndpoint.Importe,
    };
    return formattedIncomeCollection;
}

const numberFinder = (obj: IncomeCollectionEndpoint) => {
    if (obj.CuentaBancariaID !== null) {
        return obj.CuentaBancaria.CuentaNumero
    } else {
        return obj.ChequeTercero.Numero
    }
  };

const paymentTypeChecker = (obj:IncomeCollectionEndpoint) => {
    if (obj.CuentaBancariaID !== null) { 
        return PaymentType.BankAccount 
    } else  {
        return PaymentType.ThirdPartyCheque
    }
}
