import styled from "styled-components";

interface SidebarNavMenuItemContainerProps {
  show: boolean;
}

export const SidebarNavMenuItemContainer = styled.li<SidebarNavMenuItemContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-ce;
  width: 100%;
  cursor: pointer;
  user-select: none;
  transition: all 150ms ease-in-out;
  flex-direction: column;
  height: fit-content;
  .menu-icon {
    display: flex;
    flex-direction: column;
    svg {
      width: 100%;
      color: white;
      position: relative;
      font-size: 30px;
      margin: 10px 0px;
    }
  }
`;
interface StyledSidebarNavSubmenusContainerProps {
  expand: boolean;
}
export const StyledSidebarNavSubmenusContainer = styled.ul<StyledSidebarNavSubmenusContainerProps>`
  width: 100%;
  max-height: ${(props) => (props.expand ? "100vh" : '0px')};
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 150ms ease-in-out;
  padding-left: 13px;
  margin-left: 50px;
  @media (max-width: 1360px){
    padding-left: 30px;
  }
`;
