import {
  createAdapterToProcess,
  createAdapterToProcessEndpoint,
} from "../../../../parametry/general/process/adapters/process.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  MonthClosure,
  MonthClosureEndpoint,
} from "../domain/MonthClosure.model";

type Dictionary = {
  [key: string]: string;
};

const monthClosureAttributesDiccionary: Dictionary = {
  id: "FechaID",
  dischargeDate: "FechaAlta",
  closureProcessId: "ProcesoID",
  closureProcess: "Proceso",
};

export const createAdapterToFieldMonthClosureEndpoint = (
  key: string
): string => {
  return monthClosureAttributesDiccionary[key];
};

export const createAdapterToMonthClosureEndpoint = (
  monthClosure: MonthClosure
) => {
  const formattedDate = stringToNumericalDate(monthClosure.id);
  const formattedMonthClosure: MonthClosureEndpoint = {
    FechaID: formattedDate,
    FechaAlta: monthClosure.dischargeDate,
    ProcesoID: monthClosure.closureProcessId,
    Proceso: monthClosure.closureProcess
      ? createAdapterToProcessEndpoint(monthClosure.closureProcess)
      : undefined,
  };
  return formattedMonthClosure;
};

export const createAdapterToMonthClosure = (
  monthClosureEndpoint: MonthClosureEndpoint
) => {
  const formattedDate = numericalDateToString(monthClosureEndpoint.FechaID);
  const formattedMonthClosure: MonthClosure = {
    id: formattedDate,
    dischargeDate: monthClosureEndpoint.FechaAlta,
    closureProcessId: monthClosureEndpoint.ProcesoID,
    closureProcess: monthClosureEndpoint.Proceso
      ? createAdapterToProcess(monthClosureEndpoint.Proceso)
      : undefined,
  };
  return formattedMonthClosure;
};
