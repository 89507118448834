import { Area, AreaEndpoint } from "../domain/Area.model";

type Dictionary = {
    [key: string]: string;
};

const areaAttributesDiccionary: Dictionary = {
    id: 'AreaID',
    name: 'Area'
}

export const createAdapterToFieldAreaEndpoint = (key: string): string => {
    return areaAttributesDiccionary[key];
}

export const createAdapterToAreaEndpoint = (area: Area) => {
    const formattedArea: AreaEndpoint = {
        AreaID: area.id ?? undefined,
        Area: area.name
    };
    return formattedArea;
}

export const createAdapterToArea = (areaEndpoint: AreaEndpoint) => {
    const formattedArea: Area = {
        id: areaEndpoint.AreaID,
        name: areaEndpoint.Area,
    };
    return formattedArea;
}