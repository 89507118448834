import { maskNumber } from "../../../../utilities/maskNumber";

export interface InputPieChartData {
  [key: string]: number;
}

export interface OutputPieChartData {
  name: string;
  value: number;
}

export const createChartPieAdapter = (
  data: InputPieChartData
): OutputPieChartData[] => {
  const transformedData: OutputPieChartData[] = [];

  if (!data) {
    return [];
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      transformedData.push({
        name: key,
        value: data[key] ? Math.abs(data[key]) : 0,
      });
    }
  }

  return transformedData;
};
