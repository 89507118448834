import { PaymentMethod, PaymentMethodEndpoint } from "../domain/PaymentMethod.model";

type Dictionary = {
    [key: string]: string;
};

const paymentMethodAttributesDiccionary: Dictionary = {
    id: 'FormatoPagoID',
    name: 'FormatoPago',
    code: 'FormatoPagoCorto',
}

export const createAdapterToFieldPaymentMethodEndpoint = (key: string): string => {
    return paymentMethodAttributesDiccionary[key];
}

export const createAdapterToPaymentMethodEndpoint = (paymentMethod: PaymentMethod) => {
    const formattedPaymentMethod: PaymentMethodEndpoint = {
        FormatoPagoID: paymentMethod.id ?? undefined,
        FormatoPago: paymentMethod.name,
        FormatoPagoCorto: paymentMethod.code,
    };
    return formattedPaymentMethod;
}

export const createAdapterToPaymentMethod = (paymentMethodEndpoint: PaymentMethodEndpoint) => {
    const formattedPaymentMethod: PaymentMethod = {
        id: paymentMethodEndpoint.FormatoPagoID,
        name: paymentMethodEndpoint.FormatoPago,
        code: paymentMethodEndpoint.FormatoPagoCorto,
    };
    return formattedPaymentMethod;
}