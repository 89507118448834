import {
  ColumnFilter,
  ColumnType,
  OrdersColumn,
  Table,
  TableActionsProps,
  TableColumn,
  TableParameters,
  ValueType,
} from "../../Table";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteMenuMutation, useGetMenusQuery } from "../services/menuApi";
import { Menu } from "../domain/Menu.model";
import { useNavigate } from "react-router-dom";
import { StyledMenuTableIcon } from "../styled-components/StyledMenuTableIcon";
import SuspenseLoader from "../../SuspenseLoader/SuspenseLoader";
import translate from "../../../../language/translate";
import { setNotification } from "../../NotificationBar/state/notification.reducer";
import { useAppDispatch } from "../../../../utilities/hooks/redux.hooks";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import styled from "styled-components";
import { textTruncate } from "../../../../bankReconciliation/utils/textUtils";
import { InputText } from "primereact/inputtext";
import { FilterTag } from "../../Table/components/TableFilters/FilterTag";
import { Search } from "@mui/icons-material";
import TableFilter from "../../Table/components/TableFilters/TableFilter";
import { AddFilter } from "../../Table/components/TableFilters/AddFilter";

function MenusTable() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ADD_MENU_URL = "/menus/add";
  const EDIT_MENU_URL = "/menus/edit";

  const [filters, setFilters] = useState<any>({
    order: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "parentMenu.label": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "parentMenu.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilter, setGlobalFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);

  const columnsTable: Array<TableColumn<Menu>> = [
    {
      field: "menuId",
      label: "menuId",
      type: ColumnType.TEXT,
      order: false,
      hidden: true,
    },
    {
      field: "icon",
      label: intl.formatMessage({
        id: "icon",
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "10%",
      hidden: false,
      textAlign: "center",
      render: (row) =>
        row.icon ? (
          <StyledMenuTableIcon src={row.icon} alt={`icon_${row.name}`} />
        ) : (
          <FolderIcon />
        ),
    },
    {
      field: "order",
      label: intl.formatMessage({
        id: "order",
      }),
      type: ColumnType.NUMBER,
      width: "10%",
      order: true,
      hidden: false,
    },
    {
      field: "name",
      label: intl.formatMessage({
        id: "name",
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "25%",
      hidden: false,
    },
    {
      field: "label",
      label: intl.formatMessage({
        id: "label",
      }),
      type: ColumnType.TEXT,
      order: true,
      width: "auto",
      hidden: false,
    },
    {
      field: "father",
      label: intl.formatMessage({
        id: "father",
      }),
      type: ColumnType.NUMBER,
      width: "25%",
      order: false,
      hidden: false,
      render: (row) => (
        <span>
          {typeof row.parentMenu !== "string"
            ? row.parentMenu?.label
            : row.parentMenu ?? "---"}
        </span>
      ),
    },
  ];

  const [parameters, setParameters] = useState<TableParameters<Menu>>({
    page: 1,
    pageSize: 20,
    order: { field: "id", order: OrdersColumn.ASC },
  });

  const tableActions: TableActionsProps<Menu>[] = [
    {
      name: intl.formatMessage({ id: "add" }),
      toExecute: () => {
        navigate(ADD_MENU_URL);
      },
      freeAction: true,
    },
    {
      name: "edit",
      icon: <EditIcon />,
      toExecute: (data) => {
        navigate(`${EDIT_MENU_URL}/${data?.menuId}`);
      },
    },
    {
      name: "delete",
      icon: <DeleteForeverIcon />,
      toExecute: (data) => {
        // eslint-disable-next-line no-restricted-globals
        // if (
        //   !confirm(
        //     intl.formatMessage({
        //       id: "confirm delete?",
        //     })
        //   )
        // ) {
        //   return;
        // }
        // if (data?.menuId !== undefined) {
        //   deleteMenu(data.menuId);
        // }
      },
      color: () => "#ff0000",
    },
  ];

  const { data, isLoading, isError, error } = useGetMenusQuery(parameters);

  const [deleteMenu] = useDeleteMenuMutation();

  const handleParameters = (newParameters: TableParameters<Menu>) => {
    setParameters(newParameters);
  };

  useEffect(() => {
    if (isError) {
      dispatch(
        setNotification({
          show: true,
          severity: "error",
          message: intl.formatMessage({
            // @ts-ignore
            id: error.message ?? error?.data?.msg ?? "error loading data",
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (isError) {
    return <div>{translate("error loading data")}</div>;
  }

  const onFilterChange = (e: any) => {
    const filters = e.filters;

    setFilters(filters);
  };

  const filtroChange = (e: any) => {
    setGlobalFilter(e.target.value);
  };

  const renderIcons = (rowData: any) => {
    return (
      <>
        <EditIcon
          style={{ cursor: "pointer" }}
          onClick={() => editarMenus(rowData)}
        />
        <DeleteForeverIcon
          style={{ cursor: "pointer" }}
          onClick={() => eliminarMenus(rowData)}
        />
      </>
    );
  };

  const agregarMenus = () => {
    navigate(ADD_MENU_URL);
  };

  const editarMenus = (rowData: any) => {
    navigate(`${EDIT_MENU_URL}/${rowData?.menuId}`);
  };

  const eliminarMenus = (rowData: any) => {
    if (rowData?.menuId !== undefined) {
      deleteMenu(rowData.menuId);
    }
  };

  const handleRemoveFilter = (filterToRemove: ColumnFilter<ValueType>) => {
    const newFilters = parameters?.filters?.filter(
      (filter) => filter !== filterToRemove
    );
    handleParameters({
      ...parameters,
      page: 1,
      filters: newFilters ? [...newFilters] : [],
    });
  };

  const handleAddFilter = (filter: ColumnFilter<ValueType>) => {
    handleParameters({
      ...parameters,
      page: 1,
      filters: parameters?.filters
        ? [...parameters?.filters, filter]
        : [filter],
    });
  };

  return (
    <>
      {/* <h1 onClick={() => console.log(data)}>gabo</h1> */}
      {/* <Table<Menu>
        columns={columnsTable}
        data={data}
        parameters={parameters}
        onChangeParameters={handleParameters}
        actions={tableActions}
      /> */}

      <ContenedorBtns>
        <div className="botones">
          <Button onClick={agregarMenus}>
            <i className="pi pi-plus-circle"></i>Agregar
          </Button>
          {parameters?.filters?.map((filter, index) => {
            return (
              <FilterTag<any>
                key={`filtertag_${index}`}
                filter={filter}
                columns={columnsTable}
                onRemove={handleRemoveFilter}
              />
            );
          })}
        </div>
        <div className="buscador">
          <div style={{ display: "flex" }}>
            <Search className="lupa-icono" />
            <InputText
              type="search"
              onInput={(e) => filtroChange(e)}
              placeholder="Búsqueda..."
              className="input-search"
            ></InputText>
          </div>
          {/* <TableFilter openFilter={openFilter} setOpenFilter={setOpenFilter} /> */}
        </div>
      </ContenedorBtns>

      <AddFilter
        show={openFilter}
        columns={columnsTable.filter(
          (column: TableColumn<any>) => !!column.filterOperators
        )}
        filters={parameters.filters}
        onApply={(data: ColumnFilter<ValueType>) => {
          handleAddFilter(data);
          setOpenFilter(!openFilter);
        }}
        onCancel={() => {
          setOpenFilter(!openFilter);
        }}
      />


      <ContenedorTabla>
        <DataTable
          value={data && data.data ? data.data : []}
          paginator
          rows={20}
          rowsPerPageOptions={[20, 50, 100]}
          emptyMessage="No hay registros para mostrar"
          removableSort
          resizableColumns
          filters={filters}
          onFilter={onFilterChange}
          filterDisplay="row"
          globalFilter={globalFilter}
        >
          <Column
            resizeable
            headerClassName="custom-header"
            className="custom-row"
            body={(rowData: any) => textTruncate(rowData.icon, 13)}
            field="icon"
            header="Icono"
            sortable
            style={{ width: "15%" }}
          />
          <Column
            resizeable
            headerClassName="custom-header"
            body={(rowData: any) => rowData.order}
            field="order"
            header="Orden"
            sortable
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar orden"
            className="custom-row"
            style={{ width: "10%" }}
          />
          <Column
            resizeable
            headerClassName="custom-header"
            body={(rowData: any) => rowData.name}
            field="name"
            header="Nombre"
            sortable
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar nombre"
            className="custom-row"
            style={{ width: "25%" }}
          />
          <Column
            resizeable
            headerClassName="custom-header"
            body={(rowData: any) =>
              rowData.parentMenu ? rowData.parentMenu.label : rowData.name
            }
            field="parentMenu.label"
            header="Etiqueta"
            sortable
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar etiqueta"
            className="custom-row"
            style={{ width: "25%" }}
          />
          <Column
            resizeable
            headerClassName="custom-header"
            body={(rowData: any) =>
              rowData.parentMenu ? rowData.parentMenu.name : "---"
            }
            field="parentMenu.name"
            header="Superior"
            sortable
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar superior"
            className="custom-row"
            style={{ width: "25%" }}
          />
          <Column
            resizeable
            headerClassName="custom-header"
            className="custom-row"
            body={(rowData: any) => renderIcons(rowData)}
            header="Acciones"
          />
        </DataTable>
      </ContenedorTabla>
    </>
  );
}

export default MenusTable;

const ContenedorTabla = styled.div`
  background-color: #fff;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
`;

const ContenedorBtns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0 1rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  border: none;
  color: #00000099;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    color: #000000d6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  i {
    margin-right: 5px;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: auto;
  }
`;
