import {
  StyledSidebarNavMenuItemLink,
  StyledSidebarNavMenuItemName
} from "../styled-components/StyledSidebarNavMenuItemLink";
import FolderIcon from "@mui/icons-material/Folder";
import { SidebarNavMenuItemContainer } from "../styled-components/SidebarNavMenuItemContainer";
import { SidebarMenuItemIcon } from "../styled-components/SidebarMenuItemIcon";
import { useContext } from "react";
import { SidebarContext } from "../../../Sidebar/SidebarContext";
import {SidebarMenu} from "../domain/sidebarNav";
import translate from "../../../../../language/translate";

function SidebarNavMenuItemNode({
  item,
} : {
    item: SidebarMenu;
}) {
  const { verMenuLateral, mostrarOcultarMenu } = useContext(SidebarContext);

  return (
    <SidebarNavMenuItemContainer show={verMenuLateral}>
      <StyledSidebarNavMenuItemLink
        to={item.action ?? "#"}
        $showname={verMenuLateral}
        onClick={mostrarOcultarMenu}
      >
        <StyledSidebarNavMenuItemName $showname={verMenuLateral}>{translate(item.label)}</StyledSidebarNavMenuItemName>
      </StyledSidebarNavMenuItemLink>
    </SidebarNavMenuItemContainer>
  );
}

export default SidebarNavMenuItemNode;
