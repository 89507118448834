import { numericalDateToString, stringToNumericalDate } from "../../../../utilities/dateUtils";
import { Headquarters, HeadquartersEndpoint } from "../domain/Headquarters.model";

type Dictionary = {
    [key: string]: string;
};

const headquartersAttributesDiccionary: Dictionary = {
    id: 'FechaID',
    HWBankDolarBillete: 'HWBankDolarBillete',
    HWBankDolarDivisa: 'HWBankDolarDivisa',
    HWmmPesos: 'HWmmPesos',
    HWBankPesos: 'HWBankPesos',
}

export const createAdapterToFieldHeadquartersEndpoint = (key: string): string => {
    return headquartersAttributesDiccionary[key];
}

export const createAdapterToHeadquartersEndpoint = (headquarters: Headquarters) => {
    const formattedHeadquarters: HeadquartersEndpoint = {
        FechaID: stringToNumericalDate(headquarters.id),
        HWBankDolarBillete: headquarters.HWBankDolarBillete,
        HWBankDolarDivisa: headquarters.HWBankDolarDivisa,
        HWmmPesos: headquarters.HWmmPesos,
        HWBankPesos: headquarters.HWBankPesos,
    };
    return formattedHeadquarters;
}

export const createAdapterToHeadquarters = (headquartersEndpoint: HeadquartersEndpoint) => {
    const formattedHeadquarters: Headquarters = {
        id: numericalDateToString(headquartersEndpoint.FechaID),
        HWBankDolarBillete: headquartersEndpoint.HWBankDolarBillete,
        HWBankDolarDivisa: headquartersEndpoint.HWBankDolarDivisa,
        HWmmPesos: headquartersEndpoint.HWmmPesos,
        HWBankPesos: headquartersEndpoint.HWBankPesos,
    };
    return formattedHeadquarters;
}