import { useGetMenusQuery } from "../menu/services/menuApi";
import { useIntl } from "react-intl";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Breadcrumb({ breadcrumbList }: any) {
  const intl = useIntl();
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const containsUUID = (str: string) => uuidRegex.test(str);
  const filteredBreadcrumbList = breadcrumbList.filter(
    (bread: any) => !containsUUID(bread.label)
  );
  function removerAcentos(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  
  const { data: menus } = useGetMenusQuery(undefined);
  const jerarquiaBread: any[] = [];
  filteredBreadcrumbList.map((bread: any) => {
    bread.label === 'import' && jerarquiaBread.push({ label: intl.formatMessage({id: bread.label}), link: bread.link })
    const parentMenu:any = menus?.data?.find((menu) => menu.action === bread.link)?.parentMenu;
    if(parentMenu) {
      let labelParentMenu: string[] | string = parentMenu.action.split("/")[1];
      labelParentMenu = labelParentMenu[0].toUpperCase() + labelParentMenu.slice(1);
      if(removerAcentos(labelParentMenu) != removerAcentos(parentMenu.label)){
        jerarquiaBread.push({ label: intl.formatMessage({id: labelParentMenu}), link: bread.link });
      };
      jerarquiaBread.push({ label: intl.formatMessage({id: parentMenu.label}), link: bread.link });
      jerarquiaBread.push({ label: intl.formatMessage({id: bread.label}), link: bread.link });
    }
  })

  return (
    <ContenedorPrincipal>
      {
        jerarquiaBread.map((bread: any, index:any) => {
          bread.label = bread.label[0].toUpperCase() + bread.label.slice(1);
          return(
          <ContenedorBread key={`breadcrumb-${index}`} tabIndex={index}>
            <BreadcrumbsLeyenda tabIndex={index} key={`breadcrumb_${index}`}>
              <Link to={bread.link}>{bread.label}</Link>
            </BreadcrumbsLeyenda>
            {jerarquiaBread.length-1 > index ? <ArrowForwardIosIcon /> : null}
          </ContenedorBread>
        )})
      }
    </ContenedorPrincipal>
  )
  
}

export default Breadcrumb;

const ContenedorPrincipal = styled.div`
  width: 100%;
  height: 30px;
  margin-right: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
`;

const BreadcrumbsLeyenda = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  padding: 0.8em;
  margin-right: 6px;
  a {
    text-decoration: none;
    color: ${(props) => props.tabIndex == 0 ? '#3f4249' : '#9A989E'};
    font-size: 14px;
    font-weight: 500;
    margin-right: 0.2em; 
  }
`;

const ContenedorBread = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;
  svg {
    color: ${(props) => props.tabIndex == 0 ? '#4d4949f8' : '#9A989E'};
    position: relative;
    top: 2px;
    font-size: 16px;
    width: 12px;
  }
`;
