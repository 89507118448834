import { ConceptType, ConceptTypeEndpoint } from "../domain/ConceptType.model";

type Dictionary = {
    [key: string]: string;
};

const conceptTypeAttributesDiccionary: Dictionary = {
    id: 'TipoConceptoID',
    name: 'TipoConcepto',
    order: 'Orden',
}

export const createAdapterToFieldConceptTypeEndpoint = (key: string): string => {
    return conceptTypeAttributesDiccionary[key];
}

export const createAdapterToConceptTypeEndpoint = (conceptType: ConceptType) => {
    const formattedConceptType: ConceptTypeEndpoint = {
        TipoConceptoID: conceptType.id ?? undefined,
        TipoConcepto: conceptType.name,
        Orden: conceptType.order,
    };
    return formattedConceptType;
}

export const createAdapterToConceptType = (conceptTypeEndpoint: ConceptTypeEndpoint) => {
    const formattedConceptType: ConceptType = {
        id: conceptTypeEndpoint.TipoConceptoID,
        name: conceptTypeEndpoint.TipoConcepto,
        order: conceptTypeEndpoint.Orden,
    };
    return formattedConceptType;
}