import { TableColumn } from "../domain/columns.model";
import { StyledTableBody } from "../styled-components/StyledTableBody";
import { StyledTableRow } from "../styled-components/StyledTableRow";
import {
  StyledTableCell,
  StyledTableCellActions,
} from "../styled-components/StyledTableCell";
import { TableBodyProps } from "../domain/table.model";
import TableActionsContainer from "./TableActions";
import {getAlignByColumnType} from "../tableUtils/columnUtils";

function TableBody<T>({ rows, columns, selectedColumns, actions, columnsOrder }: TableBodyProps<T>) {
  const availableColumns = columns ?? [];
  const orderedColumns = columnsOrder.map((columnField) => {
    return availableColumns.find(availableColumn => availableColumn.field === columnField);
  })

  const filterColumns = orderedColumns.filter(colToFilter => !!selectedColumns.find(selected => selected.field === colToFilter?.field));

  return (
    <StyledTableBody>
      {rows?.map((cell: any, indexRow: number) => (
        <StyledTableRow key={`table_row_${indexRow}`}>
          {filterColumns?.map((col: TableColumn<T> | undefined, indexCol: number) =>
            col ?
                !col.hidden ? (
                    <StyledTableCell
                        data-label={col.label}
                        width={col.width}
                        key={`table_row_${indexRow}_col_${indexCol}`}
                        textAlign={col.textAlign ? col.textAlign : getAlignByColumnType(col.type)}
                    >
                      {col.render ? col.render(cell) : col.mask ? col.mask(cell[col.field]) : cell[col.field]}
                    </StyledTableCell>
                ) : null
                : null
          )}
          {actions && actions?.length > 0 ? (
            <StyledTableCellActions>
              <TableActionsContainer data={cell} actions={actions}/>
            </StyledTableCellActions>
          ) : null}
        </StyledTableRow>
      ))}
    </StyledTableBody>
  );
}

export default TableBody;
