import { createAdapterToBankAccount } from "../../../../parametry/financial/bankAccount/adapters/bankAccount.adapter";
import { createAdapterToBankTransactionClassification } from "../../../../parametry/financial/bankTransactionClassification/adapters/bankTransactionClassification.adapter";
import { createAdapterToBankTransactionType } from "../../../../parametry/financial/bankTransactionType/adapters/bankTransactionType.adapter";
import { createAdapterToConcept } from "../../../../parametry/financial/concept/adapters/concept.adapter";
import { numericalDateToString, stringToNumericalDate } from "../../../../utilities/dateUtils";
import { StatementMovement, StatementMovementEndpoint } from "../domain/StatementMovement.model";

type Dictionary = {
    [key: string]: string;
};

const statementMovementAttributesDiccionary: Dictionary = {
    id: 'ExtractoBancarioMovimientoID',
    dateId: 'FechaID',
    bankId: 'BancoID',
    bankAccountId: 'CuentaBancariaID',
    conceptId: 'ConceptoID',
    currencyId: 'MonedaID',
    bankTransactionClassificationId: 'ClasificacionMovimientoBancarioID',
    amount: 'Importe',
    hourId: 'HoraID',
    bankTransactionTypeId: 'TipoMovimientoBancarioID',
    valueDate: 'FechaValor',
    processDate: 'FechaProceso',
    CUIT:'CUIT',
}

export const createAdapterToFieldStatementMovementEndpoint = (key: string): string => {
    return statementMovementAttributesDiccionary[key];
}

export const createAdapterToStatementMovementEndpoint = (statementMovement: StatementMovement) => {
    const formattedStatementMovement: Partial<StatementMovementEndpoint> = {
        ExtractoBancarioMovimientoID: statementMovement.id ?? undefined,
        FechaID: stringToNumericalDate(statementMovement.dateId),
        CuentaBancariaID: statementMovement.bankAccountId,
        ConceptoID: statementMovement.conceptId ,
        ClasificacionMovimientoBancarioID: statementMovement.bankTransactionClassificationId,
        Importe: statementMovement.amount,
        HoraID: parseInt(statementMovement?.hourId?.replace(/:/g, '')),
        TipoMovimientoBancarioID: statementMovement.bankTransactionTypeId,
        FechaValor: stringToNumericalDate(statementMovement.valueDate),
        FechaProceso: stringToNumericalDate(statementMovement.processDate),
        CUIT: statementMovement.CUIT,
    };
    return formattedStatementMovement;
}

export const createAdapterToStatementMovement = (statementMovementEndpoint: StatementMovementEndpoint) => {
    const formattedHour = statementMovementEndpoint?.HoraID?.toString()
    const formattedStatementMovement: StatementMovement = {
        id: statementMovementEndpoint.ExtractoBancarioMovimientoID ?? undefined,
        dateId: numericalDateToString(statementMovementEndpoint.FechaID),
        bankAccountId: statementMovementEndpoint.CuentaBancariaID,
        bankAccount: statementMovementEndpoint.CuentaBancaria? createAdapterToBankAccount(statementMovementEndpoint.CuentaBancaria) : undefined,
        conceptId: statementMovementEndpoint.ConceptoID ,
        concept: statementMovementEndpoint.Concepto? createAdapterToConcept(statementMovementEndpoint.Concepto) : undefined,
        bankTransactionClassificationId: statementMovementEndpoint.ClasificacionMovimientoBancarioID,
        bankTransactionClassification: statementMovementEndpoint.ClasificacionMovimientoBancario? createAdapterToBankTransactionClassification(statementMovementEndpoint.ClasificacionMovimientoBancario) : undefined,
        amount: statementMovementEndpoint.Importe,
        hourId: formattedHour,
        bankTransactionTypeId: statementMovementEndpoint.TipoMovimientoBancarioID,
        bankTransactionType: statementMovementEndpoint.TipoMovimientoBancario? createAdapterToBankTransactionType(statementMovementEndpoint.TipoMovimientoBancario) : undefined,
        valueDate: numericalDateToString(statementMovementEndpoint.FechaValor),
        processDate: numericalDateToString(statementMovementEndpoint.FechaProceso),
        CUIT: statementMovementEndpoint.CUIT,
    };
    return formattedStatementMovement;
}