import {
  StyledSidebarNavMenuItemButton,
  StyledSidebarNavMenuItemName,
} from "../styled-components/StyledSidebarNavMenuItemLink";
import { StyledMenuArrow } from "../../../menu/styled-components/StyledMenuArrow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  SidebarNavMenuItemContainer,
  StyledSidebarNavSubmenusContainer,
} from "../styled-components/SidebarNavMenuItemContainer";
import React, { useContext } from "react";
import SidebarNavMenuItem from "./SidebarNavMenuItem";
import { SidebarContext } from "../../../Sidebar/SidebarContext";
import { SidebarMenu } from "../domain/sidebarNav";
import {SidebarMenuItemIcon} from "../styled-components/SidebarMenuItemIcon";
import { useAppDispatch, useAppSelector } from "../../../../../utilities/hooks/redux.hooks";
import { useGetStatusMenu } from "../../../../../utilities/menuStatusUtils";
import { changeStatusMenu, changeToFalseMenu } from "../state/menuStatusSlice.reducer";
import translate from "../../../../../language/translate";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import { ExpandMoreRounded } from "@mui/icons-material";

function SidebarNavMenuItemGroup({
  item,
}: {
  item: SidebarMenu;
}) {
  const dispatch = useAppDispatch()
  const statusArray = useAppSelector((state:any) => state.menuStatus.menus)
  const statusMenu = useGetStatusMenu(item.menuId,statusArray);
  const { verMenuLateral, setVerMenuLateral } = useContext(SidebarContext);

  const handleExpand = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    if(verMenuLateral) {
      dispatch(changeToFalseMenu(item.menuId));
      setVerMenuLateral(false);
    };
    let isLink = false;
    let element = e.target as HTMLElement | null;
    while (element) {
      if (element instanceof HTMLAnchorElement) {
        isLink = true;
        break;
      }
      element = element.parentElement;
  }
  if (isLink) {
    return;
  }
    dispatch(changeStatusMenu(item.menuId));
  };

  const configureIcon = () =>{
    const iconMap: any = {
      "/transacciones": <CurrencyExchangeIcon />,
      "/parametria": <QueryStatsIcon />,
      "/administracion": <SettingsIcon />
    };
    return iconMap[item.action] || (
    <SidebarMenuItemIcon
    src={item.icon}
    alt={`menu_icon_${item.label}`}
    menuLateral={verMenuLateral}
    />)
  }

  return (
    <SidebarNavMenuItemContainer show={verMenuLateral} onClick={handleExpand}>
      {verMenuLateral ?
        <div className="menu-icon">
          {configureIcon()}
        </div>
      :
        <>
          <StyledSidebarNavMenuItemButton expand={statusMenu} $showname={verMenuLateral} >
            {item.icon && !item.parentMenu ? 
              configureIcon()
            : '- '}
            
            <StyledSidebarNavMenuItemName $showname={verMenuLateral}>    
              {translate(item.label)}
            </StyledSidebarNavMenuItemName>

            <ExpandMoreRounded/>
            {/* <StyledMenuArrow show={verMenuLateral} expand={statusMenu}>
              <ArrowForwardIosIcon />
            </StyledMenuArrow> */}
          </StyledSidebarNavMenuItemButton>

          <StyledSidebarNavSubmenusContainer expand={statusMenu}>
            {item.submenus.map((subitem, index) => (
              <SidebarNavMenuItem
                key={`subitem_${index}_${subitem.label}`}
                item={subitem}
              />
            ))}
          </StyledSidebarNavSubmenusContainer>
        </>
      }
    </SidebarNavMenuItemContainer>
  );
}

export default SidebarNavMenuItemGroup;
