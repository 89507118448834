import { numericalDateToString, stringToNumericalDate } from "../../../../utilities/dateUtils";
import { createAdapterToCurrency } from "../../../general/currency/adapters/currency.adapter";
import { createAdapterToInvestmentType } from "../../investmentType/adapters/investmentType.adapter";
import { InvestmentInstrument, InvestmentInstrumentEndpoint } from "../domain/InvestmentInstrument.model";

type Dictionary = {
    [key: string]: string;
};

const investmentInstrumentAttributesDiccionary: Dictionary = {
    id: 'InstrumentoInversionID',
    name: 'InstrumentoInversion',
    currencyId: 'MonedaID',
    investmentTypeId: 'TipoInversionID',
    dueDate: 'FechaVencimientoID',
    ISIN: 'ISIN',
    
}

export const createAdapterToFieldInvestmentInstrumentEndpoint = (key: string): string => {
    return investmentInstrumentAttributesDiccionary[key];
}

export const createAdapterToInvestmentInstrumentEndpoint = (investmentInstrument: InvestmentInstrument) => {
    const formattedDate = (investmentInstrument.dueDate !== undefined) ?(stringToNumericalDate(investmentInstrument.dueDate)) : 0
    const formattedInvestmentInstrument: Partial<InvestmentInstrumentEndpoint> = {
        InstrumentoInversionID: investmentInstrument.id ?? undefined,
        InstrumentoInversion: investmentInstrument.name,
        MonedaID: investmentInstrument.currencyId,
        TipoInversionID: investmentInstrument.investmentTypeId,
        FechaVencimientoID: formattedDate ,
        ISIN: investmentInstrument.ISIN,

    };
    return formattedInvestmentInstrument;
}

export const createAdapterToInvestmentInstrument = (investmentInstrumentEndpoint: InvestmentInstrumentEndpoint) => {
    const formattedDate: string | undefined = (investmentInstrumentEndpoint.FechaVencimientoID !== 0) ? (numericalDateToString(investmentInstrumentEndpoint.FechaVencimientoID)): undefined
    const formattedInvestmentInstrument: InvestmentInstrument = {
        id: investmentInstrumentEndpoint.InstrumentoInversionID,
        name: investmentInstrumentEndpoint.InstrumentoInversion,
        currencyId: investmentInstrumentEndpoint.MonedaID,
        currency: investmentInstrumentEndpoint.Moneda ? createAdapterToCurrency(investmentInstrumentEndpoint.Moneda) : undefined,
        investmentTypeId: investmentInstrumentEndpoint.TipoInversionID,
        investmentType: investmentInstrumentEndpoint.TipoInversion ? createAdapterToInvestmentType(investmentInstrumentEndpoint.TipoInversion) : undefined,
        dueDate: formattedDate,
        ISIN: investmentInstrumentEndpoint.ISIN,
    };
    return formattedInvestmentInstrument;
}