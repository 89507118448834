import styled from "styled-components";

export const SidebarNavLogout = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0.3rem;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        transition: all 200ms ease-in-out;
        color: #262627c0;
    }
    span {
        color: #111;
        text-transform: uppercase;
        transition: all 1000ms ease-in-out;
        opacity: 1;
        margin: 0 0 0 10px;
        font-size: 16px;
    }
    &:hover {
      background-color: #F2F3FA;
    }
`