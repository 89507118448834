import styled from "styled-components";

interface StyledDateRangeFilterWrapperWrapperProps {
  margin?: string;
}

export const StyledDateRangeFilterWrapper = styled.div<StyledDateRangeFilterWrapperWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  /* padding: 3px 10px; */
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 5;
  //react-datepicker-wrapper
  input {
    border: none;
    outline: none;
    font-size: 1rem;
    width: 200px;
    padding: 10px 20px 10px 5px !important;
    align-items: center;
    text-align: center;
  }
  svg {
    top: 0.1rem;
    right: -18px;
    font-size: 20px;
    color: #696969;
  }
  .react-datepicker__header{
    margin: 10px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .react-datepicker__current-month, .react-datepicker__day-names {
      div {
        ::first-letter{
          text-transform: uppercase;
        }
      }
      ::first-letter{
        text-transform: uppercase;
      }
    }
  }
  .react-datepicker{
    background-color: #f2f3fa;
    button {
      margin: 10px;
    }
  }
  .react-datepicker__month-container{
    background-color: #f2f3fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .react-datepicker__month{
    margin: 5px 10px;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range{
    background-color: #64b33b !important;
    border-radius: 100%;
    color: #fff !important;
    transition: 0.5s ease-in-out;
  }
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover{
    border-radius: 40% !important;
  }
  .react-datepicker__day--keyboard-selected{
    background-color: #64b33b95 !important;
    color: white !important;
    border-radius: 100%;
    transition: 0.5s ease-in-out;
  }
  .react-datepicker__day--keyboard-selected:hover{
    background-color: #64b33b95 ;
    border-radius: 40% !important;
  }

  .react-datepicker__day{
    transition: 0.3s ease-in-out;
  }
  .react-datepicker__day:hover{
    border-radius: 50%;
  }
`;

export const StyledDateRangeFilterOptionsTitle = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
`;

export const StyledDateRangeFilterOptions = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const StyledDateRangeFilterOption = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 4px 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
