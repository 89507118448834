import { BankAccountEndpoint } from "../../../../parametry/financial/bankAccount/domain/BankAccount.model";
import {TableDataProps} from "../../../../shared-kernel/components/Table";
import { ThirdPartyChequeEndpoint } from "../../../cheques/thirdPartyCheque/domain/ThirdPartyCheque.model";

export type IncomeCollection = {
    number?:string;
    paymentType?:PaymentType;
    id?:number,
	dateId:string,
	incomeId:number,
	thirdPartyChequeId?:number,
	bankAccountId?:number,
	amount:number,
};

export type IncomeCollectionEndpoint = {
    CobroID?:number,
	FechaID:number,
	IngresoID:number,
	ChequeTerceroID?:number,
    ChequeTercero:ThirdPartyChequeEndpoint,
	CuentaBancariaID?:number,
    CuentaBancaria:BankAccountEndpoint,
	Importe:number,
}

export enum PaymentType  {
    ThirdPartyCheque = 1,
    BankAccount = 2,
}

export type EndpointIncomeCollection = {
    Status: number;
    Content: IncomeCollectionEndpoint
}
export type EndpointIncomeCollectionFiltered ={
    total: number;
    page: number;
    rows: IncomeCollectionEndpoint[]
}
export type EndpointIncomeCollectionList = {
    Status: number;
    Content: IncomeCollectionEndpoint[]
}

export interface IncomeCollectionDataProps extends TableDataProps<IncomeCollection>{
    data: IncomeCollection[];
    total?: number;
}
