import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Session } from '../../domain/auth';
import {appConfig} from "../../../../../config";

const storageToken = localStorage.getItem(appConfig.APP_TOKEN_NAME);

const authEmptyState: Session = {
  token: undefined
};

export const initialState: Session = storageToken ? {
  token: storageToken
} : authEmptyState;

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: () => authEmptyState,
    setCredentials: (_state, action: PayloadAction<Session>) => {
      const session: Session = action.payload;
      return session;
    }
  }
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;