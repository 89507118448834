import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToCountry,
  createAdapterToCountryEndpoint,
  createAdapterToFieldCountryEndpoint,
} from "../adapters/country.adapter";
import {
  Country,
  CountryEndpoint,
  EndpointCountryFiltered,
} from "../domain/Country.model";
import { appConfig } from "../../../../config";
import { EndpointCountry } from "../domain/Country.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_COUNTRY_URL = "/general/paises";
const BASE_COUNTRY_MUTATION_URL = "/general/pais";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/general/paises/export`;

export const countryApi = createApi({
  reducerPath: "countryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Countries", "Country"],
  endpoints: (builder) => ({
    getCountry: builder.query<Country, number | undefined>({
      query: (id) => `${BASE_COUNTRY_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointCountry) => {
        return createAdapterToCountry(response.Content);
      },
      providesTags: (_res, _err, id) => [{type: "Country", id: id?.toString()}],
    }),
    getCountries: builder.query<any, TableOptions<Country> | undefined>({
      query: (params: TableOptions<Country>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(f, createAdapterToFieldCountryEndpoint)
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldCountryEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_COUNTRY_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["Countries"],
      transformResponse: (
        response: EndpointCountryFiltered,
        _meta,
        params: TableOptions<Country>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (country: CountryEndpoint) => createAdapterToCountry(country)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createCountry: builder.mutation<any, Country>({
      query: (data) => ({
        url: `${BASE_COUNTRY_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToCountryEndpoint(data),
        },
      }),
      invalidatesTags: ["Countries"],
    }),
    updateCountry: builder.mutation<any, Country>({
      query: (data: Country) => ({
        url: `${BASE_COUNTRY_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToCountryEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: Country) => ["Countries", {type: 'Country', id: data?.id?.toString()}],
    }),
    deleteCountry: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_COUNTRY_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Countries"],
    }),
  }),
});

export const {
  useGetCountryQuery,
  useGetCountriesQuery,
  useCreateCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countryApi;
