export interface InputTableData {
  [key: string]: any;
}

export interface OutputTableData {
  name: string;
  value: any;
}

export interface OutputTableTotalsData {
  TotalSaldoProyectado: number;
  TotalSaldoInicial: number;
  TotalIngresosCobros: number;
  TotalEgresosPagos: number;
}

export const createDashboardTableAdapter = (
  data?: InputTableData
): { totals: OutputTableTotalsData; data: OutputTableData[] } => {
  const transformedData: OutputTableData[] = [];

  if (!data) {
    return {
      totals: {
        TotalSaldoProyectado: 0,
        TotalSaldoInicial: 0,
        TotalIngresosCobros: 0,
        TotalEgresosPagos: 0,
      },
      data: [],
    };
  }
  const TotalSaldoProyectado = data.TotalSaldoProyectado;
  const TotalSaldoInicial = data.TotalSaldoInicial;
  const TotalIngresosCobros = data.TotalIngresosCobros;
  const TotalEgresosPagos = data.TotalEgresosPagos;
  delete data["TotalSaldoProyectado"];
  delete data["TotalSaldoInicial"];
  delete data["TotalIngresosCobros"];
  delete data["TotalEgresosPagos"];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      transformedData.push({
        name: key,
        value: {
          ...data[key],
          details: data[key].Detalle
            ? createDashboardTableDetailAdapter(data[key].Detalle)
            : [],
        },
      });
    }
  }

  return {
    totals: {
      TotalSaldoProyectado,
      TotalSaldoInicial,
      TotalIngresosCobros,
      TotalEgresosPagos,
    },
    data: transformedData,
  };
};

const createDashboardTableDetailAdapter = (
  data: InputTableData
): OutputTableData[] => {
  const transformedData: OutputTableData[] = [];

  if (!data) {
    return [];
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      transformedData.push({
        name: key,
        value: data[key],
      });
    }
  }

  return transformedData;
};
