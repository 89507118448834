import { createAdapterToConceptType } from "../../conceptType/adapters/conceptType.adapter";
import { Concept, ConceptEndpoint } from "../domain/Concept.model";

type Dictionary = {
    [key: string]: string;
};

const conceptAttributesDiccionary: Dictionary = {
    id: 'ConceptoID',
    name: 'Concepto',
    typeId: 'TipoConceptoID',
    order :'Orden',
}

export const createAdapterToFieldConceptEndpoint = (key: string): string => {
    return conceptAttributesDiccionary[key];
}

export const createAdapterToConceptEndpoint = (concept: Concept) => {
    const formattedConcept: Partial<ConceptEndpoint> = {
        ConceptoID: concept.id ?? undefined,
        Concepto: concept.name,
        TipoConceptoID: concept.typeId,
        Orden: concept.order,
    };
    return formattedConcept;
}

export const createAdapterToConcept = (conceptEndpoint: ConceptEndpoint) => {
    const formattedConcept: Concept = {
        id: conceptEndpoint.ConceptoID,
        name: conceptEndpoint.Concepto,
        typeId: conceptEndpoint.TipoConceptoID,
        type: conceptEndpoint.TipoConcepto? createAdapterToConceptType(conceptEndpoint.TipoConcepto) : undefined,
        order: conceptEndpoint.Orden,
    };
    return formattedConcept;
}