import { numericalDateToString, stringToNumericalDate } from "../../../../utilities/dateUtils";
import { createAdapterToCurrency } from "../../currency/adapters/currency.adapter";
import { ExchangeRate, ExchangeRateEndpoint } from "../domain/ExchangeRate.model";

type Dictionary = {
    [key: string]: string;
};

const exchangeRateAttributesDiccionary: Dictionary = {
    id: 'FechaID',
    currencyId: 'MonedaID',
    amount: 'TipoCambio',
    currency: 'Moneda',
    
}

export const createAdapterToFieldExchangeRateEndpoint = (key: string): string => {
    return exchangeRateAttributesDiccionary[key];
}

export const createAdapterToExchangeRateEndpoint = (exchangeRate: ExchangeRate) => {
    const formattedExchangeRate: Partial<ExchangeRateEndpoint> = {
        FechaID: stringToNumericalDate(exchangeRate.id),
        MonedaID: exchangeRate.currencyId,
        TipoCambio: exchangeRate.amount,
    };
    return formattedExchangeRate;
}

export const createAdapterToExchangeRate = (exchangeRateEndpoint: ExchangeRateEndpoint) => {
    const formattedExchangeRate: ExchangeRate = {
        id: numericalDateToString(exchangeRateEndpoint.FechaID),
        currencyId: exchangeRateEndpoint.MonedaID,
        currency: exchangeRateEndpoint.Moneda? createAdapterToCurrency(exchangeRateEndpoint.Moneda) : undefined,
        amount: exchangeRateEndpoint.TipoCambio,
    };
    return formattedExchangeRate;
}