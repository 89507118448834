import { BankAccountEndpoint } from "../../../../parametry/financial/bankAccount/domain/BankAccount.model";
import {TableDataProps} from "../../../../shared-kernel/components/Table";
import { OwnChequeEndpoint } from "../../../cheques/ownCheque/domain/OwnCheque.model";
import { ThirdPartyChequeEndpoint } from "../../../cheques/thirdPartyCheque/domain/ThirdPartyCheque.model";

export type ExpensePayment = {
    number?:string;
    paymentType?:PaymentType;
    id?:number,
	dateId:string,
	expenseId:number,
	ownChequeId?:number,
	thirdPartyChequeId?:number,
	bankAccountId?:number,
	amount:number,
};

export type ExpensePaymentEndpoint = {
    PagosID?:number,
	FechaID:number,
	EgresoID:number,
	ChequePropioID?:number,
    ChequePropio:OwnChequeEndpoint
	ChequeTerceroID?:number,
    ChequeTercero:ThirdPartyChequeEndpoint,
	CuentaBancariaID?:number,
    CuentaBancaria:BankAccountEndpoint,
	Importe:number,
}

export enum PaymentType  {
    OwnCheque = 1,
    ThirdPartyCheque = 2,
    BankAccount = 3,
}

export type EndpointExpensePayment = {
    Status: number;
    Content: ExpensePaymentEndpoint
}
export type EndpointExpensePaymentFiltered ={
    total: number;
    page: number;
    rows: ExpensePaymentEndpoint[]
}
export type EndpointExpensePaymentList = {
    Status: number;
    Content: ExpensePaymentEndpoint[]
}

export interface ExpensePaymentDataProps extends TableDataProps<ExpensePayment>{
    data: ExpensePayment[];
    total?: number;
}