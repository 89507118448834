import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import { appConfig } from "../../../../config";
import {
  EndpointProcess,
  EndpointProcessFiltered,
  EndpointProcessList,
  Process,
  ProcessEndpoint,
} from "../domain/Process.model";
import {
  createAdapterToFieldProcessEndpoint,
  createAdapterToProcess,
  createAdapterToProcessEndpoint,
} from "../adapters/process.adapter";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_PROCESS_URL = "/entidadesfinancieras/procesos";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/fechacierre/export`;

export const processesApi = createApi({
  reducerPath: "processesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Processes", "Process", "ProcessesList"],
  endpoints: (builder) => ({
    getProcess: builder.query<Process, string | undefined>({
      query: (id) => `${BASE_PROCESS_URL}/${id}`,
      transformResponse: (response: EndpointProcess) => {
        return createAdapterToProcess(response.Content);
      },
      providesTags: (_res, _err, id) => [
        { type: "Process", id: id?.toString() },
      ],
    }),
    getProcesses: builder.query<any, TableOptions<Process>>({
      query: (params: TableOptions<Process>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(f, createAdapterToFieldProcessEndpoint)
        );
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldProcessEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        return {
          url: `${BASE_PROCESS_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["Processes"],
      transformResponse: (
        response: EndpointProcessFiltered,
        _meta,
        params: TableOptions<Process>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (process: ProcessEndpoint) => createAdapterToProcess(process)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    getProcessesList: builder.query<any, undefined>({
      query: () => {
        return {
          url: `${BASE_PROCESS_URL}`,
        };
      },
      providesTags: ["ProcessesList"],
      transformResponse: (response: EndpointProcessList) => {
        const endpointResponse = response?.Content?.map(
          (process: ProcessEndpoint) => createAdapterToProcess(process)
        );
        return {
          data: endpointResponse,
        };
      },
    }),
    createProcess: builder.mutation<any, Process>({
      query: (data) => ({
        url: `${BASE_PROCESS_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToProcessEndpoint(data),
        },
      }),
      invalidatesTags: ["Processes", "ProcessesList"],
    }),
    updateProcess: builder.mutation<any, Process>({
      query: (data: Process) => ({
        url: `${BASE_PROCESS_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToProcessEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: Process) => [
        "Processes",
        "ProcessesList",
        { type: "Process", id: data?.id?.toString() },
      ],
    }),
    deleteProcess: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_PROCESS_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Processes", "ProcessesList"],
    }),
  }),
});

export const {
  useGetProcessQuery,
  useGetProcessesQuery,
  useGetProcessesListQuery,
  useCreateProcessMutation,
  useDeleteProcessMutation,
} = processesApi;
