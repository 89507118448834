import { InvestmentMovementType, InvestmentMovementTypeEndpoint } from "../domain/InvestmentMovementType.model";

type Dictionary = {
    [key: string]: string;
};

const investmentMovementTypeAttributesDiccionary: Dictionary = {
    id: 'TipoMovimientoInversionID',
    name: 'TipoMovimientoInversion',
}

export const createAdapterToFieldInvestmentMovementTypeEndpoint = (key: string): string => {
    return investmentMovementTypeAttributesDiccionary[key];
}

export const createAdapterToInvestmentMovementTypeEndpoint = (investmentMovementType: InvestmentMovementType) => {
    const formattedInvestmentMovementType: InvestmentMovementTypeEndpoint = {
        TipoMovimientoInversionID: investmentMovementType.id ?? undefined,
        TipoMovimientoInversion: investmentMovementType.name,
    };
    return formattedInvestmentMovementType;
}

export const createAdapterToInvestmentMovementType = (investmentMovementTypeEndpoint: InvestmentMovementTypeEndpoint) => {
    const formattedInvestmentMovementType: InvestmentMovementType = {
        id: investmentMovementTypeEndpoint.TipoMovimientoInversionID,
        name: investmentMovementTypeEndpoint.TipoMovimientoInversion,
    };
    return formattedInvestmentMovementType;
}