import { createAdapterToCurrency } from "../../../general/currency/adapters/currency.adapter";
import { createAdapterToAccountType } from "../../accountType/adapters/accountType.adapter";
import { createAdapterToBank } from "../../banks/adapters/bank.adapter";
import { BankAccount, BankAccountEndpoint } from "../domain/BankAccount.model";

type Dictionary = {
    [key: string]: string;
};

const bankAttributesDiccionary: Dictionary = {
    id: 'CuentaBancariaID',
    bankId: 'BancoID',
    currencyId: 'MonedaID',
    accountTypeId: 'TipoCuentaBancariaID',
    SWIFT :'SWIFT',
    accountNumber:'CuentaNumero',
    accountingAccount:'CuentaContable',
    CBU:'CBU',
    parentAccount:'CuentaMadre',

}

export const createAdapterToFieldBankAccountEndpoint = (key: string): string => {
    return bankAttributesDiccionary[key];
}

export const createAdapterToBankAccountEndpoint = (bankAccount: BankAccount) => {
    const formattedBankAccount: Partial<BankAccountEndpoint> = {
        CuentaBancariaID: bankAccount.id ?? undefined,
        BancoID: bankAccount.bankId,
        MonedaID: bankAccount.currencyId,
        TipoCuentaBancariaID: bankAccount.accountTypeId,
        SWIFT: bankAccount.SWIFT,
        CuentaNumero: bankAccount.accountNumber,
        CuentaContable: bankAccount.accountingAccount,
        CBU: bankAccount.CBU,
        CuentaMadre: bankAccount.parentAccount,
    };
    return formattedBankAccount;
}

export const createAdapterToBankAccount = (bankAccountEndpoint: BankAccountEndpoint) => {
    const formattedBankAccount: BankAccount = {
        id: bankAccountEndpoint.CuentaBancariaID,
        bankId: bankAccountEndpoint.BancoID,
        bank: bankAccountEndpoint.Banco ? createAdapterToBank(bankAccountEndpoint.Banco) : undefined,
        currencyId: bankAccountEndpoint.MonedaID,
        currency: bankAccountEndpoint.Moneda ? createAdapterToCurrency(bankAccountEndpoint.Moneda) : undefined,
        accountTypeId: bankAccountEndpoint.TipoCuentaBancariaID,
        accountType: bankAccountEndpoint.TipoCuentaBancaria ? createAdapterToAccountType(bankAccountEndpoint.TipoCuentaBancaria) : undefined,
        SWIFT: bankAccountEndpoint.SWIFT,
        accountNumber: bankAccountEndpoint.CuentaNumero,
        accountingAccount: bankAccountEndpoint.CuentaContable,
        CBU: bankAccountEndpoint.CBU,
        parentAccount: bankAccountEndpoint.CuentaMadre,
        
    };
    return formattedBankAccount;
}
