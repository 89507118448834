import { lightTheme } from "./schemes/lightTheme";
import { darkTheme } from "./schemes/darkTheme";

export default function themeSelector(theme: string): any {
  switch (theme) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    default:
      return lightTheme;
  }
}
