import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldSupplierEndpoint,
  createAdapterToSupplier,
  createAdapterToSupplierEndpoint
} from "../adapters/supplier.adapter";
import { EndpointSupplierFiltered, Supplier, SupplierEndpoint } from "../domain/Supplier.model";
import { appConfig } from "../../../../config";
import { EndpointSupplier } from "../domain/Supplier.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_SUPPLIER_URL = '/general/proveedores'
const BASE_SUPPLIER_MUTATION_URL = '/general/proveedor'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/general/proveedores/export`;

export const supplierApi = createApi({
  reducerPath: "supplierApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Suppliers", "Supplier"],
  endpoints: (builder) => ({
    getSupplier: builder.query<Supplier, number | undefined>({
      query: (id) => `${BASE_SUPPLIER_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointSupplier) => {
        return createAdapterToSupplier(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: 'Supplier', id: id?.toString()}],
    }),
    getSuppliers: builder.query<any, TableOptions<Supplier>|undefined>({
      query: (params: TableOptions<Supplier>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldSupplierEndpoint));
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldSupplierEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_SUPPLIER_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["Suppliers"],
      transformResponse: (response: EndpointSupplierFiltered, _meta, params: TableOptions<Supplier>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((supplier: SupplierEndpoint) =>
            createAdapterToSupplier(supplier)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    createSupplier: builder.mutation<any, Supplier>({
      query: (data) => ({
        url: `${BASE_SUPPLIER_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToSupplierEndpoint(data)
        },
      }),
      invalidatesTags: ["Suppliers"],
    }),
    updateSupplier: builder.mutation<any, Supplier>({
      query: (data: Supplier) => ({
        url: `${BASE_SUPPLIER_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToSupplierEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: Supplier) => ["Suppliers", {type: 'Supplier', id: data?.id?.toString()}],
    }),
    deleteSupplier: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_SUPPLIER_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Suppliers"],
    }),
  }),
});

export const {
  useGetSupplierQuery,
  useGetSuppliersQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation
} = supplierApi;
