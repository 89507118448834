import styled from "styled-components";

export const StyledFormTableAddFilter = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: inherit;
  padding: 50px 20px 20px 20px;
  overflow-y: auto;
`

export const StyledFormTableFilterInputs = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
`

export const StyledFormTableFilterAddInput = styled.button`
  width: 100%;
  text-align: center;
  padding: 4px 8px;
`

interface StyledFormTableFilterInputWrapperProps {
    width?: string;
}

export const StyledFormTableFilterInputWrapper = styled.div<StyledFormTableFilterInputWrapperProps>`
  display: flex;
  width: ${props => props.width ? props.width : 'auto'};
  align-items: center;
  justify-content: flex-start;
  svg {
    cursor: pointer;
    transition: all 150ms ease-in-out;
    
  }
  svg:hover {
    color: ${props => props.theme.error.main};
    transform: scale(1.1);
  }
`