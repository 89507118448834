import {BankTransaction, BankTransactionEndpoint} from "../domain/BankTransaction.model";

type Dictionary = {
    [key: string]: string;
};

const bankTransactionAttributesDiccionary: Dictionary = {
    id: 'OperacionBancoID',
    name: 'OperacionBanco',
    code: 'CodigoOperacionBanco',
    short: 'OperacionBancoCorto',
    detail: 'OperacionBancoDetalle'
}

export const createAdapterToFieldBankTransactionEndpoint = (key: string): string => {
    return bankTransactionAttributesDiccionary[key];
}

export const createAdapterToBankTransactionEndpoint = (bankTransaction: BankTransaction) => {
    const formattedBankTransaction: BankTransactionEndpoint = {
        OperacionBancoID: bankTransaction.id ?? undefined,
        OperacionBanco: bankTransaction.name,
        CodigoOperacionBanco: bankTransaction.code,
        OperacionBancoCorto: bankTransaction.short,
        OperacionBancoDetalle:bankTransaction.detail,
    };
    return formattedBankTransaction;
}

export const createAdapterToBankTransaction = (bankTransactionEndpoint: BankTransactionEndpoint) => {
    const formattedBankTransaction: BankTransaction = {
        id: bankTransactionEndpoint.OperacionBancoID,
        name: bankTransactionEndpoint.OperacionBanco,
        code: bankTransactionEndpoint.CodigoOperacionBanco,
        short:bankTransactionEndpoint.OperacionBancoCorto,
        detail:bankTransactionEndpoint.OperacionBancoDetalle,
    };
    return formattedBankTransaction;
}