import { createAdapterToCountry } from "../../../general/country/adapters/country.adapter";
import { Bank, BankEndpoint } from "../domain/Bank.model";

type Dictionary = {
    [key: string]: string;
};

const bankAttributesDiccionary: Dictionary = {
    id: 'BancoID',
    name: 'Banco',
    code: 'BancoCorto',
    countryId: 'PaisID',
    BCRA :'CodigoBCRA',
}

export const createAdapterToFieldBankEndpoint = (key: string): string => {
    return bankAttributesDiccionary[key];
}

export const createAdapterToBankEndpoint = (bank: Bank) => {
    const formattedBank: Partial<BankEndpoint> = {
        BancoID: bank.id ?? undefined,
        Banco: bank.name,
        BancoCorto: bank.code,
        PaisID: bank.countryId,
        CodigoBCRA: bank.BCRA,
    };
    return formattedBank;
}

export const createAdapterToBank = (bankEndpoint: BankEndpoint) => {
    const formattedBank: Bank = {
        id: bankEndpoint.BancoID,
        name: bankEndpoint.Banco,
        code: bankEndpoint.BancoCorto,
        countryId: bankEndpoint.PaisID,
        country: bankEndpoint.Pais ? createAdapterToCountry(bankEndpoint.Pais) : undefined,
        BCRA: bankEndpoint.CodigoBCRA,
    };
    return formattedBank;
}