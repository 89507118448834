import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
  OpcionesPredefinidasContenedor,
  StyledDashboardDateFilterOption,
  StyledDashboardDateFilterOptions,
  StyledDashboardDateFilterOptionsTitle,
  StyledDashboardDateFilterWrapper,
} from "./styled-components/StyledDashboardDateFilter";
import { useIntl } from "react-intl";
import translate from "../../../../../language/translate";
import {
  dateToString,
  getDateByDays,
} from "../../../../../utilities/dateUtils";
import { useRef } from "react";

interface DashboardDateFilterProps {
  minDate: Date;
  selectedDate: Date | null;
  setSelectedDate: (date: Date) => void;
  opcPredefinidas?: boolean;
}

export interface DashboardDateFilterOption {
  label: string;
  action: () => void;
}

const DashboardDateFilter = ({
  minDate = new Date(),
  selectedDate,
  setSelectedDate,
  opcPredefinidas = true,
}: DashboardDateFilterProps) => {
  const intl = useIntl();
  const datePickerRef = useRef<DatePicker>(null);
  const TODAY = new Date();
  const DAYS_TOMORROW = 1;
  const DAYS_PER_WEEK = 7;
  const DAYS_THIS_WEEK = DAYS_PER_WEEK - TODAY.getDay();
  const DAYS_NEXT_WEEK = DAYS_PER_WEEK * 2 - TODAY.getDay();
  const END_OF_THE_MONTH_DATE = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth() + 1,
    0
  );

  const options: DashboardDateFilterOption[] = [
    {
      label: `${intl.formatMessage({ id: "today" })} - ${dateToString(TODAY)}`,
      action: () => {
        setSelectedDate(TODAY);
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
    {
      label: `${intl.formatMessage({ id: "tomorroy" })} - ${dateToString(
        getDateByDays(TODAY, DAYS_TOMORROW)
      )}`,
      action: () => {
        setSelectedDate(getDateByDays(TODAY, DAYS_TOMORROW));
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
    {
      label: `${intl.formatMessage({ id: "this week" })} - ${dateToString(
        getDateByDays(TODAY, DAYS_THIS_WEEK)
      )}`,
      action: () => {
        setSelectedDate(getDateByDays(TODAY, DAYS_THIS_WEEK));
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
    {
      label: `${intl.formatMessage({ id: "next week" })} - ${dateToString(
        getDateByDays(TODAY, DAYS_NEXT_WEEK)
      )}`,
      action: () => {
        setSelectedDate(getDateByDays(TODAY, DAYS_NEXT_WEEK));
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
    {
      label: `${intl.formatMessage({
        id: "end of the month",
      })} - ${dateToString(END_OF_THE_MONTH_DATE)}`,
      action: () => {
        setSelectedDate(END_OF_THE_MONTH_DATE);
        if (datePickerRef.current) {
          datePickerRef.current.setOpen(false);
        }
      },
    },
  ];

  const handleChange = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <StyledDashboardDateFilterWrapper>
        <DatePicker
          ref={datePickerRef}
          showIcon
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M19.3 18.9c.4-.7.7-1.5.7-2.4c0-2.5-2-4.5-4.5-4.5S11 14 11 16.5s2 4.5 4.5 4.5c.9 0 1.7-.2 2.4-.7l3.1 3.1l1.4-1.4l-3.1-3.1m-3.8.1c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5M5 19V9h14v2c.8.5 1.5 1.2 2 2V5c0-1.1-.9-2-2-2h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5.8c-.6-.6-1-1.2-1.3-2H5M19 5v2H5V5h14Z"
              />
            </svg>
          }
          selected={selectedDate}
          locale={es}
          minDate={minDate}
          dateFormat="dd/MM/yyyy"
          onChange={handleChange}
          placeholderText={intl.formatMessage({ id: "date to" })}
        >
        <OpcionesPredefinidasContenedor opcPredefinidas={opcPredefinidas}>
          <StyledDashboardDateFilterOptionsTitle>
            {translate("options predefined")}
          </StyledDashboardDateFilterOptionsTitle>
          <StyledDashboardDateFilterOptions opcPredefinidas={opcPredefinidas}>
            {options.map((option: DashboardDateFilterOption, index: number) => (
              <StyledDashboardDateFilterOption
              key={`dashboard_date_filter_option_${index}`}
              onClick={option.action}
              >
                {option.label}
              </StyledDashboardDateFilterOption>
            ))}
          </StyledDashboardDateFilterOptions>
        </OpcionesPredefinidasContenedor>
      </DatePicker>
    </StyledDashboardDateFilterWrapper>
  );
};

export default DashboardDateFilter;
