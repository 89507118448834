import { StyledTableActions } from "../styled-components/StyledTableActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockIcon from '@mui/icons-material/Lock';

import { StyledButtonActions } from "../styled-components/StyledButtonActions";
import { TableActionsProps } from "../domain/table.model";
import TableAction from "./TableAction";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";


interface TableActionsContainerProps<T> {
  data: T;
  actions: TableActionsProps<T>[];
}
function TableActionsContainer<T>({
  data,
  actions,
}: TableActionsContainerProps<T>) {
  const [show, setShow] = useState<boolean>(false);

  const showActions = () => {
    setShow(!show);
  };

  const closeActions = () => {
    setShow(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  

  var filteredActions: TableActionsProps<T>[] = actions.filter((action) => {
    if (action.toValidate) {
      return action.toValidate(data);
    }
    return true;
  });
  
  return (
    <ContenedorIconos ref={ref}>
      {filteredActions.filter((action) => !action.freeAction).length === 1 ? (
        <TableAction
          data={data}
          action={filteredActions.filter((action) => !action.freeAction)[0]}
          onClose={closeActions}
          onlyIcon={true}
        />
      ) : filteredActions.filter((action) => !action.freeAction).length === 0 ? (
        <LockIcon />
      ) : (
        <>
          {filteredActions.map((action, index) =>
              !action.freeAction ? (
                  <TableAction
                  key={`action_table_${index}`}
                  data={data}
                  action={action}
                  onClose={closeActions}
                />
              ) : null
            )}
        </>
      )}
    </ContenedorIconos>
  );
}

export default TableActionsContainer;


const ContenedorIconos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  padding-left: 8px;
`