import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { appConfig } from "../../../../config";
import { IndicatorsParams } from "../domain/Indicator.model";
import { createChartPieAdapter } from "../adapters/pieChartAdapter";
import { createDashboardTableAdapter } from "../adapters/dashboardTableAdapter";

const API_URL = appConfig.API_NODE_URL;
const BASE_URL = "/dashboard";

export const dashboardApi = createApi({
  reducerPath: "DashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + BASE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getDashboardIndicators: builder.query<
      IndicatorsParams,
      { CuentaBancariaId: string | undefined; FechaHastaID: number | null }
    >({
      query: ({ CuentaBancariaId, FechaHastaID }) =>
        CuentaBancariaId !== undefined && CuentaBancariaId !== ""
          ? `/indicators?FechaHastaID=${FechaHastaID}&CuentaBancariaID=${CuentaBancariaId}`
          : `/indicators?FechaHastaID=${FechaHastaID}`,
    }),
    getDashboardPieChartsData: builder.query<
      any,
      { FechaHastaID: number | null; CuentaBancariaId: string | undefined }
    >({
      query: ({ FechaHastaID, CuentaBancariaId }) => {
        let urlQuery = "/piechart";
        if (FechaHastaID) {
          urlQuery += `?FechaHastaID=${FechaHastaID}`;
          if (CuentaBancariaId !== undefined && CuentaBancariaId !== "") {
            urlQuery += `&CuentaBancariaID=${CuentaBancariaId}`;
          }
        }
        if (
          !FechaHastaID &&
          CuentaBancariaId !== undefined &&
          CuentaBancariaId !== ""
        ) {
          urlQuery += `?CuentaBancariaID=${CuentaBancariaId}`;
        }
        return urlQuery;
      },
      transformResponse: (response: any) => {
        return {
          Egresos: createChartPieAdapter(response?.Egresos),
          Ingresos: createChartPieAdapter(response?.Ingresos),
        };
      },
    }),
    getDashboardAreaChartsData: builder.query<any, number | null>({
      query: (FechaHastaID) =>
        FechaHastaID
          ? `/areaforecast?FechaHastaID=${FechaHastaID}`
          : "/areaforecast",
    }),
    getDashboardBarChartsData: builder.query<
      any,
      { FechaHastaID: number | null; CuentaBancariaId: string | undefined }
    >({
      query: ({ FechaHastaID, CuentaBancariaId }) => {
        let urlQuery = "/barchart";
        if (FechaHastaID) {
          urlQuery += `?FechaHastaID=${FechaHastaID}`;
          if (CuentaBancariaId !== undefined && CuentaBancariaId !== "") {
            urlQuery += `&CuentaBancariaID=${CuentaBancariaId}`;
          }
        }
        if (
          !FechaHastaID &&
          CuentaBancariaId !== undefined &&
          CuentaBancariaId !== ""
        ) {
          urlQuery += `?CuentaBancariaID=${CuentaBancariaId}`;
        }
        return urlQuery;
      },
    }),
    getDashboardTableData: builder.query<
      any,
      { FechaHastaID: number | null; CuentaBancariaId: string | undefined }
    >({
      query: ({ FechaHastaID, CuentaBancariaId }) => {
        let urlQuery = "/table";
        if (FechaHastaID) {
          urlQuery += `?FechaHastaID=${FechaHastaID}`;
          if (CuentaBancariaId !== undefined && CuentaBancariaId !== "") {
            urlQuery += `&CuentaBancariaID=${CuentaBancariaId}`;
          }
        }
        if (
          !FechaHastaID &&
          CuentaBancariaId !== undefined &&
          CuentaBancariaId !== ""
        ) {
          urlQuery += `?CuentaBancariaID=${CuentaBancariaId}`;
        }
        return urlQuery;
      },
      transformResponse: (response: any) => {
        return createDashboardTableAdapter(response);
      },
    }),
  }),
});

export const {
  useGetDashboardIndicatorsQuery,
  useGetDashboardPieChartsDataQuery,
  useGetDashboardAreaChartsDataQuery,
  useGetDashboardBarChartsDataQuery,
  useGetDashboardTableDataQuery,
} = dashboardApi;
