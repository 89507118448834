import TableHead from "./components/TableHead";
import { SortColumnProps, TableProps } from "./domain/table.model";
import { StyledTable } from "./styled-components/StyledTable";
import TableBody from "./components/TableBody";
import {
  StyledActionsSection,
  StyledActionsZone,
  StyledTableZone,
  StyledActionsSectionRight,
  TableContainer,
  StyledActionsSectionCenter,
} from "./styled-components/TableContainer";
import TablePagination from "./components/TablePagination";
import TableFilter from "./components/TableFilters/TableFilter";
import TableSearch from "./components/TableSearch/TableSearch";
import {
  StyledTableAddButtonName,
  StyledTableFreeAction,
  StyledTableFreeActions,
} from "./styled-components/StyledTableAdd";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { ColumnFilter, TableColumn, ValueType } from "./domain/columns.model";
import { FilterTag } from "./components/TableFilters/FilterTag";
import useTableColumnSelector, {
  ColumnSelectable,
} from "./tableUtils/columnSelector.hook";
import { useColumnExchange } from "./tableUtils/columnExchange.hook";
import { useEffect, useState } from "react";
import { AddFilter } from "./components/TableFilters/AddFilter";
import styled from "styled-components";

const TableSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 0;
`;

function Table<T>({
  tableName = "mytable",
  columns,
  data,
  parameters,
  onChangeParameters,
  pageSizeOptions = [20, 50, 100],
  actions = [],
  defaultColumns,
  hidePagination,
}: TableProps<T>) {
  const [openFilter, setOpenFilter] = useState(false);

  const { data: rows, total } = data ?? { data: [], total: 0 };
  const freeActions = actions?.filter((act) => act.freeAction);
  const initialColumns: ColumnSelectable[] = columns.map((col) => ({
    field: col.field,
    label: col.label,
  }));
  const { selectedColumns, addColumn, removeColumn } = useTableColumnSelector(
    tableName,
    initialColumns,
    defaultColumns
  );
  const { columnsOrder, exchangeColumns } = useColumnExchange(
    tableName,
    columns.map((col) => col.field),
    initialColumns
  );

  const haveFilters =
    columns.filter((col) => col.filterOperators !== undefined).length > 0;

  const handlePageChange = (page: number) => {
    onChangeParameters({
      ...parameters,
      page,
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    onChangeParameters({
      ...parameters,
      page: 1,
      pageSize,
    });
  };

  const handleSortColumnChange = (newSort: SortColumnProps) => {
    onChangeParameters({
      ...parameters,
      order: newSort,
    });
  };

  const handleAddFilter = (filter: ColumnFilter<ValueType>) => {
    onChangeParameters({
      ...parameters,
      page: 1,
      filters: parameters?.filters
        ? [...parameters?.filters, filter]
        : [filter],
    });
  };

  const handleRemoveFilter = (filterToRemove: ColumnFilter<ValueType>) => {
    const newFilters = parameters?.filters?.filter(
      (filter) => filter !== filterToRemove
    );
    onChangeParameters({
      ...parameters,
      page: 1,
      filters: newFilters ? [...newFilters] : [],
    });
  };

  const handleChangeSearch = (newSearch: string) => {
    onChangeParameters({
      ...parameters,
      search: newSearch,
    });
  };

  useEffect(() => {
    onChangeParameters({
      ...parameters,
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(parameters.filters), parameters.search]);

  return (
    <TableSection>
      <TableContainer>
        {parameters?.search || freeActions?.length > 0 || haveFilters ? (
          <StyledActionsZone>
            <StyledActionsSection>
              {freeActions?.length > 0 ? (
                <StyledTableFreeActions>
                  {freeActions?.map((action, index) => {
                    return (
                      <StyledTableFreeAction
                        type="button"
                        onClick={() => action.toExecute()}
                        key={`table_free_action_${index}`}
                      >
                        {action.icon ? action.icon : <AddCircleOutlineRoundedIcon />}
                        <StyledTableAddButtonName>
                          {action.name}
                        </StyledTableAddButtonName>
                      </StyledTableFreeAction>
                    );
                  })}
                </StyledTableFreeActions>
              ) : null}
            </StyledActionsSection>
            <StyledActionsSectionCenter>
              {parameters?.filters?.map((filter, index) => {
                return (
                  <FilterTag<T>
                    key={`filtertag_${index}`}
                    filter={filter}
                    columns={columns}
                    onRemove={handleRemoveFilter}
                  />
                );
              })}
            </StyledActionsSectionCenter>
            <StyledActionsSectionRight>
              {parameters?.searchableColumns &&
              parameters?.searchableColumns?.length > 0 ? (
                <TableSearch<T>
                  search={parameters.search}
                  onChangeSearch={handleChangeSearch}
                  haveFilters={haveFilters}
                  columns={parameters.searchableColumns}
                  tooltipLabels={parameters.searchableTooltipLabels}
                />
              ) : null}
              {haveFilters ? (
                <TableFilter
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                />
              ) : null}
            </StyledActionsSectionRight>
          </StyledActionsZone>
        ) : null}

        <StyledTableZone>
          <StyledTable>
            <TableHead
              tableName={tableName}
              columns={columns}
              sortColumn={parameters.order}
              onShortColumnChange={handleSortColumnChange}
              initialColumns={initialColumns}
              selectedColumns={selectedColumns}
              addColumn={addColumn}
              removeColumn={removeColumn}
              columnsOrder={columnsOrder}
              onExchangeColumns={exchangeColumns}
            />
            <TableBody<T>
              columns={columns}
              rows={rows}
              actions={actions}
              selectedColumns={selectedColumns}
              columnsOrder={columnsOrder}
            />
          </StyledTable>
        </StyledTableZone>
        <TablePagination
          show={!hidePagination}
          currentPage={parameters.page}
          pageSize={parameters.pageSize}
          total={total}
          pageOptions={pageSizeOptions}
          onPerPageChange={handlePageSizeChange}
          onPageChange={handlePageChange}
        />
      </TableContainer>
      <AddFilter
        show={openFilter}
        columns={columns.filter(
          (column: TableColumn<T>) => !!column.filterOperators
        )}
        filters={parameters.filters}
        onApply={(data: ColumnFilter<ValueType>) => {
          handleAddFilter(data);
          setOpenFilter(!openFilter);
        }}
        onCancel={() => {
          setOpenFilter(!openFilter);
        }}
      />
    </TableSection>
  );
}

export default Table;
