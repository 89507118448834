import {OrdersColumn, SortColumnProps, TableOptions} from "../domain/table.model";

export function getPage<T>(data: T[], currentPage: number, pageSize: number, order: SortColumnProps | undefined): T[] {
    if (order) {
        data = data.slice().sort((a:any, b:any) => {
            const aValue = a[order.field];
            const bValue = b[order.field];

            if (order.order === OrdersColumn.ASC) {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
        });
    }

    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return data.slice(start, end);
}

export function manageLocalPagination<T>(items: T[], params: TableOptions<T> | undefined) {
    let page = params?.page ?? 1;
    const pageSize = params?.pageSize ?? 20;
    const orderField: SortColumnProps | undefined = params?.order;

    return getPage<T>(items, page, pageSize, orderField);
}