import React from "react";
import { ThemeProvider } from "styled-components";
import themeSelector from "../themeSelector";
import { useAppSelector } from "../../utilities/hooks/redux.hooks";

interface Props {
  children?: React.ReactNode;
}

const ThemeProviderWrapper: React.FC<Props> = ({ children }) => {
  const { selectedTheme } = useAppSelector((state) => state.theme);
  const theme = themeSelector(selectedTheme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
