import { Navigate } from "react-router-dom";
import { FC, ReactNode } from "react";
import { useAppSelector } from "../../../../../utilities/hooks/redux.hooks";
import {appConfig} from "../../../../../config";

interface ProtectedProps {
  children?: ReactNode;
}

const Protected: FC<ProtectedProps> = ({ children }) => {
  const session = useAppSelector((state) => state.session);


  return !session?.token ? (
    <Navigate to={appConfig.DEFAULT_LOGIN_PAGE}  />
  ) : (
    <>{children}</>
  );
};

export default Protected;
