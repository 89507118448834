import axios from 'axios';
import {appConfig} from "../config";

export const AxiosInterceptor = () => {

  const updateHeader = (request: any) => {
    // const token = localStorage.getItem(appConfig.APP_TOKEN_NAME);
    // request.headers = {
    //   ...request.headers,
    //   Authorization: `Bearer ${token}`
    // };
    return request;
  };

  axios.interceptors.request.use((request: any) => {
    // if (request.url?.includes('assets')) return request;
    return updateHeader(request);
  });
};