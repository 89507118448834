import { StyledAction } from "../styled-components/StyledTableActions";
import { TableActionsProps } from "../domain/table.model";
import translate from "../../../../language/translate";


interface TableActionProps<T> {
  data: T;
  action: TableActionsProps<T>;
  onClose: () => void;
  onlyIcon?: boolean;
}
function TableAction<T>({
  data,
  action,
  onClose,
  onlyIcon,
}: TableActionProps<T>) {
  const { toExecute, name, color } = action;
  const handleAction = () => {
    toExecute(data);
    onClose();
  };
  const actionColor = color ? color(data) : "#1f690b";
  return (
      <StyledAction color={actionColor} onClick={handleAction}>
        {onlyIcon && action.icon ? action.icon : <>{action.icon}</>}
      </StyledAction>
  );
}

export default TableAction;


