import { numericalDateToString, stringToNumericalDate } from "../../../../utilities/dateUtils";
import { ExpensePayment, ExpensePaymentEndpoint, PaymentType } from "../domain/ExpensePayment.model";

type Dictionary = {
    [key: string]: string;
};

const expensePaymentAttributesDiccionary: Dictionary = {
    id:'PagosID',
	dateId:'FechaID',
	expenseId:'EgresoID',
	ownChequeId:'ChequePropioID',
	thirdPartyChequeId:'ChequeTerceroID',
	bankAccountId:'CuentaID',
	amount:'Importe'
}

export const createAdapterToFieldExpensePaymentEndpoint = (key: string): string => {
    return expensePaymentAttributesDiccionary[key];
}

export const createAdapterToExpensePaymentEndpoint = (expensePayment: ExpensePayment) => {
    const formattedExpensePayment: Partial<ExpensePaymentEndpoint> = {
        PagosID: expensePayment.id?? undefined,
        FechaID: stringToNumericalDate(expensePayment.dateId),
        EgresoID: expensePayment.expenseId,
        ChequePropioID: expensePayment.ownChequeId?? undefined,
        ChequeTerceroID: expensePayment.thirdPartyChequeId?? undefined,
        CuentaBancariaID: expensePayment.bankAccountId?? undefined,
        Importe: expensePayment.amount,
    };
    return formattedExpensePayment;
}

export const createAdapterToExpensePayment = (expensePaymentEndpoint: ExpensePaymentEndpoint) => {
    const formattedExpensePayment: ExpensePayment = {
        paymentType:paymentTypeChecker(expensePaymentEndpoint),
        number:numberFinder(expensePaymentEndpoint),
        id: expensePaymentEndpoint.PagosID,
        dateId: numericalDateToString(expensePaymentEndpoint.FechaID? expensePaymentEndpoint.FechaID : 0),
        expenseId: expensePaymentEndpoint.EgresoID,
        ownChequeId: expensePaymentEndpoint.ChequePropioID?? undefined,
        thirdPartyChequeId: expensePaymentEndpoint.ChequeTerceroID?? undefined,
        bankAccountId: expensePaymentEndpoint.CuentaBancariaID?? undefined,
        amount: expensePaymentEndpoint.Importe,
    };
    return formattedExpensePayment;
}

const numberFinder = (obj: ExpensePaymentEndpoint) => {
    if (obj.CuentaBancariaID !== null) {
        return obj.CuentaBancaria.CuentaNumero
    } else if (obj.ChequePropioID !== null) {
        return obj.ChequePropio.Numero
    } else {
        return obj.ChequeTercero.Numero
    }
  };

const paymentTypeChecker = (obj:ExpensePaymentEndpoint) => {
    if (obj.CuentaBancariaID !== null) { 
        return PaymentType.BankAccount 
    } else if (obj.ChequePropioID !== null) {
        return PaymentType.OwnCheque
    } else  {
        return PaymentType.ThirdPartyCheque
    }
}
