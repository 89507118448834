function validToken(token: string) {
    return token.split(".").length === 3;
}


export function decodeToken(token: string) {
    try {
        if(!validToken(token)){
            return null;
        }        
        const payload = token.split(".")[1];
        // determine the padding characters required for the base64 string
        const padding = "=".repeat((4 - (payload.length % 4)) % 4);
        // convert the base64url string to a base64 string
        const base64 =
        payload.replace("-", "+").replace("_", "/") + padding;
        // decode and parse to json
        return JSON.parse(atob(base64));
    } catch (error) {
        // Return null if something goes wrong
        return null;
    }
}

export function isTokenExpired(token: string | undefined) {
    let expired = true;
    if(token === undefined){
        return expired;
    }
    const decodedToken = decodeToken(token);
    if (decodedToken && decodedToken.exp) {
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp);
        expired = expirationDate.valueOf() < new Date().valueOf();
    }

    return expired;
}