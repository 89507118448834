import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToCustomer,
  createAdapterToCustomerEndpoint,
  createAdapterToFieldCustomerEndpoint,
} from "../adapters/customer.adapter";
import {
  Customer,
  CustomerEndpoint,
  EndpointCustomerFiltered,
} from "../domain/Customer.model";
import { appConfig } from "../../../../config";
import { EndpointCustomer } from "../domain/Customer.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_CUSTOMER_URL = "/general/clientes";
const BASE_CUSTOMER_MUTATION_URL = "/general/cliente";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/general/clientes/export`;

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Customers", "Customer"],
  endpoints: (builder) => ({
    getCustomer: builder.query<Customer, number | undefined>({
      query: (id) => `${BASE_CUSTOMER_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointCustomer) => {
        return createAdapterToCustomer(response.Content);
      },
      providesTags: (_res, _err, id) => [{type: "Customer", id: id?.toString()}],
    }),
    getCustomers: builder.query<any, TableOptions<Customer> | undefined>({
      query: (params: TableOptions<Customer>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(f, createAdapterToFieldCustomerEndpoint)
        );
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldCustomerEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        return {
          url: `${BASE_CUSTOMER_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["Customers"],
      transformResponse: (
        response: EndpointCustomerFiltered,
        _meta,
        params: TableOptions<Customer>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (customer: CustomerEndpoint) => createAdapterToCustomer(customer)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),

    createCustomer: builder.mutation<any, Customer>({
      query: (data) => ({
        url: `${BASE_CUSTOMER_MUTATION_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToCustomerEndpoint(data),
        },
      }),
      invalidatesTags: ["Customers"],
    }),
    updateCustomer: builder.mutation<any, Customer>({
      query: (data: Customer) => ({
        url: `${BASE_CUSTOMER_MUTATION_URL}`,
        method: "PUT",
        body: {
          Content: createAdapterToCustomerEndpoint(data),
        },
      }),
      invalidatesTags: (_res, _err, data: Customer) => ["Customers", {type: 'Customer', id: data?.id?.toString()}],
    }),
    deleteCustomer: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_CUSTOMER_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Customers"],
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customerApi;
