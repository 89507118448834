import {numericalDateToString, stringToNumericalDate} from "../../../../utilities/dateUtils";
import {StatementBalance, StatementBalanceEndpoint} from "../domain/StatementBalance.model";

type Dictionary = {
    [key: string]: string;
};

const statementBalanceAttributesDiccionary: Dictionary = {
    id: 'ExtractoBancarioSaldoID',
    dateId: 'FechaID',
    hourId: 'HoraID',
    bankId: 'BancoID',
    bankAccountId: 'CuentaBancariaID',
    balance: 'Saldo',

}

export const createAdapterToFieldStatementBalanceEndpoint = (key: string): string => {
    return statementBalanceAttributesDiccionary[key];
}

export const createAdapterToStatementBalanceEndpoint = (statementBalance: StatementBalance) => {
    const formattedDate = stringToNumericalDate(statementBalance.dateId)
    const formattedHour = parseInt(statementBalance.hourId.replace(/:/g, ''))
    const formattedStatementBalance: StatementBalanceEndpoint = {
        ExtractoBancarioSaldoID: statementBalance.id ?? undefined,
        FechaID: formattedDate,
        HoraID: formattedHour,
        BancoID: statementBalance.bankId,
        CuentaBancariaID: statementBalance.bankAccountId,
        Saldo: statementBalance.balance,
    };
    return formattedStatementBalance;
}

export const createAdapterToStatementBalance = (statementBalanceEndpoint: StatementBalanceEndpoint) => {
    const formattedDate = numericalDateToString(statementBalanceEndpoint.FechaID)
    const formattedHour = statementBalanceEndpoint.HoraID.toString()
    const formattedStatementBalance: StatementBalance = {
        id: statementBalanceEndpoint.ExtractoBancarioSaldoID,
        dateId: formattedDate,
        hourId: formattedHour,
        bankId: statementBalanceEndpoint.BancoID,
        bankAccountId: statementBalanceEndpoint.CuentaBancariaID,
        balance: statementBalanceEndpoint.Saldo,
    };
    return formattedStatementBalance;
}


export function convertNumberToTime(input:string):string {
    const hours = input.slice(0, 2);
    const minutes = input.slice(2, 4);
    const seconds = input.slice(4);
    return `${hours}:${minutes}:${seconds}`;
  }