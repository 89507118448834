import { createAdapterToState } from "../../../../parametry/estimates/state/adapters/state.adapter";
import { createAdapterToBankAccount } from "../../../../parametry/financial/bankAccount/adapters/bankAccount.adapter";
import { createAdapterToConcept } from "../../../../parametry/financial/concept/adapters/concept.adapter";
import { createAdapterToInvestmentInstrument } from "../../../../parametry/financial/investmentInstrument/adapters/investmentInstrument.adapter";
import { createAdapterToInvestmentMovementType } from "../../../../parametry/financial/investmentMovementType/adapters/investmentMovementType.adapter";
import { createAdapterToInvestmentPortfolio } from "../../../../parametry/financial/investmentPortfolio/adapters/investmentPortfolio.adapter";
import { createAdapterToCurrency } from "../../../../parametry/general/currency/adapters/currency.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  FinancialInvestment,
  FinancialInvestmentEndpoint,
} from "../domain/FinancialInvestment.model";

type Dictionary = {
  [key: string]: string;
};

const financialInvestmentAttributesDiccionary: Dictionary = {
  id: "InversionFinancieraID",
  firstEstDate: "FechaPrimeraEstimacionID",
  lastEstDate: "FechaUltimaEstimacionID",
  currencyOpId: "MonedaOperacionID",
  exchangeRateOp: "TipoCambioOperacion",
  conceptId: "ConceptoID",
  stateId: "EstadoID",
  investmentPortfolioId: "CarteraInversionID",
  investmentMovementTypeId: "TipoMovimientoInversionID",
  investmentInstrumentId: "InstrumentoInversionID",
  currencyId: "MonedaID",
  bankAccountId: "CuentaBancariaID",
  firstEstAmount: "ImportePrimeraEstimacion",
  lastEstAmount: "ImporteUltimaEstimacion",
};

export const createAdapterToFieldFinancialInvestmentEndpoint = (
  key: string
): string => {
  return financialInvestmentAttributesDiccionary[key];
};

export const createAdapterToFinancialInvestmentEndpoint = (
  financialInvestment: FinancialInvestment
) => {
  const formattedFinancialInvestment: Partial<FinancialInvestmentEndpoint> = {
    InversionFinancieraID: financialInvestment.id ?? undefined,
    FechaPrimeraEstimacionID: financialInvestment.firstEstDate
      ? stringToNumericalDate(financialInvestment.firstEstDate)
      : undefined,
    FechaUltimaEstimacionID: financialInvestment?.lastEstDate
      ? stringToNumericalDate(financialInvestment.lastEstDate)
      : undefined,
    MonedaOperacionID: financialInvestment.currencyOpId,
    TipoCambioOperacion: financialInvestment.exchangeRateOp,
    ConceptoID: financialInvestment.conceptId,
    CarteraInversionID: financialInvestment.investmentPortfolioId,
    EstadoID:
      parseInt(financialInvestment.stateId?.toString() ?? "-1") === -1
        ? undefined
        : financialInvestment.stateId,
    TipoMovimientoInversionID: financialInvestment.investmentMovementTypeId,
    InstrumentoInversionID: financialInvestment.investmentInstrumentId,
    CuentaBancariaID: financialInvestment.bankAccountId,
    ImportePrimeraEstimacion: financialInvestment.firstEstAmount
      ? financialInvestment.firstEstAmount
      : 0,
    ImporteUltimaEstimacion: financialInvestment.lastEstAmount
      ? financialInvestment.lastEstAmount
      : 0,
  };
  return formattedFinancialInvestment;
};

export const createAdapterToFinancialInvestment = (
  financialInvestmentEndpoint: FinancialInvestmentEndpoint
) => {
  const formattedFinancialInvestment: FinancialInvestment = {
    id: financialInvestmentEndpoint.InversionFinancieraID,
    firstEstDate: financialInvestmentEndpoint.FechaPrimeraEstimacionID
      ? numericalDateToString(
          financialInvestmentEndpoint.FechaPrimeraEstimacionID
        )
      : undefined,
    lastEstDate: financialInvestmentEndpoint.FechaUltimaEstimacionID
      ? numericalDateToString(
          financialInvestmentEndpoint.FechaUltimaEstimacionID
        )
      : undefined,
    currencyOpId: financialInvestmentEndpoint.MonedaOperacionID,
    currencyOp: financialInvestmentEndpoint.MonedaOperacion
      ? createAdapterToCurrency(financialInvestmentEndpoint.MonedaOperacion)
      : undefined,
    exchangeRateOp: financialInvestmentEndpoint.TipoCambioOperacion,
    conceptId: financialInvestmentEndpoint.ConceptoID,
    concept: financialInvestmentEndpoint.Concepto
      ? createAdapterToConcept(financialInvestmentEndpoint.Concepto)
      : undefined,
    investmentPortfolioId: financialInvestmentEndpoint.CarteraInversionID,
    investmentPortfolio: financialInvestmentEndpoint.CarteraInversion
      ? createAdapterToInvestmentPortfolio(
          financialInvestmentEndpoint.CarteraInversion
        )
      : undefined,
    stateId: financialInvestmentEndpoint.EstadoID,
    state: financialInvestmentEndpoint.Estado
      ? createAdapterToState(financialInvestmentEndpoint.Estado)
      : undefined,
    investmentMovementTypeId:
      financialInvestmentEndpoint.TipoMovimientoInversionID,
    investmentMovementType: financialInvestmentEndpoint.TipoMovimientoInversion
      ? createAdapterToInvestmentMovementType(
          financialInvestmentEndpoint.TipoMovimientoInversion
        )
      : undefined,
    investmentInstrumentId: financialInvestmentEndpoint.InstrumentoInversionID,
    investmentInstrument: financialInvestmentEndpoint.InstrumentoInversion
      ? createAdapterToInvestmentInstrument(
          financialInvestmentEndpoint.InstrumentoInversion
        )
      : undefined,
    bankAccountId: financialInvestmentEndpoint.CuentaBancariaID,
    bankAccount: financialInvestmentEndpoint.CuentaBancaria
      ? createAdapterToBankAccount(financialInvestmentEndpoint.CuentaBancaria)
      : undefined,
    firstEstAmount: financialInvestmentEndpoint.ImportePrimeraEstimacion,
    lastEstAmount:
      financialInvestmentEndpoint.ImporteUltimaEstimacion === 0
        ? undefined
        : financialInvestmentEndpoint.ImporteUltimaEstimacion,
  };
  return formattedFinancialInvestment;
};
