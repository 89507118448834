import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "./user/services/userApi";
import { authApi } from "./shared-kernel/components/auth/services/authApi";
import { authSlice } from "./shared-kernel/components/auth/presentation/state/auth.reducer";
import { themeSlice } from "./theme/presentation/state/theme.reducer";
import { languageSlice } from "./language/presentation/state/language.reducer";
import { timezoneSlice } from "./timezone/state/timezone.reducer";
import { customerApi } from "./parametry/general/customer/services/customerApi";
import { countryApi } from "./parametry/general/country/services/countryApi";
import { menuApi } from "./shared-kernel/components/menu/services/menuApi";
import { notificationSlice } from "./shared-kernel/components/NotificationBar/state/notification.reducer";
import { accountTypeApi } from "./parametry/financial/accountType/services/accountTypeApi";
import { bankTransactionTypeApi } from "./parametry/financial/bankTransactionType/services/bankTransactionTypeApi";
import { currencyApi } from "./parametry/general/currency/services/currencyApi";
import { supplierApi } from "./parametry/general/supplier/services/supplierApi";
import { stateApi } from "./parametry/estimates/state/services/stateApi";
import { contactApi } from "./parametry/general/contact/services/contactApi";
import { investmentTypeApi } from "./parametry/financial/investmentType/services/investmentTypeAPi";
import { areaApi } from "./parametry/general/area/services/areaApi";
import { paymentMethodApi } from "./parametry/estimates/paymentMethod/services/paymentMethodApi";
import { investmentMovementTypeApi } from "./parametry/financial/investmentMovementType/services/investmentMovementTypeApi";
import { bankApi } from "./parametry/financial/banks/services/bankApi";
import { conceptTypeApi } from "./parametry/financial/conceptType/services/conceptTypeApi";
import { conceptApi } from "./parametry/financial/concept/services/conceptApi";
import { exchangeRateApi } from "./parametry/general/exchangeRate/services/exchangeRateApi";
import { bankTransactionApi } from "./parametry/financial/bankTransaction/services/bankTransactionApi";
import { investmentPortfolioApi } from "./parametry/financial/investmentPortfolio/services/investmentPortfolioApi";
import { investmentInstrumentApi } from "./parametry/financial/investmentInstrument/services/investmentInstrumentApi";
import { bankTransactionClassificationApi } from "./parametry/financial/bankTransactionClassification/services/bankTransactionClassificationApi";
import { statementBalanceApi } from "./transactions/financial/statementBalance/services/statementBalanceApi";
import { bankAccountApi } from "./parametry/financial/bankAccount/services/bankAccountApi";
import { monthClosureApi } from "./transactions/estimates/monthClosure/services/monthClosureApi";
import { locationApi } from "./transactions/locations/services/locationApi";
import { cashFlowApi } from "./transactions/estimates/cashFlow/services/cashFlowApi";
import { cashFlowDetailsApi } from "./transactions/estimates/cashFlowDetails/services/CashFlowDetailsApi";
import { statementMovementApi } from "./transactions/financial/statementMovement/services/statementMovementApi";
import { statementMovementImportApi } from "./transactions/financial/statementMovement/services/statementMovementImportApi";
import { incomeApi } from "./transactions/estimates/income/services/incomeApi";
import { financialInvestmentApi } from "./transactions/estimates/financialInvestment/services/financialInvestmentApi";
import { expenseApi } from "./transactions/estimates/expense/services/expenseApi";
import { menuStatusSlice } from "./shared-kernel/components/Layouts/TopbarLayout/state/menuStatusSlice.reducer";
import { headquartersApi } from "./parametry/general/headquarters/services/headquartersApi";
import { chequeStateApi } from "./parametry/general/chequeState/services/chequeStateApi";
import { chequeTypeApi } from "./parametry/general/chequeType/services/chequeTypeApi";
import { ownChequeApi } from "./transactions/cheques/ownCheque/services/ownChequeApi";
import { permissionApi } from "./permission/services/permissionApi";
import { roleApi } from "./role/services/roleApi";
import { thirdPartyChequeApi } from "./transactions/cheques/thirdPartyCheque/services/thirdPartyChequeApi";
import { dashboardApi } from "./shared-kernel/components/Dashboard/services/dashboardApi";
import { incomeCollectionApi } from "./transactions/estimates/income/services/incomeCollectionApi";
import { expensePaymentApi } from "./transactions/estimates/expense/services/expensePaymentApi";
import { bankReconciliationsApi } from "./bankReconciliation/services/bankReconciliationsApi";
import { activeDirectorySlice } from "./shared-kernel/components/auth/presentation/state/activeDirectory.reducer";
import { processesApi } from "./parametry/general/process/services/processApi";

export const store = configureStore({
  reducer: {
    [menuApi.reducerPath]: menuApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [supplierApi.reducerPath]: supplierApi.reducer,
    [accountTypeApi.reducerPath]: accountTypeApi.reducer,
    [bankTransactionTypeApi.reducerPath]: bankTransactionTypeApi.reducer,
    [stateApi.reducerPath]: stateApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [investmentTypeApi.reducerPath]: investmentTypeApi.reducer,
    [areaApi.reducerPath]: areaApi.reducer,
    [exchangeRateApi.reducerPath]: exchangeRateApi.reducer,
    [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
    [investmentMovementTypeApi.reducerPath]: investmentMovementTypeApi.reducer,
    [bankApi.reducerPath]: bankApi.reducer,
    [conceptTypeApi.reducerPath]: conceptTypeApi.reducer,
    [conceptApi.reducerPath]: conceptApi.reducer,
    [bankTransactionApi.reducerPath]: bankTransactionApi.reducer,
    [investmentPortfolioApi.reducerPath]: investmentPortfolioApi.reducer,
    [investmentInstrumentApi.reducerPath]: investmentInstrumentApi.reducer,
    [bankTransactionClassificationApi.reducerPath]:
      bankTransactionClassificationApi.reducer,
    [bankAccountApi.reducerPath]: bankAccountApi.reducer,
    [statementBalanceApi.reducerPath]: statementBalanceApi.reducer,
    [monthClosureApi.reducerPath]: monthClosureApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [cashFlowApi.reducerPath]: cashFlowApi.reducer,
    [cashFlowDetailsApi.reducerPath]: cashFlowDetailsApi.reducer,
    [statementMovementApi.reducerPath]: statementMovementApi.reducer,
    [statementMovementImportApi.reducerPath]:
      statementMovementImportApi.reducer,
    [incomeApi.reducerPath]: incomeApi.reducer,
    [financialInvestmentApi.reducerPath]: financialInvestmentApi.reducer,
    [expenseApi.reducerPath]: expenseApi.reducer,
    [headquartersApi.reducerPath]: headquartersApi.reducer,
    [chequeStateApi.reducerPath]: chequeStateApi.reducer,
    [chequeTypeApi.reducerPath]: chequeTypeApi.reducer,
    [ownChequeApi.reducerPath]: ownChequeApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [thirdPartyChequeApi.reducerPath]: thirdPartyChequeApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [incomeCollectionApi.reducerPath]: incomeCollectionApi.reducer,
    [expensePaymentApi.reducerPath]: expensePaymentApi.reducer,
    [bankReconciliationsApi.reducerPath]: bankReconciliationsApi.reducer,
    [processesApi.reducerPath]: processesApi.reducer,
    session: authSlice.reducer,
    theme: themeSlice.reducer,
    timezone: timezoneSlice.reducer,
    language: languageSlice.reducer,
    activeDirectory: activeDirectorySlice.reducer,
    notification: notificationSlice.reducer,
    menuStatus: menuStatusSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      menuApi.middleware,
      userApi.middleware,
      authApi.middleware,
      countryApi.middleware,
      customerApi.middleware,
      bankTransactionTypeApi.middleware,
      accountTypeApi.middleware,
      currencyApi.middleware,
      supplierApi.middleware,
      stateApi.middleware,
      areaApi.middleware,
      investmentTypeApi.middleware,
      contactApi.middleware,
      bankApi.middleware,
      investmentMovementTypeApi.middleware,
      paymentMethodApi.middleware,
      conceptTypeApi.middleware,
      conceptApi.middleware,
      bankTransactionApi.middleware,
      investmentPortfolioApi.middleware,
      investmentInstrumentApi.middleware,
      bankTransactionClassificationApi.middleware,
      bankAccountApi.middleware,
      statementBalanceApi.middleware,
      monthClosureApi.middleware,
      locationApi.middleware,
      cashFlowApi.middleware,
      cashFlowDetailsApi.middleware,
      statementMovementApi.middleware,
      statementMovementImportApi.middleware,
      incomeApi.middleware,
      exchangeRateApi.middleware,
      financialInvestmentApi.middleware,
      expenseApi.middleware,
      headquartersApi.middleware,
      chequeStateApi.middleware,
      chequeTypeApi.middleware,
      ownChequeApi.middleware,
      permissionApi.middleware,
      roleApi.middleware,
      thirdPartyChequeApi.middleware,
      permissionApi.middleware,
      roleApi.middleware,
      dashboardApi.middleware,
      incomeCollectionApi.middleware,
      expensePaymentApi.middleware,
      bankReconciliationsApi.middleware,
      processesApi.middleware,
    ]),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
