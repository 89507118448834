import { AccountType, AccountTypeEndpoint } from "../domain/accountType.model";

type Dictionary = {
    [key: string]: string;
};

const accountTypeAttributesDiccionary: Dictionary = {
    id: 'TipoCuentaBancariaID',
    name: 'TipoCuentaBancaria',
}

export const createAdapterToFieldAccountTypeEndpoint = (key: string): string => {
    return accountTypeAttributesDiccionary[key];
}

export const createAdapterToAccountTypeEndpoint = (currency: AccountType) => {
    const formattedAccountType: AccountTypeEndpoint = {
        TipoCuentaBancariaID: currency.id ?? undefined,
        TipoCuentaBancaria: currency.name,
    };
    return formattedAccountType;
}

export const createAdapterToAccountType = (accountTypeEndpoint: AccountTypeEndpoint) => {
    const formattedAccountType: AccountType = {
        id: accountTypeEndpoint.TipoCuentaBancariaID,
        name: accountTypeEndpoint.TipoCuentaBancaria,
    };
    return formattedAccountType;
}