import styled from "styled-components";

export const StyledNonResourceMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledNonResourceMenuText = styled.p`
  font-size: 1.25rem;
  margin: 10px 0;
  padding: 0 10px;
`

export const StyledNonResourceMenuError = styled.p`
  font-size: 1.25rem;
  color: ${props => props.theme.error.main};
`