import { createAdapterToBankTransaction } from "../../bankTransaction/adapters/bankTransaction.adapter";
import { createAdapterToBankTransactionType } from "../../bankTransactionType/adapters/bankTransactionType.adapter";
import { createAdapterToConcept } from "../../concept/adapters/concept.adapter";
import { BankTransactionClassification, BankTransactionClassificationEndpoint } from "../domain/BankTransactionClassification.model";

type Dictionary = {
    [key: string]: string;
};

const bankTransactionClassificationAttributesDiccionary: Dictionary = {
    id: 'ClasificacionMovimientoBancarioID',
    name: 'ClasificacionMovimientoBancario',
    bankTransactionTypeId: 'TipoMovimientoBancarioID',
    transactionCode: 'CodigoOperacion',
    bankTransactionId: 'OperacionBancoID',
    conceptId: 'ConceptoID',
    p1Code: 'CodigoP1',
    bankTransactionClassificationDetailed: 'ClasificacionMovimientoBancarioDetallada',
}

export const createAdapterToFieldBankTransactionClassificationEndpoint = (key: string): string => {
    return bankTransactionClassificationAttributesDiccionary[key];
}

export const createAdapterToBankTransactionClassificationEndpoint = (bankTransactionClassification: BankTransactionClassification) => {
    const formattedBankTransactionClassification: Partial<BankTransactionClassificationEndpoint> = {
        ClasificacionMovimientoBancarioID: bankTransactionClassification.id ?? undefined,
        ClasificacionMovimientoBancario: bankTransactionClassification.name,
        TipoMovimientoBancarioID: bankTransactionClassification.bankTransactionTypeId,
        CodigoOperacion: bankTransactionClassification.transactionCode,
        OperacionBancoID: bankTransactionClassification.bankTransactionId,
        ConceptoID: bankTransactionClassification.conceptId,
        CodigoP1: bankTransactionClassification.p1Code,
        ClasificacionMovimientoBancarioDetallada: bankTransactionClassification.bankTransactionClassificationDetailed,
    };
    return formattedBankTransactionClassification;
}

export const createAdapterToBankTransactionClassification = (bankTransactionClassificationEndpoint: BankTransactionClassificationEndpoint) => {
    const formattedBankTransactionClassification: BankTransactionClassification = {
        id: bankTransactionClassificationEndpoint.ClasificacionMovimientoBancarioID,
        name: bankTransactionClassificationEndpoint.ClasificacionMovimientoBancario,
        bankTransactionTypeId: bankTransactionClassificationEndpoint.TipoMovimientoBancarioID,
        bankTransactionType: bankTransactionClassificationEndpoint.TipoMovimientoBancario ? createAdapterToBankTransactionType(bankTransactionClassificationEndpoint.TipoMovimientoBancario) : undefined,
        transactionCode: bankTransactionClassificationEndpoint.CodigoOperacion,
        bankTransactionId: bankTransactionClassificationEndpoint.OperacionBancoID,
        bankTransaction: bankTransactionClassificationEndpoint.OperacionBanco ? createAdapterToBankTransaction(bankTransactionClassificationEndpoint.OperacionBanco) : undefined,
        conceptId: bankTransactionClassificationEndpoint.ConceptoID,
        concept: bankTransactionClassificationEndpoint.Concepto ? createAdapterToConcept(bankTransactionClassificationEndpoint.Concepto) : undefined,
        p1Code: bankTransactionClassificationEndpoint.CodigoP1,
        bankTransactionClassificationDetailed: bankTransactionClassificationEndpoint.ClasificacionMovimientoBancarioDetallada,
    };
    return formattedBankTransactionClassification;
}