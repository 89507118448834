import { InvestmentType, InvestmentTypeEndpoint } from "../domain/InvestmentType.model";

type Dictionary = {
    [key: string]: string;
};

const investmentTypeAttributesDiccionary: Dictionary = {
    id: 'TipoInversionID',
    name: 'TipoInversion',
}

export const createAdapterToFieldInvestmentTypeEndpoint = (key: string): string => {
    return investmentTypeAttributesDiccionary[key];
}

export const createAdapterToInvestmentTypeEndpoint = (investmentType: InvestmentType) => {
    const formattedInvestmentType: InvestmentTypeEndpoint = {
        TipoInversionID: investmentType.id ?? undefined,
        TipoInversion: investmentType.name,
    };
    return formattedInvestmentType;
}

export const createAdapterToInvestmentType = (investmentTypeEndpoint: InvestmentTypeEndpoint) => {
    const formattedInvestmentType: InvestmentType = {
        id: investmentTypeEndpoint.TipoInversionID,
        name: investmentTypeEndpoint.TipoInversion,
    };
    return formattedInvestmentType;
}