import { Contact, ContactEndpoint } from "../domain/Contact.model";

type Dictionary = {
    [key: string]: string;
};

const contactAttributesDiccionary: Dictionary = {
    id: 'ContactoID',
    firstname: 'Nombre',
    lastname: 'Apellido',
    email: 'Email',
    state: 'Estado',
}

export const createAdapterToFieldContactEndpoint = (key: string): string => {
    return contactAttributesDiccionary[key];
}

export const createAdapterToContactEndpoint = (contact: Contact) => {
    const formattedContact: ContactEndpoint = {
        ContactoID: contact.id ?? undefined,
        Nombre: contact.firstname,
        Apellido: contact.lastname,
        Email: contact.email,
        Estado: Number(contact.state),
    };
    return formattedContact;
}

export const createAdapterToContact = (contactEndpoint: ContactEndpoint) => {
    const formattedContact: Contact = {
        id: contactEndpoint.ContactoID,
        firstname: contactEndpoint.Nombre,
        lastname: contactEndpoint.Apellido,
        email: contactEndpoint.Email,
        state: Boolean(contactEndpoint.Estado),
    };
    return formattedContact;
}