import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToFieldMonthClosureEndpoint,
  createAdapterToMonthClosure,
  createAdapterToMonthClosureEndpoint,
} from "../adapters/monthClosure.adapter";
import {
  EndpointMonthClosureFiltered,
  MonthClosure,
  MonthClosureEndpoint,
} from "../domain/MonthClosure.model";
import { appConfig } from "../../../../config";
import { EndpointMonthClosure } from "../domain/MonthClosure.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_MONTHCLOSURE_URL = "/entidadesfinancieras/fechacierre";
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/fechacierre/export`;

export const monthClosureApi = createApi({
  reducerPath: "monthClosureApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["MonthClosures"],
  endpoints: (builder) => ({
    getMonthClosure: builder.query<MonthClosure, string | undefined>({
      query: (id) => `${BASE_MONTHCLOSURE_URL}/${id}`,
      transformResponse: (response: EndpointMonthClosure) => {
        return createAdapterToMonthClosure(response.Content);
      },
      providesTags: ["MonthClosures"],
    }),
    getMonthClosures: builder.query<any, TableOptions<MonthClosure>>({
      query: (params: TableOptions<MonthClosure>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map((f) =>
          createAdapterToFilterBackend(
            f,
            createAdapterToFieldMonthClosureEndpoint
          )
        );
        const serializedOrder = order
          ? JSON.stringify({
              field: createAdapterToFieldMonthClosureEndpoint(order.field),
              order: order.order,
            })
          : undefined;
        const serializedFilters = adaptersFilter
          ? JSON.stringify(adaptersFilter)
          : undefined;
        return {
          url: `${BASE_MONTHCLOSURE_URL}/filtros`,
          params: {
            page: page ? page - 1 : 0,
            pageSize,
            order: serializedOrder,
            search,
            filters: serializedFilters,
          },
        };
      },
      providesTags: ["MonthClosures"],
      transformResponse: (
        response: EndpointMonthClosureFiltered,
        _meta,
        params: TableOptions<MonthClosure>
      ) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map(
          (monthClosure: MonthClosureEndpoint) =>
            createAdapterToMonthClosure(monthClosure)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),
    getMonthClosureLast: builder.query<
      any,
      TableOptions<MonthClosure> | undefined
    >({
      query: () => {
        return `${BASE_MONTHCLOSURE_URL}/lastmovimiento`;
      },
      providesTags: ["MonthClosures"],
      transformResponse: (response: EndpointMonthClosure) => {
        const formattedResponse = createAdapterToMonthClosure(response.Content);
        return {
          data: formattedResponse,
        };
      },
    }),
    getConciliationClosureLast: builder.query<
      any,
      TableOptions<MonthClosure> | undefined
    >({
      query: () => {
        return `${BASE_MONTHCLOSURE_URL}/lastconciliacion`;
      },
      providesTags: ["MonthClosures"],
      transformResponse: (response: EndpointMonthClosure) => {
        const formattedResponse = createAdapterToMonthClosure(response.Content);
        return {
          data: formattedResponse,
        };
      },
    }),
    createMonthClosure: builder.mutation<any, MonthClosure>({
      query: (data) => ({
        url: `${BASE_MONTHCLOSURE_URL}`,
        method: "POST",
        body: {
          Content: createAdapterToMonthClosureEndpoint(data),
        },
      }),
      invalidatesTags: ["MonthClosures"],
    }),
    deleteMonthClosure: builder.mutation<void, MonthClosure>({
      query: (data) => ({
        url: `${BASE_MONTHCLOSURE_URL}/${data.id.replace(/-/g, "")}/${
          data.closureProcessId
        }`,
        method: "DELETE",
      }),
      invalidatesTags: ["MonthClosures"],
    }),
  }),
});

export const {
  useGetMonthClosureQuery,
  useGetMonthClosureLastQuery,
  useGetConciliationClosureLastQuery,
  useGetMonthClosuresQuery,
  useCreateMonthClosureMutation,
  useDeleteMonthClosureMutation,
} = monthClosureApi;
