import { Customer, CustomerEndpoint } from "../domain/Customer.model";

type Dictionary = {
    [key: string]: string;
};

const customerAttributesDiccionary: Dictionary = {
    id: 'ClienteID',
    name: 'Cliente',
    cuit: 'CUIT',
}

export const createAdapterToFieldCustomerEndpoint = (key: string): string => {
    return customerAttributesDiccionary[key];
}

export const createAdapterToCustomerEndpoint = (customer: Customer) => {
    const formattedCurrency: CustomerEndpoint = {
        ClienteID: customer.id ?? undefined,
        Cliente: customer.name,
        CUIT: customer.cuit,
    };
    return formattedCurrency;
}

export const createAdapterToCustomer = (customerEndpoint: CustomerEndpoint) => {
    const formattedCustomer: Customer = {
        id: customerEndpoint.ClienteID,
        name: customerEndpoint.Cliente,
        cuit: customerEndpoint.CUIT,
    };
    return formattedCustomer;
}