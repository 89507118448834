import {
  ColumnFilter,
  ColumnFilterNode,
  DateFilterOperator,
} from "../shared-kernel/components/Table/domain/columns.model";
import { dateToNumber } from "./dateUtils";

export function createAdapterToFilterBackend<ValueType>(
  filter: ColumnFilter<ValueType>,
  dicctionaryAdapter: (key: string) => string
): ColumnFilterNode<ValueType> {
  const { column, operator, values } = filter;
  const transformedObject: ColumnFilterNode<ValueType> = {};

  if (column !== undefined) {
    transformedObject.name = dicctionaryAdapter(column);
  }

  if (operator !== undefined) {
    transformedObject.operation = operator;
  }

  if (
    operator !== undefined &&
    Object.values(DateFilterOperator).includes(operator as DateFilterOperator)
  ) {
    // @ts-ignore
    transformedObject.values = values?.map((item) =>
      dateToNumber(item.value as Date)
    );
    return transformedObject;
  }

  if (values !== undefined && values.length > 0) {
    if (operator === "some" || operator === "only") {
      // @ts-ignore
      transformedObject.values = values.map((item) => item.value.id.toString());
    } else {
      transformedObject.values = values.map((item) => item.value);
    }
  }
  return transformedObject;
}
