import styled from "styled-components";

interface TableRow {
  colorLine?: string;
  background?: string;
  hoverRow?: string;
}

interface TableHeaderRow {
  colorLine?: string;
  background?: string;
}

export const StyledTableRow = styled.tr<TableRow>`
  display: block;
  width: 100%;
  height: fit-content;
  background-color: ${(props) => `${props.theme.table.rowBackground}50`};
  border: 1px solid #fff;
  @media (min-width: 600px) {
    display: table-row;
    margin: 0;
    border: 1px solid #fff;
    td {
      border: 1px solid #fff;
      border-left: 2px dotted rgba(0,0,0, .2);
      background: #fff;
      border-radius: 0;
      color: #00000075;
      padding: 3px 0;
    }
    td:first-child {
      border-left: 1px solid #fff;
      border-radius: 8px 0 0 8px;
    }
    td:last-child {
      border-right: 1px solid 1px solid #fff;
      border-radius: 0 8px 8px 0;
    }
    &:hover {
      background: #c7c7c7;
    }
  }
`;

export const StyledTableHeaderRow = styled.tr<TableHeaderRow>`
  display: table-row;
  width: 100%;
  vertical-align: middle;
  height: fit-content;
  border: 1px solid rgba(0,0,0, .1);
  th {
    border-top: 2px dotted rgba(0,0,0, .1);
    border-bottom: 2px dotted rgba(0,0,0, .1);
    background-color: #F2F3FA;
    color: #706f6f;
    font-weight: 700;
    border-radius: 0;
  }
  th:first-child {
    color: #706f6f;
    border-left: 2px dotted rgba(0,0,0, .1);
    border-radius: 5px 0 0 5px;
  }
  th:last-child {
    color: #ACACAC;
    border-right: 2px dotted rgba(0,0,0, .1);
    border-radius: 0 5px 8px 5;
  }
`;
