import styled from "styled-components";

interface TablePaginationProps {
    show?: boolean;
}

interface StyledTablePaginationBoxProps {
    hiddenMobile?: boolean;
}

interface StyledTablePaginationPageNumberProps {
    ref?: any;
    active?: boolean;
}

export const StyledTablePagination = styled.div<TablePaginationProps>`
    display: ${props => props.show ? "flex" : "none"};
    color: ${props => props.theme.table.textColor};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    svg {
        cursor: pointer;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    @media (min-width: 400px) {
        flex-direction: row;
    }
`

export const StyledTablePaginationPageNumber = styled.input<StyledTablePaginationPageNumberProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 60px;
    height: 25px;
    padding: 15px 0;
    /* background-color: ${props => props.active ? '#64b33b' : 'transparent'}; */
    background-color: #f2f3fa;
    border: transparent;
    color: ${props => props.active ? "#4d4949f8" : "#4d4949b8" };
    font-size: ${props => props.active ? "16px" : "15px"};
    font-weight: ${props => props.active ? "800" : "600"};
    outline: none;
    cursor: pointer;
    caret-color: transparent;
    transition: 0.3s ease-in-out;
    :focus {
        outline: none;
        box-shadow: none;
    }
    :hover{
        color: ${props => !props.active && "#2d2d2de0" };
        font-size: ${props => !props.active && "16px"};
    }
`

export const StyledTablePaginationSelect = styled.select`
    padding: 3px 0 3px 5px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    border: 0.5px none #4d4949b8;
    background-color: #f2f3fa;
    color: #4d4949f8;
    margin: 0 10px;
    outline: none;
`

export const StyledTablePaginationBox = styled.div<StyledTablePaginationBoxProps>`
    display: ${props => props.hiddenMobile ? "none" : "flex"};
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 16px;
    color: #4d4949f8;
    border: 0.5px none #4d4949b8;
    height: 40px;
    svg{
        transition: 0.3s ease-in-out;
        border-radius: 100%;
        margin: 0 5px;
    }
    svg:hover{
        font-size: 26px;
    }
    .navigate {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f3fa;
        color: #4d4949f8;
        border-radius: 5px;
        margin: 5px;
    }
    @media (min-width: 400px) {
        display: flex;
        margin: 0;
    }
`
export const StyledTablePaginationDivider = styled.span`
    margin: 10px;
    display: none;
    @media (min-width: 400px) {
        display: inherit;
    }
`;