import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageLocale } from "../../domain/language";
import { LOCALES } from "../../locales";


const initialState: LanguageLocale = {value:LOCALES.SPANISH};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
