import { InvestmentPortfolio, InvestmentPortfolioEndpoint } from "../domain/InvestmentPortfolio.model";

type Dictionary = {
    [key: string]: string;
};

const investmentPortfolioAttributesDiccionary: Dictionary = {
    id: 'CarteraInversionID',
    name: 'CarteraInversion',
    ric: 'RIC',
    dueDate: 'FechaVencimientoID',
    currencyId: 'MonedaID',
    investmentType: 'TipoInversionID',
}

export const createAdapterToFieldInvestmentPortfolioEndpoint = (key: string): string => {
    return investmentPortfolioAttributesDiccionary[key];
}

export const createAdapterToInvestmentPortfolioEndpoint = (investmentPortfolio: InvestmentPortfolio) => {
    const formattedInvestmentPortfolio: InvestmentPortfolioEndpoint = {
        CarteraInversionID: investmentPortfolio.id ?? undefined,
        CarteraInversion: investmentPortfolio.name,
    };
    return formattedInvestmentPortfolio;
}

export const createAdapterToInvestmentPortfolio = (investmentPortfolioEndpoint: InvestmentPortfolioEndpoint) => {
    const formattedInvestmentPortfolio: InvestmentPortfolio = {
        id: investmentPortfolioEndpoint.CarteraInversionID,
        name: investmentPortfolioEndpoint.CarteraInversion,
    };
    return formattedInvestmentPortfolio;
}