import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  MenuDataProps,
  Menu, MenuEndpoint,
} from "../domain/Menu.model";
import {
  createAdapterToMenu,
  createAdapterToMenuEndpoint,
} from "../adapters/menu.adapter";
import {TableOptions} from "../../Table";
import {appConfig} from "../../../../config";
import {
  createAdapterToTopSidebarMenu
} from "../../Layouts/TopbarLayout/adapters/SidebarMenu.adapter";
import {SidebarMenuEndpoint, SidebarMenu} from "../../Layouts/TopbarLayout/domain/sidebarNav";

const BASE_MENU_URL = '/menus';
const TAG_MENUS = 'Menus';
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/menus/export`;

export const menuApi = createApi({
  reducerPath: "menuApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const token = localStorage.getItem(appConfig.APP_TOKEN_NAME);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: [TAG_MENUS],
  endpoints: (builder) => ({
    getSidebarMenus: builder.query<SidebarMenu[], void | undefined>({
      query: () => BASE_MENU_URL,
      providesTags: [TAG_MENUS],
      transformResponse: (response: SidebarMenuEndpoint[]) => {
        return response.map(men => createAdapterToTopSidebarMenu(men, response))
      }
    }),
    getMenu: builder.query<Menu, string | undefined>({
      query: (id) => `${BASE_MENU_URL}/${id}`,
      providesTags: [TAG_MENUS],
      transformResponse: (response: MenuEndpoint) => {
        return createAdapterToMenu(response)
      }
    }),
    getMenus: builder.query<MenuDataProps, TableOptions<Menu> | undefined>({
      query: (tableOptions: TableOptions<Menu>) => {
        let queryParams: string = '';
        if(tableOptions !== undefined && tableOptions.page){
          const page = tableOptions.page - 1;
          queryParams += `page=${page}`;
        }
        if(tableOptions !== undefined && tableOptions.pageSize){
          queryParams += `&pageSize=${tableOptions.pageSize}`;
        }
        if(tableOptions !== undefined && tableOptions.order){
          const parsedOrder = JSON.stringify({
            field: tableOptions.order.field,
            order: tableOptions.order.order
          })
          queryParams += `&order=${parsedOrder}`;
        }
        if(tableOptions !== undefined && tableOptions.search){
          queryParams += `&search=${tableOptions.search}`;
        }
       return `${BASE_MENU_URL}${queryParams !== '' ? `?${queryParams}` : ''}`;
      },
      providesTags: [TAG_MENUS],
      transformResponse: (response: MenuEndpoint[]) => {
        return {
          data: response.map((m: MenuEndpoint) =>
            createAdapterToMenu(m)
          ),
          page: 1,
          total: response.length,
        };
      },
    }),
    createMenu: builder.mutation<any, Menu>({
      query: (data) => ({
        url: BASE_MENU_URL,
        method: "POST",
        body: createAdapterToMenuEndpoint(data),
      }),
      invalidatesTags: [TAG_MENUS],
    }),
    updateMenu: builder.mutation<any, Menu>({
      query: (data: Menu) => ({
        url: `${BASE_MENU_URL}/${data.menuId}`,
        method: "PUT",
        body: createAdapterToMenuEndpoint(data),
      }),
      invalidatesTags: [TAG_MENUS],
    }),
    deleteMenu: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_MENU_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [TAG_MENUS],
    }),
  }),
});

export const {
  useGetMenuQuery,
  useGetMenusQuery,
  useCreateMenuMutation,
  useUpdateMenuMutation,
  useDeleteMenuMutation,
  useGetSidebarMenusQuery
} = menuApi;
