import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface StyledSidebarNavMenuItemLinkProps {
  to: string;
  $showname?: boolean;
}

export const StyledSidebarNavMenuItemLink = styled(
  NavLink
)<StyledSidebarNavMenuItemLinkProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #9A989E;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  padding: 8px 0px 8px 8px;
  &.active {
    color: #fff;
    font-weight: 600;
    background-color: #9a989e30;
  }
  &:hover {
    opacity: 0.65;
  }
`;

interface StyledSidebarNavMenuItemNameProps {
  $showname?: boolean;
}

export const StyledSidebarNavMenuItemName = styled.span<StyledSidebarNavMenuItemNameProps>`
  margin: 0 0 0px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 600;
`

interface StyledSidebarNavMenuIteButtonProps {
  expand: boolean;
  $showname?: boolean;
}

export const StyledSidebarNavMenuItemButton = styled.div<StyledSidebarNavMenuIteButtonProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  padding: 15px 0 20px 0px;
  svg:last-child {
    margin-left: auto;
    transition: all 300ms ease-in-out;
    transform: ${(props) => (props.expand ? "rotate(0deg)" : "rotate(-90deg)")};
    color: ${(props) => (props.expand ? "fff" : "#9A989E")};
  }
  &:hover {
    opacity: 0.80;
  }
`;
