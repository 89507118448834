import { Currency, CurrencyEndpoint } from "../domain/Currency.model";

type Dictionary = {
    [key: string]: string;
};

const currencyAttributesDiccionary: Dictionary = {
    id: 'MonedaID',
    name: 'Moneda',
    code: 'MonedaCorto',
}

export const createAdapterToFieldCurrencyEndpoint = (key: string): string => {
    return currencyAttributesDiccionary[key];
}

export const createAdapterToCurrencyEndpoint = (currency: Currency) => {
    const formattedCurrency: CurrencyEndpoint = {
        MonedaID: currency.id ?? undefined,
        Moneda: currency.name,
        MonedaCorto: currency.code,
    };
    return formattedCurrency;
}

export const createAdapterToCurrency = (currencyEndpoint: CurrencyEndpoint) => {
    const formattedCurrency: Currency = {
        id: currencyEndpoint.MonedaID,
        name: currencyEndpoint.Moneda,
        code: currencyEndpoint.MonedaCorto,
    };
    return formattedCurrency;
}