import {EndpointUser, User} from "../domain/User.model";
import {createAdapterRole} from "../../role/adapters/role.adapter";
import { config } from "process";

export const createAdapterUser = (endpointUser: EndpointUser) => {
    const formattedUser: User = {
        id: endpointUser?.id,
        username: endpointUser.username,
        email: endpointUser.email,
        role: endpointUser.role ? createAdapterRole(endpointUser.role) : undefined,
        firstname: endpointUser.metadata?.firstname,
        lastname: endpointUser.metadata?.lastname 
    };
    return formattedUser;
}

export const createAdapterEndpointUser = (user: User) => {
    const firstname = user.firstname;
    const lastname = user.lastname;
    const role = user?.role as string;
    const formattedEndpointUser: EndpointUser = {
        id: user?.id,
        username: user.username,
        password: user.password,
        email: user.email,
        roleId: role ? role: undefined,
        metadata: !!user.firstname || !!user.lastname ? {
            firstname, 
            lastname
        } : undefined
    };
    return formattedEndpointUser;
}
