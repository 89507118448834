import { createAdapterToPaymentMethod } from "../../../../parametry/estimates/paymentMethod/adapters/paymentMethod.adapter";
import { createAdapterToState } from "../../../../parametry/estimates/state/adapters/state.adapter";
import { createAdapterToBankAccount } from "../../../../parametry/financial/bankAccount/adapters/bankAccount.adapter";
import { createAdapterToConcept } from "../../../../parametry/financial/concept/adapters/concept.adapter";
import { createAdapterToArea } from "../../../../parametry/general/area/adapters/area.adapter";
import { createAdapterToCurrency } from "../../../../parametry/general/currency/adapters/currency.adapter";
import {
  numericalDateToString,
  stringToNumericalDate,
} from "../../../../utilities/dateUtils";
import {
  Expense,
  ExpenseEndpoint,
  Supplier_wPersona,
  Supplier_wPersonaEndpoint,
} from "../domain/Expense.model";

type Dictionary = {
  [key: string]: string;
};

const expenseAttributesDiccionary: Dictionary = {
  id: "EgresoID",
  firstEstDate: "FechaPrimeraEstimacionID",
  lastEstDate: "FechaUltimaEstimacionID",
  conceptId: "ConceptoID",
  areaId: "AreaID",
  currencyId: "MonedaID",
  bankAccountId: "CuentaBancariaID",
  currencyLocationId: "MonedaLocacionID",
  currencyOpId: "MonedaOperacionID",
  exchangeRateOp: "TipoCambioOperacion",
  paymentMethodId: "FormatoPagoID",
  stateId: "EstadoID",
  payOrder: "OrdenPago",
  supplierId: "ProveedorID",
  comment: "Comentario",
  firstEstAmount: "ImportePrimeraEstimacion",
  lastEstAmount: "ImporteUltimaEstimacion",
  fees: "Comisiones",
  taxes: "Impuestos",
};

export const createAdapterToFieldExpenseEndpoint = (key: string): string => {
  return expenseAttributesDiccionary[key];
};

export const createAdapterToExpenseEndpoint = (expense: Expense) => {
  const formattedExpense: Partial<ExpenseEndpoint> = {
    EgresoID: expense.id ?? undefined,
    FechaPrimeraEstimacionID: expense.firstEstDate
      ? stringToNumericalDate(expense.firstEstDate)
      : undefined,
    FechaUltimaEstimacionID: expense.lastEstDate
      ? stringToNumericalDate(expense.lastEstDate)
      : undefined,
    ConceptoID: expense.conceptId,
    CuentaBancariaID: expense.bankAccountId,
    AreaID: expense.areaId,
    MonedaLocacionID: expense.currencyLocationId,
    MonedaOperacionID: expense.currencyOpId,
    TipoCambioOperacion: expense.exchangeRateOp,
    FormatoPagoID: expense.paymentMethodId,
    EstadoID: expense.stateId,
    OrdenPago: expense.payOrder,
    ProveedorID: expense.supplierId,
    Comentario: expense.comment,
    ImportePrimeraEstimacion: expense.firstEstAmount,
    ImporteUltimaEstimacion:
      expense.lastEstAmount !== undefined && !isNaN(expense.lastEstAmount)
        ? expense.lastEstAmount
        : undefined,
    Comisiones: expense.fees,
    Impuestos: expense.taxes,
  };
  return formattedExpense;
};

export const createAdapterToExpense = (expenseEndpoint: ExpenseEndpoint) => {
  const formattedExpense: Expense = {
    id: expenseEndpoint.EgresoID,
    firstEstDate: expenseEndpoint.FechaPrimeraEstimacionID
      ? numericalDateToString(expenseEndpoint.FechaPrimeraEstimacionID)
      : undefined,
    lastEstDate: expenseEndpoint.FechaUltimaEstimacionID
      ? numericalDateToString(expenseEndpoint.FechaUltimaEstimacionID)
      : undefined,
    conceptId: expenseEndpoint.ConceptoID,
    concept: expenseEndpoint.Concepto
      ? createAdapterToConcept(expenseEndpoint.Concepto)
      : undefined,
    areaId: expenseEndpoint.AreaID,
    area: expenseEndpoint.Area
      ? createAdapterToArea(expenseEndpoint.Area)
      : undefined,
    bankAccountId: expenseEndpoint.CuentaBancariaID,
    bankAccount: expenseEndpoint.CuentaBancaria
      ? createAdapterToBankAccount(expenseEndpoint.CuentaBancaria)
      : undefined,
    currencyLocationId: expenseEndpoint.MonedaLocacionID,
    currencyLocation: expenseEndpoint.MonedaLocacion
      ? createAdapterToCurrency(expenseEndpoint.MonedaLocacion)
      : undefined,
    currencyOpId: expenseEndpoint.MonedaOperacionID,
    currencyOp: expenseEndpoint.MonedaOperacion
      ? createAdapterToCurrency(expenseEndpoint.MonedaOperacion)
      : undefined,
    exchangeRateOp: expenseEndpoint.TipoCambioOperacion,
    paymentMethodId: expenseEndpoint.FormatoPagoID,
    paymentMethod: expenseEndpoint.FormatoPago
      ? createAdapterToPaymentMethod(expenseEndpoint.FormatoPago)
      : undefined,
    stateId: expenseEndpoint.EstadoID,
    state: expenseEndpoint.Estado
      ? createAdapterToState(expenseEndpoint.Estado)
      : undefined,
    payOrder: expenseEndpoint.OrdenPago,
    supplierId: expenseEndpoint.ProveedorID,
    supplier: expenseEndpoint.Proveedor
      ? createAdapterToSupplier_wPersona(expenseEndpoint.Proveedor)
      : undefined,
    comment: expenseEndpoint.Comentario,
    firstEstAmount: expenseEndpoint.ImportePrimeraEstimacion,
    lastEstAmount: expenseEndpoint.ImporteUltimaEstimacion,
    fees: expenseEndpoint.Comisiones,
    taxes: expenseEndpoint.Impuestos,
  };
  return formattedExpense;
};

const createAdapterToSupplier_wPersona = (
  supplier_wPersonaEndpoint: Supplier_wPersonaEndpoint
) => {
  //
  const formattedSupplier_wPersona: Supplier_wPersona = {
    personId: supplier_wPersonaEndpoint.PersonaID,
    person: supplier_wPersonaEndpoint.Persona,
    CUIT: supplier_wPersonaEndpoint.CUIT,
  };
  return formattedSupplier_wPersona;
};
