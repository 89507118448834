import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { LanguageProps } from "../domain/language";
import messages from "../messages";
import { useAppSelector } from "../../utilities/hooks/redux.hooks";


function LanguageProvider ({
  children,
}:LanguageProps) {
  const language = useAppSelector((state) => state.language);

  
  return (
      <IntlProvider
        locale={language.value}
        messages={messages[language.value]}
        textComponent={Fragment}
      >
        {children}
      </IntlProvider>
  );
};
export default LanguageProvider;
