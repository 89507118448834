import { FC, ReactNode } from "react";
import { useGetSidebarMenusQuery} from "../../../menu/services/menuApi";
import {
  StyledNonResourceMenuError
} from "../../../NonRecourseMenu/styled-components/StyledNonResourceMenuContainer";
import translate from "../../../../../language/translate";
import { useLocation } from "react-router-dom";
import useSession from "../../services/useSession";
import {MENU_PERMISSION_BASE} from "../../../menu/menu.constants";

interface SecureSlugProps {
  children?: ReactNode;
}

const SecureSlug: FC<SecureSlugProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { hasPermission } = useSession();

  const { data, isSuccess, isError } = useGetSidebarMenusQuery();

  if(isError){
    return <StyledNonResourceMenuError>
      {translate("there was an error loading the menus")}
    </StyledNonResourceMenuError>
  }

  return isSuccess ?
    data.find(men => men.action !== undefined && pathname.includes(men.action) && hasPermission(`${MENU_PERMISSION_BASE}${men.action}`)) ?
      <>{children}</>
      : <StyledNonResourceMenuError>
        {translate("you do not have permissions to enter this menu")}
      </StyledNonResourceMenuError>

    : null
};

export default SecureSlug;
