import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TableOptions } from "../../../../shared-kernel/components/Table";
import {
  createAdapterToBankTransactionType,
  createAdapterToBankTransactionTypeEndpoint,
  createAdapterToFieldBankTransactionTypeEndpoint
} from "../adapters/bankTransactionType.adapter";
import { BankTransactionType, BankTransactionTypeEndpoint, EndpointBankTransactionTypeFiltered } from "../domain/bankTransactionType.model";
import { appConfig } from "../../../../config";
import { EndpointBankTransactionType } from "../domain/bankTransactionType.model";
import { createAdapterToFilterBackend } from "../../../../utilities/paramsAdapter";

const BASE_BANKTRANSACTIONTYPE_URL = '/entidadesfinancieras/tiposmovimientosbancarios'
const BASE_BANKTRANSACTIONTYPE_MUTATION_URL = '/entidadesfinancieras/tipomovimientobancario'
export const BASE_EXPORT_URL = `${appConfig.API_NODE_URL}/entidadesfinancieras/tiposmovimientosbancarios/export`;

export const bankTransactionTypeApi = createApi({
  reducerPath: "bankTransactionTypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.API_NODE_URL,
    prepareHeaders: (headers: Headers) => {
      const APP_TOKEN = "app_token";
      const token = localStorage.getItem(APP_TOKEN);

      if (token) {
        headers.set("authorization", `${token}`);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["BankTransactionTypes", "BankTransactionType"],
  endpoints: (builder) => ({
    getBankTransactionType: builder.query<BankTransactionType, number | undefined>({
      query: (id) => `${BASE_BANKTRANSACTIONTYPE_MUTATION_URL}/${id}`,
      transformResponse: (response: EndpointBankTransactionType) => {
        return createAdapterToBankTransactionType(response.Content)
      },
      providesTags: (_res, _err, id) => [{type: "BankTransactionType", id: id?.toString()}],
    }),
    getBankTransactionTypes: builder.query<any, TableOptions<BankTransactionType>|undefined>({
      query: (params: TableOptions<BankTransactionType>) => {
        const { page, pageSize, order, search, filters } = params || {};
        const adaptersFilter = filters?.map( f => createAdapterToFilterBackend(f,createAdapterToFieldBankTransactionTypeEndpoint))
        const serializedFilters = adaptersFilter ? JSON.stringify(adaptersFilter) : undefined;
        const serializedOrder = order ? JSON.stringify({field:createAdapterToFieldBankTransactionTypeEndpoint(order.field), order:order.order}) : undefined;
        return {
          url:`${BASE_BANKTRANSACTIONTYPE_URL}/filtros`,
          params: {
            page:page? page - 1: 0,
            pageSize,
            order: serializedOrder,
            search,
            filters:serializedFilters
          },
        }
      },
      providesTags: ["BankTransactionTypes"],
      transformResponse: (response: EndpointBankTransactionTypeFiltered, _meta, params: TableOptions<BankTransactionType>) => {
        const page = params?.page ?? 1;
        const endpointResponse = response?.rows?.map((bankTransactionType: BankTransactionTypeEndpoint) =>
            createAdapterToBankTransactionType(bankTransactionType)
        );
        return {
          data: endpointResponse,
          page,
          total: response.total,
        };
      },
    }),

    createBankTransactionType: builder.mutation<any, BankTransactionType>({
      query: (data) => ({
        url: `${BASE_BANKTRANSACTIONTYPE_MUTATION_URL}`,
        method: "POST",
        body: {
          "Content": createAdapterToBankTransactionTypeEndpoint(data)
        },
      }),
      invalidatesTags: ["BankTransactionTypes"],
    }),
    updateBankTransactionType: builder.mutation<any, BankTransactionType>({
      query: (data: BankTransactionType) => ({
        url: `${BASE_BANKTRANSACTIONTYPE_MUTATION_URL}`,
        method: "PUT",
        body: {
          "Content": createAdapterToBankTransactionTypeEndpoint(data)
        },
      }),
      invalidatesTags: (_res, _err, data: BankTransactionType) => ["BankTransactionTypes", {type: 'BankTransactionType', id: data?.id?.toString()}],
    }),
    deleteBankTransactionType: builder.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_BANKTRANSACTIONTYPE_MUTATION_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BankTransactionTypes"],
    }),
  }),
});

export const {
  useGetBankTransactionTypeQuery,
  useGetBankTransactionTypesQuery,
  useCreateBankTransactionTypeMutation,
  useUpdateBankTransactionTypeMutation,
  useDeleteBankTransactionTypeMutation
} = bankTransactionTypeApi;
