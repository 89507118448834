import { BankTransactionType, BankTransactionTypeEndpoint } from "../domain/bankTransactionType.model";

type Dictionary = {
    [key: string]: string;
};

const bankTransactionTypeAttributesDiccionary: Dictionary = {
    id: 'TipoMovimientoBancarioID',
    name: 'TipoMovimientoBancario'
}

export const createAdapterToFieldBankTransactionTypeEndpoint = (key: string): string => {
    return bankTransactionTypeAttributesDiccionary[key];
}

export const createAdapterToBankTransactionTypeEndpoint = (bankTransactionType: BankTransactionType) => {
    const formattedBankTransactionType:BankTransactionTypeEndpoint = {
        TipoMovimientoBancarioID: bankTransactionType.id ?? undefined,
        TipoMovimientoBancario: bankTransactionType.name,
    };
    return formattedBankTransactionType;
}

export const createAdapterToBankTransactionType = (bankTransactionTypeEndpoint: BankTransactionTypeEndpoint) => {
    const formattedBankTransactionType: BankTransactionType = {
        id: bankTransactionTypeEndpoint.TipoMovimientoBancarioID,
        name: bankTransactionTypeEndpoint.TipoMovimientoBancario
    };
    return formattedBankTransactionType;
}