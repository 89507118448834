import { SidebarAttributes } from "../domain/sidebarNav";
import { FC } from "react";
import SidebarNavMenuItem from "./SidebarNavMenuItem";
import styled from "styled-components";

const SidebarNav: FC<SidebarAttributes> = ({ data, isSuccess }) => {

  return (
    <StyledSidebarNavMenu>
      {isSuccess
        ? data?.map((item, index) =>
            !item.parentMenu ? (
              <SidebarNavMenuItem
                item={item}
                key={`item_${index}_${item.name}`}
              />
            ) : null
          )
        : null}

    </StyledSidebarNavMenu>
  );
};

export default SidebarNav;

export const StyledSidebarNavMenu = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
`
