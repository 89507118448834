import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
 

  * {
    margin: 0;
    box-sizing: border-box;
  };

  .p-c, .p-button-outlined span, .p-column-filter-matchmode-dropdownm, .p-column-filter-operator, .p-column-filter-matchmode-dropdown, .p-column-filter-add-rule{
    display: none !important;
  };

  .p-column-filter-buttonbar button{
    padding: 10px;
    margin-top: -15px;
    background-color: red;
    border: none;
    color: #fff;
    background-color: #2616F2;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  };

  .p-column-filter-buttonbar .p-button-outlined{
    padding: 5px 10px;
    margin: 0;
    background-color: red;
    border: none;
    color: #fff;
    background-color: tomato;
    display: none;
  };

  .p-button-sm::after{
    content: 'Buscar';
    display: block;
    text-align: center;
    color: inherit;
  };

  .p-button-outlined::after {
    content: 'Limpiar';
    display: block;
    text-align: center;
    color: inherit;
  };

  .p-checkbox-box{
    background-color: #fff !important;
  };

  .p-checkbox-box svg{
    color: #000 !important;
  };

  .p-checkbox input{
    color: red !important;
    background-color: red !important;
  }

  .p-filter-column{
    padding: 10px 0;
  }

  .p-column-filter {
    padding: 6px 5px !important;
    min-width: 50px;
    z-index: 1 !important;
    width: 100% !important;
  };

  .p-column-filter input{
    padding: 5px 5px !important;
    min-width: 50px;
  }

  .p-column-filter-clear-button{

  }

  body {
    margin: 0;
    font-family: 'Verdana', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .Titulo-compuesto {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .checkDiaries,
  .checkExtract {
    position: absolute;
    left: 20px;
    top: 15px;
    z-index: 1 !important;
  }

  .p-column-filter-clear-button svg{
    visibility: visible !important;
  }
  .custom-header {
    background-color: #f2f3fa;
    color: #4d4949f8;
    padding: 15px 5px !important;
    border-top: 2px dotted #cccccc99;
    border-bottom: 2px dotted #cccccc99;
    font-weight: bold;
    text-align: center !important;
    position: relative !important;
    :first-child {
      border-radius: 5px 0px 0px 5px;
      border-left: 2px dotted #cccccc99;
    }
    :last-child {
      border-radius: 0px 5px 5px 0px;
      border-right: 2px dotted #cccccc99;
    }
    svg{
      width: 25px;
      height: 23px;
      color: #706F6F;
      transition: 0.5s ease-in-out;
      padding: 3.5px;
    } 
    :hover{
      svg{
        border-radius: 5px;
      }
    }
    .settings-icon{
      color: #ACACAC;
      text-align: center;
      opacity: 1 !important;
      font-size: 50px !important;
      padding: 0 !important;
    }
  }
  .custom-row{
    text-align: center;
    svg{
      color: #ACACAC;
      cursor: pointer;
      font-size: 26px;
      padding: 1px 2px;
      transition: 0.5s;
    }
    svg:hover{
      border-radius: 5px;
      font-size: 26px;
      padding: 0 2px;
      background: rgba(0,0,0,0.1);
    }
    border-right: 2px dotted #cccccc99;
    border-bottom: none;
    padding: 0 10px;
    :last-child{
      border-right: none;
    }
  }
  .botones{
    display: flex;
    gap: 1rem;
  }
  .buscador{
    display: flex;
    gap: 1rem;
    
    align-items: center;
    .input-search{
      width: 100%;
      padding: 10px 10px 10px 40px;
      transition: 0.5s;
      &:focus{
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
        outline: none;
      }
      &:hover{
        outline: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(0,0,0,0.3);
      } 
    }
    .lupa-icono{
      position: relative;
      left: 40px;
      color: #ACACAC;
      padding: 0 5px;
      font-size: 40px;
    }
  }

  .p-fluid,
  .p-link {
    background-color: transparent;
  }

  .p-datatable-tbody {
  }

  .p-datatable-tbody td {
    padding: 5px 0;
    min-height: 40px !important;
    height: 40px !important;
    max-height: 40px !important;
    .tacho {
      width: 50%;
      cursor: pointer;
    }
    .tacho svg {
      transform: translate(-30%, 0);
    }
    .LockClock {
      width: 50%;
      transform: translate(-20%, 0);
    }
  }

  .p-datatable {
    padding: 0 8px;
  }
  .p-filter-column {
    padding: 5px 0;
  }
  .p-column-header-content span svg {
    margin-left: 5px;
    color: #4d4949f8;
  }
  .p-datatable-thead tr th {
    white-space: nowrap;
    .p-column-header-content {
      justify-content: center;
    }
  }
  td {
    text-align: center;
  }
`;



export default GlobalStyles;
