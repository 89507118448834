import { EndpointLoginAttributes, LoginAttributes } from "../domain/login";

export const createAdapterLogin = (login: LoginAttributes) => {
  const formattedLogin: EndpointLoginAttributes = {
    username: login.username,
    password: login.password,
    authenticationType: login.authenticationType,
  };
  return formattedLogin;
};
