import styled from "styled-components";

interface InputErrorProps {
    show?: boolean;
    theme: any;
}

export const InputError = styled.small<InputErrorProps>`
    position: absolute;
    left: 0;
    top: ${props => props.show ? '100%': 0};
    color: ${props => props.theme.base.main};
    padding: 10px 5px 5px 5px;
    border: 1px solid ${props => props.theme.error.main};
    background: ${props => props.theme.error.main};
    border-radius: 0 0 8px 8px;
    width: fit-content;
    transition: all 500ms ease-in-out;
    opacity: ${props => props.show ? 1 : 0};
    transform: ${props => props.show ? 'translateY(-5px)': 'translateY(0)'};
    z-index: -1;
    
`