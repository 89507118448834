import styled from "styled-components";
import DropMenu from "./DropMenu";
import { useEffect, useRef, useState } from "react";
import {useAppSelector} from "../../../../../utilities/hooks/redux.hooks";
import {decodeToken} from "../../../auth/utilities/jwt";
import { useIntl } from "react-intl";

const exampleUserData = {
    fullname: "ExampleName",
    role: "ExampleRole",
    imageProfile: undefined
};

const Profile = () => {
    const session = useAppSelector(state => state.session);
    const intl = useIntl();
    const userData = session?.token ?  {
        ...exampleUserData,
        fullname: decodeToken(session.token).username,
        role: intl.formatMessage({ id: decodeToken(session.token).role?.name}) ?? intl.formatMessage({
          id: 'noRole'
        })
    } : exampleUserData;

    const [openDropMenu, setOpenDropMenu] = useState(false);
    const dropdownToggle = () => {
      setOpenDropMenu(!openDropMenu);
    };
    let menuRef = useRef<any>(null);
    let profileRef = useRef<any>(null);

    useEffect(() => {
      const handler = (e: MouseEvent) => {
        if (menuRef.current && e.target instanceof Node && !menuRef.current.contains(e.target) && !profileRef.current.contains(e.target) && openDropMenu) {
          setOpenDropMenu(false);
        }
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    });
  return (
    <>
      <StyledProfilePicture
        onClick={dropdownToggle}
        src={userData?.imageProfile ?? '/assets/abstract-user-profile.png'}
        alt="profile"
        ref={profileRef}
      />
      <StyledProfileText>
        <StyledProfileName>{userData.fullname}</StyledProfileName>
        <StyledProfileRole>{userData.role}</StyledProfileRole>
      </StyledProfileText>
      <DropMenu openDropMenu={openDropMenu} dropdownToggle={dropdownToggle} ref={menuRef} />
    </>
  );
};

export default Profile;

export const StyledProfileText = styled.div`
  color: #111;
  white-space: nowrap;
  text-align: left;
  padding-right: 20px;
  h3 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 0.7rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledProfileName = styled.span`
  font-size: 1rem;
  font-weight: 500;
`

export const StyledProfileRole = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  color: #262627;
`

export const StyledProfilePicture = styled.img`
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
`;