import { Supplier, SupplierEndpoint } from "../domain/Supplier.model";

type Dictionary = {
    [key: string]: string;
};

const supplierAttributesDiccionary: Dictionary = {
    id: 'ProveedorID',
    name: 'Proveedor',
    cuit: 'CUIT',
}

export const createAdapterToFieldSupplierEndpoint = (key: string): string => {
    return supplierAttributesDiccionary[key];
}

export const createAdapterToSupplierEndpoint = (supplier: Supplier) => {
    const formattedSupplier: SupplierEndpoint = {
        ProveedorID: supplier.id ?? undefined,
        Proveedor: supplier.name,
        CUIT: supplier.cuit,
    };
    return formattedSupplier;
}

export const createAdapterToSupplier = (supplierEndpoint: SupplierEndpoint) => {
    const formattedSupplier: Supplier = {
        id: supplierEndpoint.ProveedorID,
        name: supplierEndpoint.Proveedor,
        cuit: supplierEndpoint.CUIT,
    };
    return formattedSupplier;
}